/* eslint-disable jsx-a11y/anchor-is-valid */
import {compact, isEmpty, isEqual, map, take} from 'lodash'
import {FC} from 'react'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {Tooltip} from 'src/app2/modules/apps/components/tooltip/Tooltip'
import {String} from 'src/app2/modules/_models'
import {Tag} from '../../../../campaign-create/core/_models'

interface Props {
  className?: String
  translateNode?: String
  tags?: Tag[]
}

const TagListCell: FC<Props> = ({className, translateNode, tags}) => {
  const intl = useIntl()
  const compactedTags = compact(tags)
  const taked2Tags = take(compactedTags, 2)

  return (
    <div className={clsx('d-flex align-items-center overflow-hidden', className)}>
      <div className='d-flex'>
        {isEmpty(taked2Tags) ? (
          <span>-</span>
        ) : (
          <Tooltip
            content={
              <ul className='m-n1 p-3 d-flex flex-wrap mw-492px'>
                {map(compactedTags, (tag) => {
                  const {_id, name} = tag
                  return (
                    <li key={_id} className='d-flex align-items-center p-2 rounded bg-gray-200 m-1'>
                      <span className='fs-7 fw-bold text-gray-600 text-nowrap'>
                        {translateNode
                          ? intl.formatMessage({id: `CUSTOMER.${translateNode}.${name}`})
                          : name}
                      </span>
                    </li>
                  )
                })}
              </ul>
            }
          >
            <div className='d-flex'>
              {map(taked2Tags, (tag, tagIndex) => {
                const {_id, name} = tag

                return (
                  <div
                    key={_id}
                    className={clsx('d-flex align-items-center p-2 rounded bg-gray-200', {
                      'ms-3': !isEqual(tagIndex, 0),
                    })}
                  >
                    <span className='fs-7 fw-bold text-gray-600 text-nowrap'>
                      {translateNode
                        ? intl.formatMessage({id: `CUSTOMER.${translateNode}.${name}`})
                        : name}
                    </span>
                  </div>
                )
              })}
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export {TagListCell}
