import axios, {AxiosResponse} from 'axios'
import {ID, Response} from 'src/_biha/helpers'
import request from 'src/app/shared/config/request'
import {CampaignQueryResponse, Customer} from './_models'

const API_URL = process.env.REACT_APP_GUGO_API
const CAMPAIGN_URL = `${API_URL}/campaign`
const LEAD_URL = `${API_URL}/lead`

const getCampaign = (query: string): Promise<CampaignQueryResponse> => {
  return request('get', `${CAMPAIGN_URL}?${query}`).then((d) => d)
}

const getCampaignById = (id: ID): Promise<Customer | undefined> => {
  return axios
    .get(`${CAMPAIGN_URL}/${id}`)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data)
}

const createCustomer = (customer: Customer): Promise<Customer | undefined> => {
  return axios
    .put(CAMPAIGN_URL, customer)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data)
}

const updateCustomer = (customer: Customer): Promise<Customer | undefined> => {
  return axios
    .post(`${CAMPAIGN_URL}/${customer.id}`, customer)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data)
}

const updateStatus = (ids: Array<ID>, status: string): Promise<{status: string}> => {
  const requests = ids.map((id) => axios.put(`${CAMPAIGN_URL}/run/${id}`, {status}))
  return axios
    .all(requests)
    .then((e) => e[0]?.data)
    .catch((e) => e)
}

export {getCampaign, updateStatus, getCampaignById, createCustomer, updateCustomer}
