import axios, {AxiosResponse} from 'axios'
import {ID, Response, ResponseGraphQL} from 'src/_biha/helpers'
import request from 'src/app/shared/config/request'
import {
  B2bGetListOfUsersBasicTel4vnInfoData,
  B2bGetListOfUsersBasicTel4vnInfoVariables,
  Campaign,
  CampaignQueryResponse,
  Customer,
} from './_models'

const API_URL = process.env.REACT_APP_GUGO_API
const CAMPAIGN_URL = `${API_URL}/campaign`
const API_USER = process.env.REACT_APP_GUGO_GRAPHQL_API ?? ''

const getCampaign = (query: string): Promise<CampaignQueryResponse> => {
  return request('get', `${CAMPAIGN_URL}?${query}`).then((d) => d)
}

const getCampaignById = (id: ID): Promise<Customer | undefined> => {
  return axios
    .get(`${CAMPAIGN_URL}/${id}`)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data)
}

const createCustomer = (customer: Customer): Promise<Customer | undefined> => {
  return axios
    .put(CAMPAIGN_URL, customer)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data)
}

const updateCustomer = (customer: Customer): Promise<Customer | undefined> => {
  return axios
    .post(`${CAMPAIGN_URL}/${customer.id}`, customer)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data)
}

const updateStatus = (ids: Array<ID>, status: string): Promise<any> => {
  const requests = ids.map((id) => axios.put(`${CAMPAIGN_URL}/run/${id}`, {status}))
  return axios
    .all(requests)
    .then((e) => e[0]?.data)
    .catch((e) => e)
}
const deleteCampaign = (ids: Array<ID>): Promise<any> => {
  return axios
    .post(`${CAMPAIGN_URL}/deleteMany`, {ids})
    .then((response: AxiosResponse<Response<Campaign>>) => response.data)
}
const b2bGetListOfUsersBasicTel4vnInfo = async (
  variables: B2bGetListOfUsersBasicTel4vnInfoVariables
) => {
  const response: AxiosResponse<ResponseGraphQL<B2bGetListOfUsersBasicTel4vnInfoData>> =
    await axios.post(API_USER, {
      query: `
      query B2bGetListOfUsersBasicTel4vnInfo($page: Int, $limit: Int, $sort: String, $isCreateExtension: Boolean, $userIds: [String], $userUuidIds: [String], $fullName: String, $extensions: [String]) {
        b2bGetListOfUsersBasicTel4vnInfo(page: $page, limit: $limit, sort: $sort, is_create_extension: $isCreateExtension, userIds: $userIds, user_uuidIds: $userUuidIds, fullName: $fullName, extensions: $extensions) {
         items {
                  id
                  fullName
                  avatar {
                    ... on FileWithFullUrls {
                      fileId
                      originalUrl
                    }
                  }
                  provider {
                    tel4vn {
                      user_uuid
                      extension
                    }
                  }
                }
                hasNextPage
                total
        }
      }
        `,
      variables: {
        ...variables,
      },
    })
  return response.data
}

export {
  getCampaign,
  updateStatus,
  getCampaignById,
  createCustomer,
  updateCustomer,
  deleteCampaign,
  b2bGetListOfUsersBasicTel4vnInfo,
}
