
import {Career, CustomerResources, UserType} from '../common/enum'

export const customerResourcesToText = (value: CustomerResources) => {
  switch (value) {
    case CustomerResources.EXCEL:
      return 'Excel'
    case CustomerResources.HANDMADE:
      return 'Thủ công'
    default:
      return '-'
  }
}
export const userTypeToText = (value: UserType) => {
  switch (value) {
    case UserType.INDIVIDUAL:
      return 'CÁ NHÂN'
    case UserType.ENTERPRISE:
      return 'DOANH NGHIỆP'
    default:
      return '-'
  }
}
export const careerToText = (value: Career) => {
  switch (value) {
    case Career.WORK_SAFETY:
      return 'An toàn lao động'
    case Career.DOCTOR:
      return 'Bác sĩ'
    case Career.SELL:
      return 'Bán hàng'
    case Career.TECHNICAL_SALES:
      return 'Bán hàng kỹ thuật'
    case Career.RETAIL_WHOLESALE:
      return 'Bán lẻ/Bán sỉ'
    case Career.INSURANCE:
      return 'Bảo hiểm'
    case Career.MAINTENANCE_REPAIR:
      return 'Bảo trì/Sửa chữa'
    case Career.REAL_ESTATE:
      return 'Bất động sản'
    case Career.TRANSLATOR:
      return 'Biên phiên dịch'
    case Career.EXECUTIVE_MANAGEMENT_LEVEL:
      return 'Cấp quản lý điều hành'
    case Career.STOCK:
      return 'Chứng khoán'
    case Career.HIGH_TECHNOLOGY:
      return 'Công nghệ cao'
    case Career.MECHANICAL:
      return 'Cơ khí'
    case Career.OIL_AND_GAS:
      return 'Dầu khí'
    case Career.TEXTILE_SHOES:
      return 'Dệt may/Da giày'
    case Career.CUSTOMER_SERVICE:
      return 'Dịch vụ khách hàng'
    case Career.PHARMACEUTICAL_BIOTECH:
      return 'Dược phẩm/Công nghệ sinh học'
    case Career.PHARMACIST:
      return 'Dược sĩ'
    case Career.GEOLOGY_MINERAL:
      return 'Địa chất/Khoáng sản'
    case Career.REFRIGERATION_REFRIGERATION:
      return 'Điện lạnh/Nhiệt lạnh'
    case Career.POWER_ELECTRONICS:
      return 'Điện/Điện tử'
    case Career.ENTERTAINMENT:
      return 'Giải trí'
    case Career.EDUCATIONS:
      return 'Giáo dục/Đào tạo'
    case Career.HIGH_END_GOODS:
      return 'Hàng cao cấp'
    case Career.HOUSEHOLD_APPLIANCES:
      return 'Hàng gia dụng'
    case Career.SHIPPING:
      return 'Hàng hải'
    case Career.AVIATION_TOURISM:
      return 'Hàng không/Du lịch'
    case Career.CONSUMER_GOODS:
      return 'Hàng tiêu dùng'
    case Career.ADMINISTRATION_CLERICAL:
      return 'Hành chánh/Thư ký'
    case Career.CHEMISTRY_BIOCHEMISTRY:
      return 'Hóa học/Hóa sinh'
    case Career.PLANNING_PROJECTS:
      return 'Hoạch định/Dự án'
    case Career.PRINTING_PUBLISHING:
      return 'In ấn/Xuất bản'
    case Career.INTERNET_ONLINE_MEDIA:
      return 'Internet/Online Media'
    case Career.IT_HARDWARE_NETWORK:
      return 'IT-Phần cứng/Mạng'
    case Career.IT_SOFTWARE:
      return 'IT-Phần mềm'
    case Career.ACCOUNTANT:
      return 'Kế toán'
    case Career.LOGISTICS:
      return 'Kho vận'
    case Career.AUDIT:
      return 'Kiểm toán'
    case Career.ARCHITECTURE_INTERIOR_DESIGN:
      return 'Kiến trúc/Thiết kế nội thất'
    case Career.BEAUTY_SPA:
      return 'Làm đẹp/Spa'
    case Career.WORK_ABROAD:
      return 'Làm việc ở nước ngoài'
    case Career.THE_LAW:
      return 'Luật'
    case Career.MARKETING:
      return 'Marketing'
    case Career.ENVIRONMENT_WASTE_REATMENT:
      return 'Môi trường/Xử lý chất thải'
    case Career.JUST_HAVE_GRADUATED:
      return 'Mới tốt nghiệp'
    case Career.FINE_ARTS_ART_DESIGN:
      return 'Mỹ thuật/Nghệ thuật/Thiết kế'
    case Career.BANK:
      return 'Ngân hàng'
    case Career.FOREIGNERS_VIET_KIEU:
      return 'Người nước ngoài/Việt kiều'
    case Career.RESTAURANT_HOTEL:
      return 'Nhà hàng/Khách sạn'
    case Career.PERSONNEL:
      return 'Nhân sự'
    case Career.AGRICULTURE_FORESTRY:
      return 'Nông nghiệp/Lâm nghiệp'
    case Career.JURIDICAL:
      return 'Pháp lý'
    case Career.NON_GOVERNMENT_NON_PROFIT:
      return 'Phi chính phủ/Phi lợi nhuận'
    case Career.QA_QC:
      return 'QA/QC (Đảm bảo chất lượng/Kiểm soát chất lượng)'
    case Career.ADVERTISING_PROMOTION_FOREIG_AFFAIRS:
      return 'Quảng cáo/Quan hệ đối ngoại'
    case Career.PRODUCT_OF_INDUSTRY:
      return 'Sản phẩm/Công nghiệp'
    case Career.MANUFACTURE:
      return 'Sản xuất'
    case Career.TECHNOLOGY_FINANCE:
      return 'Tài chính/Công nghệ'
    case Career.FINANCE_INVESTMENT:
      return 'Tài chính/Đầu tư'
    case Career.SPORT:
      return 'Thể thao'
    case Career.FASHION:
      return 'Thời trang'
    case Career.SEASONAL_SHORT_TERM_CONTRACT:
      return 'Thời vụ/Hợp đồng ngắn hạn'
    case Career.PET:
      return 'Thú cưng'
    case Career.PROCUREMENT_SUPPLIES_SUPPLYING:
      return 'Thu mua/Cung cấp/Vật tư'
    case Career.FOOD_BEVERAGE:
      return 'Thực phẩm/Đồ uống'
    case Career.ECOMMERCE:
      return 'Thương mại điện tử'
    case Career.PHARMACEUTICAL_REPRESENTATIVES:
      return 'Trình dược viên'
    case Career.TELEVISION_MEDIA_JOURNALISM:
      return 'Truyền hình/Media/Báo chí'
    case Career.AUTOMATION_AUTOMOTIVE:
      return 'Tự động hóa/Ô tô'
    case Career.ADVISE:
      return 'Tư vấn'
    case Career.SHIPPING_DELIVERY:
      return 'Vận chuyển/Giao nhận'
    case Career.TELECOMMUNICATION:
      return 'Viễn thông'
    case Career.BUILD:
      return 'Xây dựng'
    case Career.IMPORT_AND_EXPORT:
      return 'Xuất nhập khẩu'
    case Career.DOCTOR_AIDE:
      return 'Y tá'
    case Career.MEDICAL_HEALTHCARE:
      return 'Y tế/Chăm sóc sức khỏe'
    case Career.OTHER:
      return 'Khác'

    default:
      return '-'
  }
}
