import {FC, Suspense, lazy} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import ExtensionPage from 'src/app/modules/apps/extensions/ExtensionPage'
import ReportPageWrapper from 'src/app/modules/apps/report'
import {getCSSVariableValue} from '../../_biha/assets/ts/_utils'
import {WithChildren} from '../../_biha/helpers'
import {MasterLayout} from '../../_biha/layout/MasterLayout'

const PrivateRoutes = () => {
  const CampaignsPage = lazy(() => import('../modules/apps/campaign/CampaignPage'))
  const ConfigsPage = lazy(() => import('../modules/apps/configuration/ConfigurationPage'))
  const SwitchboardPageWrapper = lazy(
    () => import('src/app/modules/apps/switchboard/SwitchboardPage')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/call-center/campaign-list/auto-call' />} />
        <Route
          path='call-center/*'
          element={
            <SuspensedView>
              <CampaignsPage />
              <ConfigsPage />
              <ReportPageWrapper />
              <ExtensionPage />
              <SwitchboardPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
