
import { useQueryResponse } from '../../core/QueryResponseProvider'
interface AutoCallListFilterProps {
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>
}
const AutoCallListFilter = ({setShowFilter}: AutoCallListFilterProps) => {
  const {isLoading} = useQueryResponse()

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-secondary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={() => setShowFilter((prev) => !prev)}
      >
        <i className='ki-duotone ki-filter fs-3'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
        Bộ lọc
      </button>
      {/* end::Filter Button */}
    </>
  )
}

export { AutoCallListFilter }

