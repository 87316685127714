// @ts-nocheck
import {Column} from 'react-table'
import {Extension} from '../../../core/_models'
import {CampaignCustomHeader} from './CampaignCustomHeader'
import {AvatarUser} from 'src/app/shared/components/AvatarUser/AvatarUser'
import {DateTimeCell} from './DateTimeCell'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const extensionColumns: ReadonlyArray<Column<Extension>> = [
  {
    Header: (props) => <CampaignCustomHeader title='STT' tableProps={props} />,
    id: 'index',
    width: 50,
    // filter: 'STT',
    Cell: ({...props}) => <div>{props.row.index + 1}</div>,
  },
  {
    Header: 'Máy nhánh',
    id: 'extension',
    width: 250,
    Cell: ({...props}) => (
      <OverlayTrigger
        placement='top'
        overlay={
          <Tooltip id={props.data[props.row.index].extension}>
            <div className='w-300px'>{props.data[props.row.index].extension}</div>
          </Tooltip>
        }
      >
        <div className='text-overflow'>{props.data[props.row.index].extension}</div>
      </OverlayTrigger>
    ),
  },
  {
    Header: 'Tên nhân viên',
    id: 'employee',
    Cell: ({...props}) => (
      <AvatarUser
        fullName={props.data[props.row.index].user?.fullName}
        imageUrl={props.data[props.row.index].user?.avatar}
        width='fit-content'
        minWidth={200}
      />
    ),
  },
  {
    id: 'status',
    Header: 'Trạng thái',
    filter: 'Trạng thái',
    width: 150,

    Cell: ({...props}) => {
      const renderTag = () => {
        switch (props.data[props.row.index].enabled) {
          case false:
            return <span className='badge badge-light-danger fs-8'>Đã tắt</span>
          case true:
            return <span className='badge badge-light-primary fs-8'>Hoạt động</span>

          default:
            return ''
        }
      }
      return <div className='w-150px'>{renderTag()}</div>
    },
  },
  {
    Header: 'Người tạo',
    id: 'createdBy',
    Cell: ({...props}) => (
      <AvatarUser
        imageUrl={props.data[props.row.index].created_by?.avatar ?? ''}
        fullName={props.data[props.row.index]?.created_by?.fullName ?? ''}
        width='fit-content'
        minWidth={200}
      />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader tableProps={props} title='Ngày tạo' className='min-w-200px' />
    ),
    width: 200,
    id: 'created_at2',
    Cell: ({...props}) => (
      <DateTimeCell
        className='w-200px'
        value={props.data[props.row.index].created_at}
        format='HH:mm:ss, DD/MM/YYYY'
      />
    ),
  },
]

export {extensionColumns}
