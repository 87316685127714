import {FC, ReactNode} from 'react'
import {Tooltip as BootstrapTooltip, OverlayTrigger} from 'react-bootstrap'
import {Placement} from 'react-bootstrap/esm/types'
import {WithChildren} from '../../../../../_biha/helpers'

interface Props {
  content?: ReactNode
  position?: Placement
}

const Tooltip: FC<Props & WithChildren> = ({content, position = 'auto', children}) => {
  return (
    <OverlayTrigger
      placement={position}
      trigger={['hover', 'focus']}
      overlay={
        <BootstrapTooltip
          bsPrefix='m-0'
          className='w-auto bg-white rounded-2 shadow-sm'
          style={{zIndex: 99}}
        >
          {content}
        </BootstrapTooltip>
      }
    >
      <div>{children}</div>
    </OverlayTrigger>
  )
}

export {Tooltip}
