// @ts-nocheck
import {Column} from 'react-table'
import {Campaign} from '../../../core/_models'
import {CampaignCustomHeader} from './CampaignCustomHeader'
import {CustomerSelectionCell} from './CustomerSelectionCell'
import {CustomerSelectionHeader} from './CustomerSelectionHeader'
import {DateTimeCell} from './DateTimeCell'
import {TextCell} from './TextCell'
import {AvatarUser} from 'src/app/shared/components/AvatarUser/AvatarUser'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const autoDialColumns: ReadonlyArray<Column<Campaign> & {filter?: string}> = [
  {
    Header: (props) => <CustomerSelectionHeader tableProps={props} />,
    id: 'selection',
    width: 10,
    Cell: ({...props}) => <CustomerSelectionCell id={props.data[props.row.index]._id} />,
  },
  {
    Header: (props) => <CampaignCustomHeader title='STT' tableProps={props} />,
    id: 'index',
    filter: 'STT',
    width: 50,
    Cell: ({...props}) => <div style={{width: 50}}>{props.row.index + 1}</div>,
  },
  {
    Header: 'Tên chiến dịch',
    filter: 'Tên chiến dịch',
    id: 'campaign_name',
    width: 250,
    Cell: ({...props}) => (
      <OverlayTrigger
        placement='top'
        overlay={
          <Tooltip id={props.data[props.row.index].campaign_name}>
            <div className='w-300px'>{props.data[props.row.index].campaign_name}</div>
          </Tooltip>
        }
      >
        <div className='me-4 text-overflow'>{props.data[props.row.index].campaign_name}</div>
      </OverlayTrigger>
    ),
  },
  {
    id: 'status',
    Header: 'Trạng thái',
    filter: 'Trạng thái',
    width: 150,

    Cell: ({...props}) => {
      const renderTag = () => {
        switch (props.data[props.row.index].status) {
          case 'stop':
            return <span className='badge badge-light-danger fs-8 '>Đã hủy</span>
          case 'start':
            return <span className='badge badge-light-primary fs-8'>Đang thực hiện</span>

          default:
            return <span className='badge badge-light fs-8'>Chưa bắt đầu</span>
        }
      }
      return <div className='w-152px'>{renderTag()}</div>
    },
  },
  {
    Header: 'Số lượng',
    filter: 'Số lượng',
    id: 'lead_count',
    width: 140,
    Cell: ({...props}) => (
      <TextCell className='min-w-140px' value={props.data[props.row.index].lead_count} />
    ),
  },

  {
    Header: 'NHÂN VIÊN ĐƯỢC PHÂN CÔNG',
    // Header: (props) => <CampaignCustomHeader title='NHÂN VIÊN ĐƯỢC PHÂN CÔNG' tableProps={props} />,
    id: 'carrier_uuid',
    filter: 'Nhân viên được phân công',
    width: 240,
    Cell: ({...props}) =>
      props.data[props.row.index].extensions?.length === 1 ? (
        <AvatarUser
          width='fit-content'
          minWidth={200}
          imageUrl={
            props.data[props.row.index].extensions?.length
              ? props.data[props.row.index].extensions[0]?.avatar
              : ''
          }
          fullName={
            props.data[props.row.index].extensions?.length
              ? props.data[props.row.index].extensions[0]?.fullName
              : ''
          }
        />
      ) : props.data[props.row.index].extensions?.length > 4 ? (
        <div class='symbol-group symbol-hover' style={{height: 40, width: 240}}>
          {props.data[props.row.index].extensions?.map(
            (user, index) =>
              index < 3 &&
              user?.avatar && (
                <div class='symbol symbol-circle symbol-40px'>
                  <img src={user?.avatar} alt='avatar' className='rounded-circle' />
                </div>
              )
          )}
          <div class='symbol symbol-circle symbol-40px'>
            <div class='symbol-label fs-12px fw-semibold bg-success text-inverse-danger'>
              + {props.data[props.row.index].extensions?.length - 3}
            </div>
          </div>
        </div>
      ) : (
        <div class='symbol-group symbol-hover' style={{height: 40, width: 240}}>
          {props.data[props.row.index].extensions?.map(
            (user) =>
              user?.avatar && (
                <div class='symbol symbol-circle symbol-40px'>
                  <img src={user?.avatar} alt='avatar' className='rounded-circle' />
                </div>
              )
          )}
        </div>
      ),
  },
  {
    Header: 'Ngày bắt đầu',
    id: 'local_start_time',
    filter: 'Ngày bắt đầu',
    width: 200,

    Cell: ({...props}) => (
      <DateTimeCell className='w-200px' value={props.data[props.row.index].local_start_time} />
    ),
  },
  {
    Header: 'NGÀY KẾT THÚC',
    id: 'local_end_time',
    filter: 'Ngày kết thúc',
    width: 200,

    Cell: ({...props}) => (
      <DateTimeCell className='w-200px' value={props.data[props.row.index].local_start_time} />
    ),
  },
  {
    Header: 'Người tạo',
    id: 'created_by',
    filter: 'Người tạo',
    width: 190,

    Cell: ({...props}) => (
      <AvatarUser
        imageUrl={props.data[props.row.index].created_by?.avatar}
        fullName={props.data[props.row.index].created_by?.fullName}
        width='fit-content'
        minWidth={200}
      />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader tableProps={props} title='Ngày tạo' className='w-200px' />
    ),
    filter: 'Ngày tạo',
    id: 'created_at',
    width: 200,

    Cell: ({...props}) => (
      <DateTimeCell
        className='w-200px'
        value={props.data[props.row.index].created_at}
        format='HH:mm:ss, DD/MM/YYYY'
      />
    ),
  },
  // {
  //   Header: (props) => (
  //     <CampaignCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <CustomerActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {autoDialColumns}
