import axios, {AxiosResponse} from 'axios'
import {GetUserConfigurationsData, UserConfigsResponseGraphQL} from './_models'

const MAIN_ENDPOINT = process.env.REACT_APP_GUGO_GRAPHQL_API ?? ''

const getUserConfigurations = async (): Promise<GetUserConfigurationsData | undefined> => {
  const response: AxiosResponse<UserConfigsResponseGraphQL> = await axios.post(MAIN_ENDPOINT, {
    query: `
      query GetUserConfigurations {
        getUserConfigurations {
          bankEnum {
            key
            value
          }
          careerEnum {
            key
            value
          }
          companyFields {
            key
            value
          }
          industryGroupEnum {
            key
            value
          }
          memberPrice
          memberStatusEnum {
            key
            value
          }
          premiumRequestStatus {
            key
            value
          }
          tollStatusEnum {
            key
            value
          }
          userEnum {
            value
            key
          }
          userType {
            key
            value
          }
        }
      }
    `,
  })
  return response.data.data
}

export {getUserConfigurations}
