import axios, {AxiosResponse} from 'axios'
import {ConfigGetSerialization, ConfigsQueryResponse} from './_models'

const API_CRM_URL = process.env.REACT_APP_CRM_API
export const CONFIG_URL = `${API_CRM_URL}/config`

const getConfigurations = async (): Promise<ConfigGetSerialization | undefined> => {
  const response: AxiosResponse<ConfigsQueryResponse> = await axios.get(CONFIG_URL)
  return response.data.data
}

export {getConfigurations}
