import axios, {AxiosRequestConfig} from 'axios'
import {getAuth} from 'src/app/modules/auth'

// const instance = axios.create();
type Method = 'get' | 'post' | 'delete' | 'put'

const axiosInstance = axios.create({
  timeout: 60000,
  baseURL: process.env.REACT_APP_API_URL,
})
// instance.defaults.baseURL = API_URL;

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.headers!.Authorization) {
      return config
    } else {
      const auth = getAuth()
      const {accessToken} = auth ?? {}
      config.data = config.data ? config.data : {}
      config.headers!.Authorization = 'Bearer ' + accessToken
      return config
    }
  },
  (error) => Promise.reject(error)
)
axiosInstance.interceptors.response.use(
  function (response) {
    return response.data
  },
  function (error) {
    return Promise.reject(error)
  }
)
export type Response<T = any> = {
  [x: string]: any

  status: boolean
  data: T
}

export type IResponseApi<T = any> = Promise<Response<T>>

/**
 *
 * @param method - Request methods
 * @param url - Request url
 * @param data - Request data or params
 * @param config - Config of axios request
 */
export const request = <T = any>(
  method: Method,
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): IResponseApi<T> => {
  let params = {}
  if (typeof data === 'string' || data instanceof String) {
  } else if (Array.isArray(data)) {
  } else if (data) {
    Object.keys(data).forEach((key) => {
      let value = data[key]

      if (typeof value === 'string') {
        value = value.trim()
      }

      data[key] = value

      if ([null, '', undefined].includes(value)) {
        delete data[key]
      }
    })
  }

  if (method === 'get') {
    params = data
  }

  return axiosInstance({
    method,
    url,
    data,
    params,
    ...config,
  }) as any
}
export default request
