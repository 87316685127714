import {Module} from 'src/app/modules/auth'
import {RouterProps} from '../layout/components/header/header-menus/routers'
import {filter, includes} from 'lodash'

const filterRoutersAppByModule = (routesApp: RouterProps[], modules?: Module[]) => {
  return filter(
    routesApp,
    (route) => (route.module && includes(modules, route.module)) || !route.module
  )
}

export {filterRoutersAppByModule}
