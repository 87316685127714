import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_biha/layout/core'
import { ExtensionListWrapper } from './ExtensionListWrapper'

export const customersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Tổng đài thông minh',
    path: '/call-center',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ExtensionPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='extension'
          element={
            <>
              <PageTitle breadcrumbs={customersBreadcrumbs}>Quản lý máy nhánh</PageTitle>
              <ExtensionListWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ExtensionPage
