// @ts-nocheck
import clsx from 'clsx'
import {FC, useEffect} from 'react'
import {Row} from 'react-table'
import {ToggleComponent} from 'src/_biha/assets/ts/components'
import {reInitMenu} from 'src/_biha/helpers'
import {Extension} from '../../../core/_models'

type Props = {
  row: Row<Extension>
  onMouseEnterRow?: (index: number) => void
}

const CustomRow: FC<Props> = ({row, onMouseEnterRow}) => {
  useEffect(() => {
    setTimeout(() => {
      reInitMenu()

      ToggleComponent.bootstrap()
    }, 500)
  })

  const handleMouseEnter = () => {
    onMouseEnterRow?.(row.index)
  }

  return (
    <tr
      {...row.getRowProps()}
      className='actions-wrapper text-nowrap cursor-pointer'
      onMouseEnter={handleMouseEnter}
    >
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
            style={{height: 56}}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
      {/* <div className='actions'>
        <Dropdown  >
          <Dropdown.Toggle as={CustomToggle}>Thao tác</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                dispatch(setGlobalState({selectedExtension: row.original}))
              }}
            >
              Chỉnh sửa
            </Dropdown.Item>
            <Dropdown.Item
              onClick={async () => await removeExtenstion.mutateAsync({id: row.original._id})}
              className='text-danger'
            >
              Xoá
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div> */}
    </tr>
  )
}

export {CustomRow}
