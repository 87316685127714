const NextIcon = () => (
  <div
    style={{
      position: 'absolute',
      right: '24px',
      top: '22px',
    }}
  >
    <div className='h-32px w-32px rounded-2 p-0 bg-gray-100 d-flex justify-content-center align-items-center'>
      <i className='ki-duotone ki-right fs-2 text-gray-700'></i>
    </div>
  </div>
)

export {NextIcon}
