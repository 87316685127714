import { FormikProvider, useFormik } from 'formik'
import { map } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Collapse, Dropdown, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { ReactComponent as Success } from 'src/_biha/assets/svg/shield-tick.svg'
import { stringifyRequestQuery, useDebounce } from 'src/_biha/helpers'
import {
  Employee,
  IListDepartment,
} from 'src/app/modules/apps/campaign/campaign-list/campaign-create/core/_models'
import {
  getListDepartmentNoneExtension,
  getListUserNoneExtension,
} from 'src/app/modules/apps/campaign/campaign-list/campaign-create/core/_requests'
import { AvatarUser } from 'src/app/shared/components/AvatarUser/AvatarUser'
import { CloseButton } from 'src/app/shared/components/Modal/ModalConfirm/ModalHeader'
import { Pagination } from 'src/app/shared/interfaces'
import { hideDropdown, removeAccent } from 'src/app/shared/utils'
import { FormInput } from 'src/app2/modules/apps/components/form-input/FormInput'
import * as Yup from 'yup'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { createExtension } from '../../core/_requests'
import { ModalHeader } from './ModalHeader'

const createExtensionSchema = Yup.object().shape({
  user_uuid: Yup.string().required('Vui lòng chọn nhân viên'),
  extension: Yup.string().required('Vui lòng nhập máy nhánh'),
  enabled: Yup.boolean().required('Vui lòng chọn trạng thái'),
  password: Yup.string().required('Vui lòng nhập mật khẩu'),
  passwordConfirmation: Yup.string()
    .required('Vui lòng nhập xác nhận mật khẩu')
    .oneOf([Yup.ref('password'), null], 'Mật khẩu không khớp'),
})

interface ModalCreateProps {
  onClose: () => void
}
const ModalCreate = ({onClose}: ModalCreateProps) => {
  const {refetch} = useQueryResponse()
  const [isLoading, setIsLoading] = useState(false)
  const [listCustomer, setListCustomer] = useState<any[]>([])
  const [listDepartment, setListDepartment] = useState<IListDepartment[]>([])
  const btnRef = useRef<HTMLButtonElement | null>(null)
  const [isDepartment, setIsDepartment] = useState<boolean>(false)
  const [employeeExtension, setEmployeeExtension] = useState('')
  const [searchType, setSearchType] = useState<string>('')
  const [searchTypeDepartment, setSearchTypeDepartment] = useState<string>('')
  const [filteredOptions, setFilteredOptions] = useState(listCustomer)
  const [filteredOptionsDepartment, setFilteredOptionsDepartment] = useState(listDepartment)

  const fetchData = async () => {
    const paging: Pagination = {page: 1, perPage: 100, items_per_page: 100}
    const listRes = await getListUserNoneExtension()
    if (listRes && listRes.b2bGetListOfUsersBasicTel4vnInfo) {
      setListCustomer(listRes.b2bGetListOfUsersBasicTel4vnInfo.items)
    }

    const resDepartment = await getListDepartmentNoneExtension(stringifyRequestQuery(paging))
    if (resDepartment.data.b2bGetListEmployeeBasicDepartments) {
      const dataDepartment: IListDepartment[] = []
      map(resDepartment.data.b2bGetListEmployeeBasicDepartments, (item, index: number) => {
        let filterEmployee: any = []
        item.employees.forEach((ele: any) => {
          filterEmployee.push(ele)
        })

        dataDepartment.push({
          id: item.id,
          name: item.name,
          employee: filterEmployee,
          active: index === 0 ? true : false,
        })
      })
      setListDepartment(dataDepartment)
    }
  }

  const formik = useFormik({
    initialValues: {
      extension: '',
      user_uuid: '',
      enabled: 'true',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: createExtensionSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        setIsLoading(true)
        const createRes = await createExtension({
          ...values,
          enabled: values.enabled === 'true' ? true : false,
          passwordConfirmation: undefined,
        })
        if (createRes) {
          toast.success(
            ({closeToast}) => (
              <div className='text-black'>
                <div className='fw-bold'>Thành công!</div>
                <div className='text-muted'>Tạo máy nhánh thành công!</div>
              </div>
            ),
            {icon: () => <Success />, closeButton: CloseButton}
          )
          refetch()
        }
      } catch (ex: any) {
        toast.error(`${ex.response.data._error}`)
      } finally {
        setIsLoading(false)

        setSubmitting(true)
        onClose()
      }
    },
  })

  useEffect(() => {
    if (isLoading) {
      btnRef.current?.setAttribute('data-kt-indicator', 'on')
    } else {
      btnRef.current?.removeAttribute('data-kt-indicator')
    }
  }, [isLoading])

  useEffect(() => {
    fetchData()
  }, [])

  const debouncedSearchTerm = useDebounce(searchType, 150)
  useEffect(() => {
    const _options: any = listCustomer.filter(
      (option) =>
        (debouncedSearchTerm &&
          removeAccent(option.fullName)
            .toLowerCase()
            .includes(removeAccent(debouncedSearchTerm)?.toLowerCase())) ||
        !debouncedSearchTerm
    )

    setFilteredOptions(_options.filter((x: Employee) => x?.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, listCustomer])

  const debouncedSearchTermDepartment = useDebounce(searchTypeDepartment, 150)

  useEffect(() => {
    const _options: any = listDepartment.filter(
      (option) =>
        option.employee.filter(
          (item) =>
            (debouncedSearchTermDepartment &&
              removeAccent(item?.fullName ?? '')
                .toLowerCase()
                .includes(removeAccent(debouncedSearchTermDepartment)?.toLowerCase())) ||
            !debouncedSearchTermDepartment
        ).length
    )
    setFilteredOptionsDepartment(_options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTermDepartment, listDepartment])

  const CustomToggle = React.forwardRef(({children, onClick}: any, ref: any) => (
    <button
      className='btn btn-outline h-40px w-100 d-flex justify-content-between align-items-center'
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <span>{children}</span>
      <span className='svg-icon fs-3 rotate-180 ms-3 me-0'>
        <i className='ki-duotone ki-down'></i>
      </span>
    </button>
  ))
  return (
    <FormikProvider value={formik}>
      <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
        <div className='modal-dialog modal-dialog-centered min-w-25'>
          <div
            style={{padding: '24px', height: '80vh', overflow: 'hidden'}}
            className='modal-content '
          >
            <ModalHeader title='Thêm máy nhánh' onClose={onClose} />
            <div className='separator my-2'></div>
            <Form
              onSubmit={formik.handleSubmit}
              noValidate
              style={{overflow: 'auto'}}
              className=' modal-body'
            >
              <Form.Group controlId='user_uuid' className='filter-group'>
                <Form.Label className='required fw-bold fs-6 mb-3 w-50'>Tên nhân viên</Form.Label>
                <Dropdown
                  onToggle={(show) => {
                    if (!show) {
                      setEmployeeExtension('')
                    } else {
                      setEmployeeExtension(formik.values.user_uuid)
                    }
                  }}
                >
                  <Dropdown.Toggle as={CustomToggle}>
                    {listCustomer?.find(
                      (customer) =>
                        customer?.provider?.tel4vn?.user_uuid === formik.values.user_uuid
                    )?.fullName ?? 'Chọn nhân viên'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='w-100'>
                    <div>
                      <div className='menu-item'>
                        <div className='d-flex gap-4'>
                          <button
                            type='button'
                            onClick={() => {
                              setIsDepartment(false)
                            }}
                            className={`btn mt-2 ${isDepartment ? 'btn-light' : 'btn-primary'}`}
                          >
                            Nhân viên
                          </button>
                          <button
                            type='button'
                            onClick={() => {
                              setIsDepartment(true)
                            }}
                            className={`btn ${!isDepartment ? 'btn-light' : 'btn-primary'} mt-2`}
                          >
                            Phòng ban
                          </button>
                        </div>
                        {!isDepartment ? (
                          <div className='w-100'>
                            <div className='menu-item px-3 d-flex align-items-center position-relative my-4'>
                              <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-3'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                              <input
                                type='text'
                                data-kt-table-filter='search'
                                className='form-control w-100 ps-12'
                                placeholder='Tìm kiếm'
                                value={searchType}
                                onChange={(e) => setSearchType(e.target.value)}
                              />
                            </div>
                            <div className='department overflow-auto px-1 h-140px'>
                              {map(filteredOptions, (column) => (
                                <div className='w-100' style={{height: 52}} key={column.value}>
                                  <div
                                    onClick={() => {
                                      setEmployeeExtension(column?.provider?.tel4vn?.user_uuid)
                                    }}
                                    className={`custom-department form-check form-check-custom form-check-lg d-flex justify-content-between align-items-center py-3 px-2 my-1 cursor-pointer ${
                                      employeeExtension === column?.provider?.tel4vn?.user_uuid
                                        ? 'item-checked'
                                        : ''
                                    }`}
                                    key={column?.provider?.tel4vn?.user_uuid}
                                    // eventKey={column?.provider?.tel4vn?.user_uuid}
                                  >
                                    <AvatarUser
                                      imageUrl={column.avatar?.originalUrl ?? ''}
                                      fullName={column.fullName ?? ''}
                                      styleName
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className='menu-item px-3 d-flex align-items-center position-relative my-4'>
                              <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-3'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                              <input
                                type='text'
                                data-kt-table-filter='search'
                                className='form-control w-100 ps-12'
                                placeholder='Tìm kiếm'
                                value={searchTypeDepartment}
                                onChange={(e) => setSearchTypeDepartment(e.target.value)}
                              />
                            </div>
                            <div className='overflow-auto h-140px d-flex flex-column gap-8'>
                              {filteredOptionsDepartment.map((department) => {
                                return (
                                  <div key={department.id} className='department'>
                                    <div
                                      className='d-flex justify-content-between align-items-center gap-2 py-2 bg-light-primary px-4'
                                      style={{borderRadius: 8}}
                                      onClick={(e) => {
                                        const showDepartment = listDepartment.map((show) => {
                                          if (show.id === department.id) {
                                            return {
                                              employee: show.employee,
                                              id: show.id,
                                              name: show.name,
                                              active: true,
                                            }
                                          } else {
                                            return {
                                              employee: show.employee,
                                              id: show.id,
                                              name: show.name,
                                              active: false,
                                            }
                                          }
                                        })
                                        setListDepartment(showDepartment)
                                      }}
                                    >
                                      <label
                                        className='form-check-label fw-bolder'
                                        style={{color: '#7E8299'}}
                                      >
                                        {department.name}
                                      </label>
                                      <div className='d-flex align-items-center'>
                                        {department.active ? (
                                          <span className='svg-icon svg-icon-primary svg-icon-2x'>
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              xmlnsXlink='http://www.w3.org/1999/xlink'
                                              width='24px'
                                              height='24px'
                                              viewBox='0 0 24 24'
                                              version='1.1'
                                            >
                                              <g
                                                stroke='none'
                                                stroke-width='1'
                                                fill='none'
                                                fill-rule='evenodd'
                                              >
                                                <polygon points='0 0 24 0 24 24 0 24' />
                                                <path
                                                  d='M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z'
                                                  fill='#000000'
                                                  fill-rule='nonzero'
                                                  transform='translate(12.000003, 11.999999) rotate(-180.000000) translate(-12.000003, -11.999999) '
                                                />
                                              </g>
                                            </svg>
                                          </span>
                                        ) : (
                                          <span className='svg-icon svg-icon-primary svg-icon-2x'>
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              xmlnsXlink='http://www.w3.org/1999/xlink'
                                              width='24px'
                                              height='24px'
                                              viewBox='0 0 24 24'
                                              version='1.1'
                                            >
                                              <g
                                                stroke='none'
                                                stroke-width='1'
                                                fill='none'
                                                fill-rule='evenodd'
                                              >
                                                <polygon points='0 0 24 0 24 24 0 24' />
                                                <path
                                                  d='M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z'
                                                  fill='#000000'
                                                  fill-rule='nonzero'
                                                />
                                              </g>
                                            </svg>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    {department.active && (
                                      <Collapse in={department.active} key={department.id}>
                                        <div className=' d-flex flex-column gap-2 ps-4'>
                                          {map(department.employee, (column) => (
                                            <div className='d-flex justify-content-between align-items-center'>
                                              <div
                                                // ref={allCheckRef}
                                                className='w-100'
                                                style={{height: 52}}
                                                key={column.provider.tel4vn.extension}
                                              >
                                                <div
                                                  onClick={() => {
                                                    setEmployeeExtension(
                                                      column?.provider?.tel4vn?.user_uuid
                                                    )
                                                  }}
                                                  className={`custom-department form-check form-check-custom form-check-solid form-check-lg d-flex justify-content-between align-items-center py-3 px-2 my-1 cursor-pointer ${
                                                    employeeExtension ===
                                                    column?.provider?.tel4vn?.user_uuid
                                                      ? 'item-checked'
                                                      : ''
                                                  }`}
                                                  key={column?.provider?.tel4vn?.user_uuid}
                                                >
                                                  <AvatarUser
                                                    imageUrl={column.avatar?.originalUrl ?? ''}
                                                    fullName={column.fullName ?? ''}
                                                    styleName
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </Collapse>
                                    )}
                                  </div>
                                )
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='separator my-3 opacity-75'></div>
                    <div className='menu-item px-3'>
                      <div className='menu-content px-3 py-3 d-flex justify-content-end gap-2'>
                        <button
                          type='button'
                          onClick={() => {
                            hideDropdown()
                            setEmployeeExtension('')
                          }}
                          data-kt-menu-trigger='click'
                          className='btn btn-secondary px-4'
                        >
                          Huỷ
                        </button>
                        <button
                          type={'button'}
                          onClick={() => {
                            formik.setFieldValue('user_uuid', employeeExtension)
                            setEmployeeExtension('')
                            hideDropdown()
                          }}
                          className='btn btn-primary px-4'
                        >
                          Xác nhận
                        </button>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Text className='text-danger'>
                  {formik.touched.user_uuid && formik.errors.user_uuid && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.user_uuid}</span>
                      </div>
                    </div>
                  )}
                </Form.Text>
              </Form.Group>
              <div className='fv-row mb-7 w-100 form-group'>
                <label className='required fw-bold fs-6 mt-4 w-50 mb-3'>Máy nhánh</label>
                <FormInput
                  placeholder='Nhập máy nhánh'
                  {...formik.getFieldProps('extension')}
                  type='text'
                  name='extension'
                  maxLength={118}
                  disabled={formik.isSubmitting}
                />
              </div>
              <div className='fv-row mb-7 w-100 form-group'>
                <label className='required fw-bold fs-6 w-50 mb-3'>Mật khẩu</label>
                <FormInput
                  placeholder='Nhập mật khẩu'
                  {...formik.getFieldProps('password')}
                  type='password'
                  name='password'
                  disabled={formik.isSubmitting}
                />
              </div>
              <div className='fv-row mb-7 w-100 form-group'>
                <label className='required fw-bold fs-6 w-50 mb-3'>Nhập lại mật khẩu</label>
                <FormInput
                  placeholder='Nhập lại mật khẩu'
                  {...formik.getFieldProps('passwordConfirmation')}
                  type='password'
                  name='passwordConfirmation'
                  disabled={formik.isSubmitting}
                />
              </div>
              <div className='fv-row mb-7 w-100 form-group'>
                <label className='required fw-bold fs-6 my-2 w-50'>Kích hoạt</label>
                <div className='d-flex gap-4 mt-3'>
                  <div className='form-check form-check-custom form-check-solid form-check-26px'>
                    <input
                      {...formik.getFieldProps('enabled')}
                      name='enabled'
                      className='form-check-input'
                      type='radio'
                      value='true'
                      id='enabled'
                      checked={formik.values.enabled === 'true'}
                      onChange={formik.handleChange}
                    />
                    <label className='form-check-label fw-bold ' htmlFor='enabled'>
                      Có
                    </label>
                  </div>
                  <div className='form-check form-check-custom form-check-solid form-check-26px'>
                    <input
                      {...formik.getFieldProps('enabled')}
                      name='enabled'
                      className='form-check-input'
                      type='radio'
                      value='false'
                      id='disabled'
                      checked={formik.values.enabled === 'false'}
                      onChange={formik.handleChange}
                    />
                    <label className='form-check-label fw-bold ' htmlFor='disabled'>
                      Không
                    </label>
                  </div>
                </div>
              </div>
            </Form>
            <div
              style={{
                display: 'flex',
                gap: 10,
                justifyContent: 'center',
              }}
            >
              <Button variant='secondary' onClick={onClose}>
                Huỷ
              </Button>
              <Button
                ref={btnRef}
                onClick={() => {
                  formik.handleSubmit()
                }}
                variant='primary'
              >
                Thêm máy nhánh
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </FormikProvider>
  )
}

export default ModalCreate
