import {KTCard} from 'src/_biha/helpers'
import { ExtensionTable } from './components/table/ExtensionTable'
import { ExtensionHeader } from './components/header/ExtensionHeader'

const ExtensionList = () => {
  return (
    <KTCard>
      <ExtensionHeader />
      <ExtensionTable />
    </KTCard>
  )
}

export default ExtensionList
