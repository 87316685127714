/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {ReactComponent as Megaphone} from 'src/_biha/assets/svg/megaphone.svg'
const SidebarMenuMain = () => {
  // const intl = useIntl()

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'></div>
      </div>
      <SidebarMenuItemWithSub
        to='/#'
        title='Thống kê'
        customIcon={
          <i className='ki-duotone ki-chart-simple-2 fs-2'>
            <i className='path1'></i>
            <i className='path2'></i>
            <i className='path3'></i>
            <i className='path4'></i>
          </i>
        }
      >
        {/* <SidebarMenuItem hasBullet to='/call-center/campaign-list' title='Danh sách khách hàng' />
        <SidebarMenuItem hasBullet to='/customers/new' title='Thêm mới khách hàng' /> */}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/#' title='Chiến dịch' customIcon={<Megaphone />}>
        <SidebarMenuItem
          itemKey='auto-call'
          hasBullet
          to='/call-center/campaign-list/auto-call'
          title='Gọi tự động'
        />
        <SidebarMenuItem
          itemKey='auto-dial'
          hasBullet
          to='/call-center/campaign-list/auto-dial'
          title='Quay số tự động'
        />
        <SidebarMenuItem itemKey='zns' hasBullet to='/call-center/campaign-list/zns' title='ZNS' />
        <SidebarMenuItem itemKey='sms' hasBullet to='/call-center/campaign-list/sms' title='SMS' />
        <SidebarMenuItem
          itemKey='email'
          hasBullet
          to='/call-center/campaign-list/email'
          title='Email'
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        itemKey='extension'
        to='/call-center/extension'
        title='Thiết lập máy nhánh'
        customIcon={
          <i className='ki-duotone ki-data fs-2'>
            <i className='path1'></i>
            <i className='path2'></i>
            <i className='path3'></i>
            <i className='path4'></i>
            <i className='path5'></i>
          </i>
        }
      />

      <SidebarMenuItemWithSub
        to='/#'
        title='Tổng đài'
        customIcon={
          <i className='ki-duotone ki-whatsapp fs-2'>
            <i className='path1'></i>
            <i className='path2'></i>
          </i>
        }
      >
        <SidebarMenuItem
          itemKey='call-history'
          hasBullet
          to='/call-center/switchboard/call-history'
          title='Lịch sử cuộc gọi'
        />
        <SidebarMenuItem
          itemKey='zns-send-history'
          hasBullet
          to='/call-center/switchboard/zns-send-history'
          title='Lịch sử gửi tin ZNS'
        />
        <SidebarMenuItem
          itemKey='sms-send-history'
          hasBullet
          to='/call-center/switchboard/sms-send-history'
          title='Lịch sử gửi tin SMS'
        />
        <SidebarMenuItem
          itemKey='email-history'
          hasBullet
          to='/call-center/switchboard/email-history'
          title='Lịch sử gửi tin Email'
        />
        <SidebarMenuItem
          itemKey='call-observe'
          hasBullet
          to='/call-center/switchboard/call-observe'
          title='Giám sát cuộc gọi'
        />
        <SidebarMenuItem
          itemKey='session'
          hasBullet
          to='/call-center/switchboard/session'
          title='Phiên đăng nhập'
        />
        <SidebarMenuItem
          itemKey='call-active'
          hasBullet
          to='/call-center/switchboard/call-active'
          title='Cuộc gọi hoạt động'
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        itemKey='report'
        to='/call-center/report'
        title='Báo cáo'
        customIcon={
          <i className='ki-duotone ki-like-tag fs-2'>
            <i className='path1'></i>
            <i className='path2'></i>
          </i>
        }
      />

      <SidebarMenuItemWithSub
        to='/#'
        title='Cấu hình'
        icon='/media/icons/duotune/coding/cod009.svg'
      >
        <SidebarMenuItem
          itemKey='prefix-list'
          hasBullet
          to='/call-center/config-list/prefix-list'
          title='Danh sách đầu số'
        />
        <SidebarMenuItem
          itemKey='element-call'
          hasBullet
          to='/call-center/config-list/element-call'
          title='Thành phần cuộc gọi'
        />
        <SidebarMenuItem
          itemKey='script-call'
          hasBullet
          to='/call-center/config-list/script-call'
          title='Kịch bản cuộc gọi'
        />
        <SidebarMenuItem
          itemKey='zalo-OA-list'
          hasBullet
          to='/call-center/config-list/zalo-OA-list'
          title='Danh sách Zalo OA'
        />
        <SidebarMenuItem
          itemKey='email-list'
          hasBullet
          to='/call-center/config-list/email-list'
          title='Danh sách email'
        />
        <SidebarMenuItem
          itemKey='brand-name-list'
          hasBullet
          to='/call-center/config-list/brand-name-list'
          title='Danh sách brandname'
        />
        <SidebarMenuItem
          itemKey='zalo-template'
          hasBullet
          to='/call-center/config-list/zalo-template'
          title='Zalo Template'
        />
        <SidebarMenuItem
          itemKey='sms-template'
          hasBullet
          to='/call-center/config-list/sms-template'
          title='SMS Template'
        />
        <SidebarMenuItem
          itemKey='email-template'
          hasBullet
          to='/call-center/config-list/email-template'
          title='Email Template'
        />
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
