/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes} from 'react-router-dom'
import {MasterLayout} from 'src/_biha/layout/MasterLayout'
import {Error403} from './components/Error403'
import {Error404} from './components/Error404'
import {Error500} from './components/Error500'

const ErrorsPage = () => (
  <Routes>
    <Route element={<MasterLayout />}>
      <Route path='403' element={<Error403 />} />
      <Route path='404' element={<Error404 />} />
      <Route path='500' element={<Error500 />} />
      <Route index element={<Error404 />} />
    </Route>
  </Routes>
)

export {ErrorsPage}
