import {useField, useFormikContext} from 'formik'
import {filter, flatMap, includes, isArray, isEqual, map} from 'lodash'
import Select from 'react-select'
import {StateManagerProps} from 'react-select/dist/declarations/src/useStateManager'
import {Fragment} from 'react'
import {String} from 'src/app2/modules/_models'

interface MyOption {
  label?: String
  value?: any
}
interface GroupedOption {
  label: string
  options: MyOption[]
}
type Props = {
  name: string
  onChangeProps?: () => void
} & Omit<StateManagerProps<MyOption, false | true, GroupedOption>, 'value'>

const FormSelect = (props: Props) => {
  const {name, onChangeProps, ...restProps} = props
  const [field, meta] = useField(name)
  const {setFieldValue, setFieldTouched, validateField} = useFormikContext()
  const flattenedOptions = flatMap(props.options, (option) => {
    const isNotGrouped = 'value' in option
    if (isNotGrouped) {
      return option
    } else {
      return (option as GroupedOption).options
    }
  })
  const value = filter(flattenedOptions, (option) => {
    const isArrayValue = isArray(field.value)

    if (isArrayValue) {
      const values = field.value as Array<any>
      return includes(values, option.value)
    } else {
      return isEqual(field.value, option.value)
    }
  })

  return (
    <Fragment>
      <Select
        {...restProps}
        className='react-select-container'
        classNamePrefix='react-select'
        value={value}
        isClearable
        onChange={(val, actionMeta) => {
          const _val = val as MyOption[] | MyOption
          const isArray = Array.isArray(_val)
          if (isEqual(actionMeta.action, 'clear')) {
            setFieldTouched(name, true, true)
            setFieldValue(name, undefined)
            validateField(name)
          } else {
            if (isArray) {
              const values = map(_val, (option) => option.value)
              setFieldValue(name, values)
            } else {
              setFieldValue(name, _val?.value)
            }
          }
          onChangeProps?.()
        }}
      />
      {meta.touched && meta.error && (
        <div className='mt-2'>
          <span role='alert' className='text-danger'>
            {meta.error}
          </span>
        </div>
      )}
    </Fragment>
  )
}

export {FormSelect}
