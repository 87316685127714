import axios from 'axios'
import {
  GetListDistrictsOfProvinceData,
  GetListProvincesData,
  GetListStreetsOfDistrictData,
  GetListWardsOfDistrictData,
} from './_models'
import {map} from 'lodash'

const MAP_API_ENDPOINT = `${process.env.REACT_APP_MAP_API_ENDPOINT}/graphql`

const queryGetListProvinces = async () => {
  const response = await axios.post<GetListProvincesData>(MAP_API_ENDPOINT, {
    query: `
        query {
          getListProvinces {
            id
            name
          }
        }
      `,
  })
  if (response.data.data?.getListProvinces) {
    response.data.data.getListProvinces = map(response.data.data.getListProvinces, (province) => ({
      value: {_id: province.id, name: province.name},
      label: province.name,
    }))
  }
  return response
}
const queryGetDistrictsOfProvince = async (provinceId: string) => {
  const response = await axios.post<GetListDistrictsOfProvinceData>(MAP_API_ENDPOINT, {
    query: `
          query ($provinceId: ObjectID!, $limit: Int) {
            getListDistrictsOfProvince(provinceId: $provinceId, limit: $limit) {
                districts {
                  id
                  name
                }
              }
          }
        `,
    variables: {
      provinceId,
      limit: 1000,
    },
  })
  return map(response.data.data?.getListDistrictsOfProvince?.districts, (district) => ({
    value: {_id: district.id, name: district.name},
    label: district.name,
  }))
}
const queryGetWardsOfDistrict = async (districtId: string) => {
  const response = await axios.post<GetListWardsOfDistrictData>(MAP_API_ENDPOINT, {
    query: `
          query ($districtId: ObjectID!, $limit: Int){
            getListWardsOfDistrict(districtId: $districtId, limit: $limit) {
                wards {
                  id
                  name
                }
              }
          }
        `,
    variables: {
      districtId,
      limit: 1000,
    },
  })
  return map(response.data.data?.getListWardsOfDistrict?.wards, (ward) => ({
    value: {_id: ward.id, name: ward.name},
    label: ward.name,
  }))
}
const queryGetStreetsOfDistrict = async (districtId: string) => {
  const response = await axios.post<GetListStreetsOfDistrictData>(MAP_API_ENDPOINT, {
    query: `
          query ($districtId: ObjectID!, $limit: Int){
            getListStreetsOfDistrict(districtId: $districtId, limit: $limit) {
                streets {
                  id
                  name
                }
            }
          }
        `,
    variables: {
      districtId,
      limit: 1000,
    },
  })
  return map(response.data.data?.getListStreetsOfDistrict?.streets, (street) => ({
    value: {_id: street.id, name: street.name},
    label: street.name,
  }))
}
export {
  queryGetListProvinces,
  queryGetDistrictsOfProvince,
  queryGetWardsOfDistrict,
  queryGetStreetsOfDistrict,
}
