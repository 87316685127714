import Cookie from 'js-cookie'
import axios from 'axios'
import request from 'src/app/shared/config/request'
import {B2bGetMeData} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const API_GRAPHQL_URL = process.env.REACT_APP_GUGO_GRAPHQL_API

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel

export function getToken() {
  const cookiesValue: string | undefined = Cookie.get('authentication')
  let auth: any = {}
  if (!cookiesValue) {
    return
  }
  try {
    auth = JSON.parse(cookiesValue)
  } catch (error) {
    console.error('AUTH COOKIES PARSE ERROR', error)
  }
  return request(
    'post',
    `${process.env.REACT_APP_GUGO_GRAPHQL_API}`,
    {
      query: `
      query {
          b2bGetMe {
            payload {
              id
              fullName
              email
              phone
              username
              avatar {
              ...on FileWithFullUrls{
                originalUrl
                }
              }
              provider{
                tel4vn{
                  token
                  domain_uuid
                  domain_name
                  port
                  transports
                  outbound_proxy
                  wss
                  extension
                  user_uuid
                  authPass
                }
              }
                tableSettings {
              tableId
              columns {
                checked
                id
                label
              }
            }

              modules
            }
            msg
            success
          }
        }
    `,
    },
    {
      headers: {Authorization: `Bearer ${auth?.accessToken ?? ''}`},
    }
  ).then((response) => {
    localStorage.setItem('extension', response.data.b2bGetMe.payload.provider.tel4vn.extension)
    localStorage.setItem('tel4Token', response.data.b2bGetMe.payload.provider.tel4vn.token)
    localStorage.setItem('user_uuid', response.data.b2bGetMe.payload.provider.tel4vn.user_uuid)
    localStorage.setItem('kt-auth-react-v', auth?.accessToken)
    return response.data.b2bGetMe.payload
  })
}

export function login(username: string, password: string) {
  return request('post', `${API_GRAPHQL_URL}/graphql`, {
    query: `
        mutation {
          b2bSignIn(username: "${username}" password: "${password}") {
            payload {
              accessToken
              provider{
                tel4vn{
                  token
                  domain_uuid
                  domain_name
                  port
                  transports
                  outbound_proxy
                  wss
                  extension
                  user_uuid
                }
              }
            }
          }
        }
      `,
  }).then((response) => {
    localStorage.setItem('extension', response.data.b2bSignIn.payload.provider.tel4vn.extension)
    localStorage.setItem('tel4Token', response.data.b2bSignIn.payload.provider.tel4vn.token)
    localStorage.setItem('user_uuid', response.data.b2bSignIn.payload.provider.tel4vn.user_uuid)
    return response.data.b2bSignIn.payload
  })
}
export function getMe() {
  return axios.post<B2bGetMeData>(`${API_GRAPHQL_URL}/graphql`, {
    query: `
      query {
        b2bGetMe {
          success
          msg
          payload {
            id
            fullName
            avatar {
              ... on FileWithFullUrls {
                fileId
                originalUrl
              }
            }
            tableSettings {
              tableId
              columns {
                checked
                id
                label
              }
            }
            modules
          }
        }
      }`,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

// export function getUserByToken(token: string) {
//   return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
//     api_token: token,
//   })
// }
