import {formatDateTime} from 'src/app/shared/utils'
import {FC} from 'react'

type Props = {
  value: Date
  className: string
  format?: string
}

const DateTimeCell: FC<Props> = ({value, className, format}) => (
  <div className={className ?? ''}>{formatDateTime(value, format)}</div>
)

export {DateTimeCell}
