import {useState} from 'react'
import ModalCreate from '../modal/ModalCreate'
import {AutoCallListFilter} from './ExtensionListFilter'
import {useAppDispatch, useAppSelector} from 'src/app/redux/hook'
import ModalEdit from '../modal/ModalEdit'
import {setGlobalState} from 'src/app/redux/slice/globalSlice'

interface CustomersListToolbarProps {
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>
}
const CustomersListToolbar = ({setShowFilter}: CustomersListToolbarProps) => {
  const [visibleModal, setVisibleModal] = useState(false)
  const {selectedExtension} = useAppSelector((state) => state.global)
  const dispatch = useAppDispatch()
  return (
    <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>
      <AutoCallListFilter setShowFilter={setShowFilter} />
      <button
        type='button'
        className='btn btn-primary'
        onClick={() => {
          setVisibleModal(true)
        }}
      >
        <i className='ki-duotone ki-plus fs-3'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
        Thêm máy nhánh
      </button>
      {visibleModal && <ModalCreate onClose={() => setVisibleModal(false)} />}
      {selectedExtension && (
        <ModalEdit onClose={() => dispatch(setGlobalState({selectedExtension: undefined}))} />
      )}
    </div>
  )
}

export {CustomersListToolbar}
