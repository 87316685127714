import { FC, PropsWithChildren, useMemo } from 'react'
import { HeaderProps } from 'react-table'
import { Customer } from '../../../../auto-call/core/_models'
import { useListView } from '../../../core/ListViewProvider'
import { useQueryResponseData } from '../../../core/QueryResponseProvider'
import { useAppSelector } from 'src/app/redux/hook'

type Props = {
  tableProps: PropsWithChildren<HeaderProps<Customer>>
}

const CustomerSelectionHeader: FC<Props> = ({ tableProps }) => {
  const { clearAll, selected } = useListView()
  const customers = useQueryResponseData()
  const { listCustomerSelect } = useAppSelector((state) => state.campaign)
  const isSelected = useMemo(() => customers.every((i) => listCustomerSelect.includes(i._id)), [customers, listCustomerSelect])
  
  return (
    <th {...tableProps.column.getHeaderProps()} className='w-10px pe-2'>
      <div className='form-check form-check-custom form-check-solid me-3'>
        <input
          className='form-check-input'
          type='checkbox'
          disabled={isSelected}
          data-kt-check={customers?.length > 0 && customers.every((i) => selected.includes(i._id))}
          data-kt-check-target='#kt_table_customers .form-check-input'
          checked={customers?.length > 0 &&
            (customers.every((i) => selected.includes(i._id)) || customers.every((i) => listCustomerSelect.includes(i._id)))
          }
          onChange={clearAll}
        />
      </div>
    </th>
  )
}

export { CustomerSelectionHeader }
