import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_biha/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_biha/layout/core'
import {MasterInit} from '../_biha/layout/MasterInit'
import {AuthInit} from './modules/auth'
import './styles/global.scss'
import {ToastContainer} from 'react-toastify'
import {Chart, registerables} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {CustomerConfigProvider} from 'src/app2/modules/major/customer/CustomerConfigProvider'
import {AddressProvider} from 'src/app2/modules/major/address/AddressProvider'
import {ToastProvider} from 'src/app2/modules/apps/components/toast/Toast'
import {UserConfigurationProvider} from 'src/app2/modules/major/user-config/UserConfigProvider'

Chart.register(...registerables, ChartDataLabels)

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <CustomerConfigProvider>
              <AddressProvider>
                <UserConfigurationProvider>
                  <ToastProvider>
                    <Outlet />
                    <MasterInit />
                    <ToastContainer
                      position='top-right'
                      autoClose={5000}
                      hideProgressBar={true}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      pauseOnHover
                      theme='colored'
                      className='toast-message'
                    />
                  </ToastProvider>
                </UserConfigurationProvider>
              </AddressProvider>
            </CustomerConfigProvider>
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
