/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {initialQueryState, useDebounce} from 'src/_biha/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'

const CustomersListSearchComponent = () => {
  const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({search: debouncedSearchTerm, ...initialQueryState})
    }
  }, [debouncedSearchTerm])

  return (
    <div className='d-flex align-items-center position-relative'>
      <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-3'>
        <span className='path1'></span>
        <span className='path2'></span>
      </i>
      <input
        type='text'
        data-kt-table-filter='search'
        className='form-control control-w-180px ps-12 '
        placeholder='Tìm kiếm'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
  )
}

export {CustomersListSearchComponent}
