import React, { FC, Fragment, useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Row } from 'react-table'
import { setGlobalState } from 'src/app/redux/slice/globalSlice'
import { useQueryResponsePagination } from '../../core/QueryResponseProvider'
import { Extension } from '../../core/_models'
import ModalConfirm from '../modal/ModalConfirm'

interface Props {
  rows: Row<Extension>[]
  index: number
}

const ExtensionDeletedRowActions: FC<Props> = ({ rows, index }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const dispatch = useDispatch()
  const pagination = useQueryResponsePagination()
  const CustomToggle = React.forwardRef(({ children, onClick }: any, ref: any) => (
    <a
      className='btn btn-light d-flex justify-content-between align-items-center'
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <span>{children}</span>
      <span className='svg-icon fs-3 rotate-180 ms-3 me-0'>
        <i className='ki-duotone ki-down'></i>
      </span>
    </a>
  ))

  return (
    <Fragment>
      <div
        className='position-absolute translate-middle-y h-56px d-flex align-items-center justify-content-end'
        style={{
          top: 56.5 + index * 56 + 24, //56.5 + index * 56 + 28
          right: 25,
          backgroundImage: 'linear-gradient(270deg, #ffffff, #ffffff80)',
          width: '12%',
        }}
      >
        <DropdownButton
          drop={pagination.items_per_page - 3 <= index ? 'up' : 'down'}
          id='dropdown-item-button'
          title='Thao tác'
          variant={'light'}
          align={'end'}
          >
          {/* <Dropdown.Toggle as={CustomToggle}>Thao tác</Dropdown.Toggle> */}
          {/* <Dropdown.Menu> */}
          <Dropdown.Item
            onClick={() => {
              dispatch(setGlobalState({ selectedExtension: rows[index].original }))
            }}
          >
            Sửa máy nhánh
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setModalVisible(true)} className='text-danger'>
            Xoá máy nhánh
          </Dropdown.Item>
          {/* </Dropdown.Menu> */}
        </DropdownButton>
      </div>
      {modalVisible && (
        <ModalConfirm onClose={() => setModalVisible(false)} id={[rows[index].original._id!]} />
      )}
    </Fragment>
  )
}

export { ExtensionDeletedRowActions }
