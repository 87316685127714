import clsx from 'clsx'
import {useField} from 'formik'
import {gt, isEqual, isUndefined, lt} from 'lodash'
import {ChangeEvent, FC, InputHTMLAttributes, KeyboardEvent, useState} from 'react'
import {numberWithCommas} from '../../../../utils'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  skipTouched?: boolean
  name: string
  wrapperClassName?: string
  inputClassName?: string
  maxLength?: number
  isNumber?: boolean
}

const FormInput: FC<Props> = ({
  skipTouched,
  name,
  wrapperClassName,
  inputClassName,
  maxLength,
  isNumber,
  ...props
}) => {
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false)
  const {type, disabled} = props
  const [field, meta, helpers] = useField(name)
  const valueWatch = field.value ?? ''

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword)
  }
  const handleClear = () => {
    setIsShowPassword(false)
    helpers.setValue('')
  }
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value
    if (isNumber) {
      inputValue = inputValue.replace(/[^0-9]/g, '')
      inputValue = inputValue.replace(/^0+/, '')
      if (!isNaN(Number(inputValue.replaceAll(',', '')))) {
        inputValue = numberWithCommas(inputValue)
      }
      helpers.setValue(inputValue)
    } else {
      helpers.setValue(inputValue)
    }
  }
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (isEqual(type, 'number') || isNumber) {
      if (
        isEqual(event.key, 'e') ||
        isEqual(event.key, '+') ||
        isEqual(event.key, '-') ||
        gt(Number(event.key), 9) ||
        lt(Number(event.key), 0)
      ) {
        event.preventDefault()
      }
    }
  }

  return (
    <div id={`form-input-${name}`} className='position-relative'>
      <div className='position-relative'>
        <input
          {...props}
          name={name}
          type={isShowPassword || isUndefined(type) ? 'text' : type}
          inputMode={isEqual(type, 'number') ? 'numeric' : 'text'}
          autoComplete='off'
          className={clsx(
            `form-control form-control-solid h-40px min-h-40px mh-40px mb-3 mb-lg-0 ${
              inputClassName ?? ''
            } pe-16 border-gray-300 fs-7 fw-bold text-gray-600 `,
            {
              'is-invalid': meta.touched && meta.error,
            },
            {
              'is-valid': meta.touched && !meta.error,
            },
            {
              'bg-white': !disabled,
              'bg-gray-300': disabled,
            }
          )}
          style={{backgroundColor: disabled ? '#f3f4f6' : '#fff'}}
          maxLength={maxLength}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        {!disabled && valueWatch && (
          <button
            className='position-absolute btn p-0  translate-middle'
            style={{
              right: !meta.touched ? 0 : isEqual(type, 'password') ? 50 : 20,
              top: 19,
            }}
            onClick={handleClear}
          >
            <i className='ki-duotone ki-cross-circle fs-3'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </button>
        )}
        {isEqual(type, 'password') && !disabled && valueWatch && (
          <button
            className='position-absolute btn p-0 translate-middle'
            style={{right: !meta.touched ? 32 : 25, top: 20}}
            onClick={handleShowPassword}
          >
            {isShowPassword ? (
              <span className='ki-duotone ki-eye-slash fs-2'>
                <span className='path1' />
                <span className='path2' />
                <span className='path3' />
                <span className='path4' />
              </span>
            ) : (
              <span className='ki-duotone ki-eye fs-2'>
                <span className='path1' />
                <span className='path2' />
                <span className='path3' />
              </span>
            )}
          </button>
        )}
      </div>
      {(meta.touched || skipTouched) && meta.error && (
        <div className='mt-2'>
          <span role='alert' className='text-danger'>
            {meta.error}
          </span>
        </div>
      )}
    </div>
  )
}

export {FormInput}
