import {FC, useMemo} from 'react'
import {ID} from 'src/_biha/helpers'
import {useAppDispatch, useAppSelector} from 'src/app/redux/hook'
import {setCampaignState} from 'src/app/redux/slice/campaginSlice'
import {useListView} from '../../../core/ListViewProvider'
import {useQueryResponseData} from '../../../core/QueryResponseProvider'

type Props = {
  id: ID
}

const CustomerSelectionCell: FC<Props> = ({id}) => {
  const {selected, onSelect} = useListView()
  const campaigns = useQueryResponseData()

  const isSelected = useMemo(() => selected.includes(id), [id, selected])
  const dispatch = useAppDispatch()
  const {selectedCampaigns} = useAppSelector((state) => state.campaign)

  return (
    <div className='form-check form-check-custom form-check-solid'>
      <input
        className='form-check-input cursor-pointer'
        type='checkbox'
        data-kt-check={isSelected}
        data-kt-check-target='#kt_table_customers .form-check-input'
        checked={isSelected}
        onChange={() => {
          onSelect(id)
          const exitsted = selected.includes(id)
          if (exitsted) {
            dispatch(
              setCampaignState({
                selectedCampaigns: selectedCampaigns.filter((item) => item?._id !== id),
              })
            )
          } else {
            const added = campaigns.find((_campaign) => _campaign._id === id)
            if (added) {
              dispatch(
                setCampaignState({
                  selectedCampaigns: [...selectedCampaigns, added],
                })
              )
            }
          }
        }}
      />
    </div>
  )
}

export {CustomerSelectionCell}
