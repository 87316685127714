import {AxiosError} from 'axios'
import {Dispatch, SetStateAction} from 'react'

export type ID = undefined | null | number | string

export type PaginationState = {
  page?: number | undefined
  items_per_page?: number | undefined
  perPage?: number | undefined
  links?: Array<{label: string; active: boolean; url: string | null; page: number | null}>
}

export interface PaginationState2 {
  page?: number | undefined
  currentPage?: number | undefined
  perPage?: number | undefined
  totalPage?: number | undefined
  totalData?: number | undefined
  links?: {
    active: boolean
    disabled: boolean
    label: string | undefined
    page: number | undefined
  }[]
}

export interface LoadMoreState extends Omit<PaginationState, 'perPage'> {
  until?: string | undefined
}
export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}
export type CampaignFilterState = {
  type?: string
  status?: string
  campaign_name?: string
  caller_id?: string
  local_start_time?: string
  local_end_time?: string
  created_at?: string
  campaign_uuid?: string
  lead_count?: string
  active?: string
  extensions?: string
  userType?: string
  career?: string
  finance?: string
  tags?: string
  customerResources?: string
  transactionArising?: string
  createdBy?: string
  updatedBy?: string
  citizenIdentification?: string
  passport?: string
  email?: string
  fullName?: string
  code?: string
  phone?: string
  tagDetails?: string
  province?: string
  district?: string
  ward?: string
  street?: string
  updatedAt?: string
  createdAt?: string
  dateOfBirth?: string
  carrier_uuid?: string
}
export type SwitchBoardFilterState = {
  time_started?: string
  status?: string
  created_by?: string
  customer_fullname?: string
  to_number?: string
  recording_url?: string
  billsec?: string
  note?: string
  view?: string
  app?: string
  first_name?: string
  is_create_extension?: boolean
}
export type ConfigFilterState = {
  composition?: string
  created_at?: string
}
export type ExtensionFilterState = {
  user_uuid?: string
  extension?: string
  enabled?: string
}
export type SearchState = {
  search?: string
}
export type CarrierFilterState = {
  carrier_name?: string
  caller_id?: string
  dial_type?: string
  concurrent_call?: string
}
export type TemplateFilterState = {
  composition?: string
  template_name?: string
}
export type ScriptFilterState = {
  script_name?: string
}
export interface Response<T> {
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
  totalData?: number
}
export interface Response<T> {
  data?: T
  message?: string
  statusCode?: number
  currentPage?: number
  totalPage?: number
  perPage?: number
  totalData?: number
}

export interface ResponseGraphQL<T> {
  data?: T
  errors: AxiosError[]
}

export type QueryState = PaginationState &
  SortState &
  FilterState &
  SearchState &
  LoadMoreState &
  CampaignFilterState &
  ConfigFilterState &
  ExtensionFilterState &
  SwitchBoardFilterState &
  CarrierFilterState &
  TemplateFilterState &
  ScriptFilterState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  items_per_page: 10,
  perPage: 10,
  links: [],
}

export const initialQueryState2: QueryState = {
  page: 1,
  perPage: 10,
}

export const initialCampaignQueryState: QueryState = {
  ...initialQueryState,
  type: undefined,
  status: undefined,
  campaign_name: undefined,
  caller_id: undefined,
  created_at: undefined,
  created_by: undefined,
  user_uuid: undefined,
  script_name: undefined,
  template_name: undefined,
  composition: undefined,
  active: undefined,
  extensions: undefined,
  lead_count: undefined,
  userType: undefined,
  career: undefined,
  finance: undefined,
  tags: undefined,
  customerResources: undefined,
  transactionArising: undefined,
  createdBy: undefined,
  updatedBy: undefined,
  citizenIdentification: undefined,
  passport: undefined,
  email: undefined,
  fullName: undefined,
  code: undefined,
  phone: undefined,
  tagDetails: undefined,
  province: undefined,
  district: undefined,
  ward: undefined,
  street: undefined,
  updatedAt: undefined,
  createdAt: undefined,
  dateOfBirth: undefined,
  extension: undefined,
  enabled: undefined,
  carrier_uuid: undefined,
}
export const initialSwitchBoardQueryState: QueryState = {
  ...initialQueryState,
  time_started: '',
  status: '',
  created_by: '',
  customer_fullname: '',
  to_number: '',
  recording_url: '',
  billsec: '',
  note: '',
  view: '',
  app: '',
  first_name: '',
}
export const initialConfigQueryState: QueryState = {
  ...initialQueryState,
  type: '',
  status: '',
  created_at: '',
}
export const initialCarrierQueryState: QueryState = {
  ...initialQueryState,
  carrier_name: '',
  caller_id: '',
  created_at: '',
  created_by: '',
  concurrent_call: '',
  dial_type: '',
}
export const initialTemplateQueryState: QueryState = {
  ...initialQueryState,
  template_name: '',
  composition: '',
  created_at: '',
}
export const initialScriptQueryState: QueryState = {
  ...initialQueryState,
  script_name: '',
}
export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState2,
  updateState: () => {},
}

export const initialQueryRequest2: QueryRequestContextProps = {
  state: initialQueryState2,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export interface PaginationQueryResponseContextProps<T> {
  response?: T
  refetch: () => void
  isLoading: boolean
  query?: string
}

export interface LoadMoreQueryResponseContextProps<T>
  extends PaginationQueryResponseContextProps<T> {
  setResponse?: (response?: T) => void
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}

export type ListViewContextProps = {
  hoveredIndex: number
  selected: Array<ID>
  setSelected?: (data: ID[]) => void
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  clearAll?: () => void
  setSelect?: (data: ID[]) => void
  onHover: (hoveredIndex: number) => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  setSelected: () => {},
  onHover: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  clearAll: () => {},
  setSelect: () => {},
  setItemIdForUpdate: () => {},
  isAllSelected: false,
  disabled: false,
  hoveredIndex: -9999,
}
