import {Button} from 'react-bootstrap'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {ReactComponent as Information} from 'src/_biha/assets/svg/infomation-icon.svg'
import {ReactComponent as Question} from 'src/_biha/assets/svg/question.svg'
import {ReactComponent as Success} from 'src/_biha/assets/svg/shield-tick.svg'
import {ID, QUERIES} from 'src/_biha/helpers'
import {CloseButton} from 'src/app/shared/components/Modal/ModalConfirm/ModalHeader'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {removeExtensions} from '../../core/_requests'
import {ModalHeader} from './ModalHeader'

interface ModalConfirmProps {
  onClose: () => void
  id?: ID[]
}
const ModalConfirm = ({onClose, id}: ModalConfirmProps) => {
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()

  const removeExtenstion = useMutation(() => removeExtensions(id!), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.EXTENSION_LIST}-${query}`])
      toast.success(
        ({closeToast}) => (
          <div className='text-black'>
            <div className='fw-bold'>Thành công!</div>
            <div className='text-muted'>Xoá máy nhánh thành công</div>
          </div>
        ),
        {icon: () => <Success />, closeButton: CloseButton}
      )
      onClose()
    },
    onError: () => {
      toast.error(
        ({closeToast}) => (
          <div>
            <div className='fw-bold'>Thất bại!</div>
            <div>Xoá máy nhánh thất bại</div>
          </div>
        ),
        {closeButton: (props) => <CloseButton {...props} />}
      )
      onClose()
    },
  })

  return (
    <>
      <div className='modal fade show d-block' role='dialog' tabIndex={-1} aria-modal='true'>
        <div className='modal-dialog modal-dialog-centered mw-25'>
          <div style={{padding: '24px'}} className='modal-content'>
            <ModalHeader title='' onClose={onClose} />
            <div className='modal-body d-flex flex-column align-items-center justify-content-center gap-10'>
              <Information />
              <div className='text-center'>
                <p className='text-muted'>Bạn có chắc muốn xoá máy nhánh này?</p>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                gap: 10,
              }}
            >
              <Button className='flex-fill' variant='secondary' onClick={onClose}>
                Huỷ
              </Button>
              <Button
                className='flex-fill'
                variant='primary'
                onClick={async () => await removeExtenstion.mutateAsync()}
              >
                Xác nhận
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default ModalConfirm
