import {Boolean, String} from 'src/app2/modules/_models'

export enum Module {
  REALESTATE = 'REALESTATE',
  PROJECT = 'PROJECT',
  CRM = 'CRM',
  CALLCENTER = 'CALLCENTER',
  B2B_PUBLIC_REALESTATE = 'B2B_PUBLIC_REALESTATE',
  FORECLOSURE_REQUEST = 'FORECLOSURE_REQUEST',
  UPGRADE_ACCOUNT = 'UPGRADE_ACCOUNT',
  LOAN = 'LOAN',
  NOTARIZATION = 'NOTARIZATION',
  REGISTER = 'REGISTER',
  PROJECT_FOR_LEASE = 'PROJECT_FOR_LEASE',
  ORGANIZATION = 'ORGANIZATION',
  EMPLOYEE = 'EMPLOYEE',
  MANAGE_MEMBERSHIP = 'MANAGE_MEMBERSHIP',
}

export interface AuthModel {
  api_token: string
  refreshToken?: string
}

export interface UserAddressModel {
  addressLine: string
  city: string
  state: string
  postCode: string
}

export interface UserCommunicationModel {
  email: boolean
  sms: boolean
  phone: boolean
}

export interface UserEmailSettingsModel {
  emailNotification?: boolean
  sendCopyToPersonalEmail?: boolean
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean
    youAreSentADirectMessage?: boolean
    someoneAddsYouAsAsAConnection?: boolean
    uponNewOrder?: boolean
    newMembershipApproval?: boolean
    memberRegistration?: boolean
  }
  updatesFromKeenthemes?: {
    newsAboutKeenthemesProductsAndFeatureUpdates?: boolean
    tipsOnGettingMoreOutOfKeen?: boolean
    thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean
    tipsOnStartBusinessProducts?: boolean
  }
}

export interface UserSocialNetworksModel {
  linkedIn: string
  facebook: string
  twitter: string
  instagram: string
}
export interface TableType {
  columns?: ColumnType[]
  tableId?: String
}
export interface ColumnType {
  checked?: Boolean
  id?: String
  label: String
}

export interface UserModel {
  id: number
  username: string
  password: string | undefined
  email: string
  first_name: string
  last_name: string
  fullname?: string
  occupation?: string
  companyName?: string
  phone?: string
  roles?: Array<number>
  pic?: string
  language?: 'en' | 'de' | 'es' | 'fr' | 'ja' | 'zh' | 'ru'
  timeZone?: string
  website?: 'https://keenthemes.com'
  emailSettings?: UserEmailSettingsModel
  auth?: AuthModel
  communication?: UserCommunicationModel
  address?: UserAddressModel
  socialNetworks?: UserSocialNetworksModel
  modules: Module[]
  avatar?: AnotherServiceFileType
  fullName?: string
  tableSettings?: TableType[]
}

export interface FileWithFullUrls {
  createdAt?: string
  creator?: string
  fileId?: string
  originalUrl?: string
  type?: string
  updatedAt?: string
  urls?: Url[]
}

export interface Url {
  type?: string
  url?: string
}

export type AnotherServiceFileType = YoutubeFile | FileWithFullUrls

export interface YoutubeFile {
  fileId?: string
  type?: string
  youtubeMetadata?: YoutubeMetadata
}

export interface YoutubeMetadata {
  description?: string
  thumbnailUrl?: string
  title?: string
  videoId?: string
}

export interface B2bGetMeData {
  data: {
    b2bGetMe?: MutationOfB2BUserType
  }
}

export interface MutationOfB2BUserType {
  payload?: UserModel
  msg?: String
  success?: Boolean
}
//for login
export interface AccessTokenType {
  accessToken?: String
  refreshToken?: String
}
