/* eslint-disable react-hooks/exhaustive-deps */
import {isEmpty, isUndefined, map, omitBy, replace} from 'lodash'
import qs from 'qs'
import {FC, useContext} from 'react'
import {useQuery} from 'react-query'
import {useLocation} from 'react-router-dom'
import {
  PaginationState,
  QUERIES,
  WithChildren,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
} from 'src/_biha/helpers'
import {Campaign} from './_models'
import {getCampaign} from './_requests'

const QueryResponseContext = createResponseContext<Campaign>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {search} = useLocation()
  const searchObject = qs.parse(replace(search, '?', ''))
  const convertedSearchObject = map(
    omitBy(
      {
        ...searchObject,
      },
      (value) => isUndefined(value) || isEmpty(value)
    ),
    (value, key) => `${encodeURIComponent(key)}=${encodeURIComponent(value as any)}`
  ).join('&')

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.CAMPAIGNS_LIST}-${search}`,
    () => {
      return getCampaign(convertedSearchObject)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider
      value={{isLoading: isFetching, refetch, response, query: search}}
    >
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()

  if (!response) {
    return []
  }

  return response?.data || []
}

//Re-format pagination from API
const reFormatPagination = (data: any) => {
  if (data?.totalPage) {
    let pagination: any = {
      page: data.currentPage,
      first_page_url: '/?page=1',
      from: (data.currentPage - 1) * data.perPage ? (data.currentPage - 1) * data.perPage + 1 : 1,
      last_page: data.totalPage,
      links: [
        {
          url: `/?page=${data.currentPage - 1}`,
          label: '&laquo; Previous',
          active: data?.currentPage === 1 ? false : true,
          page: data?.currentPage === 1 ? null : data.currentPage - 1,
        },
      ],
      next_page_url: `/?page=${data.currentPage + 1}`,
      items_per_page: data.perPage > data.totalData ? data.totalData : data.perPage ?? 10,
      perPage: data.perPage ?? 10,
      prev_page_url: null,
      to: data.currentPage * data.perPage ?? 10,
      total: data.totalData,
    }
    for (let i = 1; i <= data.totalPage; i++) {
      let linkItem = {
        url: `/?page=${i}`,
        label: i,
        active: i === data.currentPage ? true : false,
        page: i,
      }
      pagination.links.push(linkItem)
    }
    pagination.links.push({
      url: `/?page=${data.currentPage + 1}`,
      label: 'Next &raquo;',
      active: false,
      page: data.currentPage === data.totalPage ? null : data.currentPage + 1,
    })
    return pagination
  }
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }
  const {response} = useQueryResponse()
  if (!response) {
    return defaultPaginationState
  } else {
    return reFormatPagination(response)
  }
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
}
