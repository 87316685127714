/* eslint-disable jsx-a11y/anchor-is-valid */
import {isArray, isEmpty, isEqual, join, map, without} from 'lodash'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {Tooltip} from 'src/app2/modules/apps/components/tooltip/Tooltip'
import {Number, String} from 'src/app2/modules/_models'

interface Props {
  className?: String
  translateNode?: String
  translateSource?: String
  value?: String | String[] | Number
}

const TextCell: FC<Props> = ({className, translateNode, translateSource, value}) => {
  const intl = useIntl()
  const texts = isArray(value) ? value : [value]
  const withoutTexts = without(texts, '', null, undefined)
  const translatedTexts = map(withoutTexts, (text) =>
    isEqual(typeof text, 'string')
      ? translateNode
        ? intl.formatMessage({id: `${translateSource}.${translateNode}.${text}`})
        : text
      : text
  )

  return (
    <div className={clsx('d-flex align-items-center overflow-hidden', className)}>
      <div className='d-flex flex-column overflow-hidden'>
        {isEmpty(translatedTexts) ? (
          <span>-</span>
        ) : (
          <Tooltip
            content={
              <div className='m-n1 p-3 d-flex flex-wrap'>
                <span className='text-nowrap text-truncate'>{join(translatedTexts, ', ')}</span>
              </div>
            }
          >
            <div className='d-flex'>
              <span className='text-nowrap text-truncate lh-lg'>{join(translatedTexts, ', ')}</span>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export {TextCell}
