import Cookie from 'js-cookie'
import {AccessTokenType} from './_models'
const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const AUTH_TEL4_LOCAL_STORAGE_KEY = 'tel4Token'
const USER_UUID_KEY = 'user_uuid'
const AUTH_COOKIES_KEY = 'authentication'
const getAuth = (): AccessTokenType | undefined => {
  const cookiesValue = Cookie.get(AUTH_COOKIES_KEY)
  if (!cookiesValue) {
    return
  }
  try {
    const auth = JSON.parse(cookiesValue)
    if (auth) {
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AccessTokenType) => {
  try {
    const cookiesValue = JSON.stringify(auth)
    Cookie.set(AUTH_COOKIES_KEY, cookiesValue, {
      path: '/',
      domain: process.env.REACT_APP_BASE_DOMAIN,
    })
  } catch (error) {
    console.error('AUTH COOKIES SAVE ERROR', error)
  }
}

const removeAuth = () => {
  try {
    Cookie.remove(AUTH_COOKIES_KEY, {
      path: '/',
      domain: process.env.REACT_APP_BASE_DOMAIN,
    })
    Cookie.remove(AUTH_COOKIES_KEY)
  } catch (error) {
    console.error('AUTH COOKIES REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      const {accessToken} = auth ?? {}
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {
  getAuth,
  setAuth,
  removeAuth,
  AUTH_LOCAL_STORAGE_KEY,
  AUTH_TEL4_LOCAL_STORAGE_KEY,
  USER_UUID_KEY,
  AUTH_COOKIES_KEY,
}
