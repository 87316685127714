import {Extension} from 'src/app/modules/apps/extensions/core/_models'
import {createSlice} from '@reduxjs/toolkit'
// import { LoginForm, LoginResponse, RegisterDataRequest, RegisterUser, RequestError } from "../../types/auth";
import {PayloadAction} from '@reduxjs/toolkit'
// import {User} from "../../types/user";

export interface GlobalState {
  campaignActiveTabKey: string
  loading: boolean

  configsActiveTabKey: string
  isShowModal: boolean
  menuActiveKey: string
  selectedExtension?: Extension
}
const initialState: GlobalState = {
  loading: false,
  campaignActiveTabKey: 'auto-call',

  configsActiveTabKey: 'prefix-list',
  isShowModal: false,
  menuActiveKey: '',
}
export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setGlobalState(state, action: PayloadAction<Partial<GlobalState>>) {
      Object.assign(state, action.payload)
    },
    reset(state: any) {
      Object.keys(state).forEach((stateKey) => {
        Object.keys(initialState).forEach((initStateKey) => {
          if (stateKey === initStateKey) {
            state[stateKey] = {...initialState}[initStateKey]
          }
        })
      })
    },
  },
})

export const {actions: globalAction, reducer: globalReducer} = globalSlice
export const {setGlobalState, reset} = globalSlice.actions
