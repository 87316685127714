import axios, {AxiosResponse} from 'axios'
import {ID} from 'src/_biha/helpers'
import request from 'src/app/shared/config/request'
import {CustomersQueryResponse} from '../../campaign/campaign-list/campaign-create/core/_models'
import {CUSTOMER_URL} from '../../campaign/campaign-list/campaign-create/core/_requests'
import {CreateExtensionRequest, Extension, ExtensionQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_GUGO_API
const EXTENSION_URL = `${API_URL}/extension`

const getExtension = (query: string): Promise<ExtensionQueryResponse> => {
  return request('get', `${EXTENSION_URL}?${query}`).then((d) => d)
}

const createExtension = (data: CreateExtensionRequest): Promise<Extension | undefined> => {
  return axios
    .post(EXTENSION_URL, data)
    .then((response: AxiosResponse<Extension>) => response.data)
    .then((response: Extension) => response)
}
const updateExtension = (
  data: CreateExtensionRequest,
  id: string
): Promise<Extension | undefined> => {
  return axios
    .put(EXTENSION_URL +`/${id}`, data)
    .then((response: AxiosResponse<Extension>) => response.data)
    .then((response: Extension) => response)
}

const removeExtensions = (ids: Array<ID>): Promise<void> => {
  const requests = ids.map((id) => axios.delete(`${EXTENSION_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const getCustomers = (query: string): Promise<CustomersQueryResponse> => {
  return request('get', `${CUSTOMER_URL}?${query}`).then((d) => {
    return d
  })
}
export {getExtension, removeExtensions, createExtension, getCustomers, updateExtension}
