import {isEqual, isUndefined, map, pick, reduce, toString} from 'lodash'
import {
  EmailCreationForm,
  ParameterTabEnums,
} from '../modules/apps/modules/email/email-creation/EmailCreation.form'
import moment from 'moment'
import {
  CampaignV2CreateDto,
  LeadTypeEnums,
} from '../modules/apps/modules/email/email-creation/core/_model'
import {String} from '../modules/_models'
import {EmailEditionForm} from '../modules/apps/modules/email/email-edition/EmailEdition.form'

const reFormatAdditionalInfo = (values: EmailCreationForm) => {
  switch (true) {
    case isEqual(values.parameter_tab, ParameterTabEnums.excel_file):
      return reduce(
        values.params,
        (acc: any, {key}) => {
          acc[`{{${key}}}`] = key
          return acc
        },
        {}
      )
    default:
      return reduce(
        values.params,
        (acc: any, {key, value}) => {
          acc[`{{${key}}}`] = value?.trim()?.replace(/\s+/g, ' ')
          return acc
        },
        {}
      )
  }
}
const reFormatLead = (values: EmailCreationForm) => {
  const params = map(values.params, 'value')
  switch (true) {
    case isEqual(values.parameter_tab, ParameterTabEnums.crm_list):
      return map(values.customers, (customer) => {
        return {
          ...pick(customer, params as string[]),
          customer_id: customer?._id,
          full_name: customer?.fullName,
          phone_number: customer?.phone?.[0],
          address: customer?.address,
          email: customer?.email,
        }
      })
    case isEqual(values.parameter_tab, ParameterTabEnums.member_social_list):
      return map(values.memberSocials, (member) => {
        return {
          ...pick(member, params as string[]),
          customer_id: member?.id,
          full_name: member?.fullName,
          phone_number: member?.phone ?? member?.phones?.[0],
          address: member?.province?.name,
          email: member?.email,
        }
      })
    default:
      return values.excelData
  }
}

const reFormatDataCampaignInput = (values: EmailCreationForm, type: String) => {
  const campaignInput: CampaignV2CreateDto = {
    campaign_name: toString(values.campaign_name),
    time_start: toString(moment(values.sent_time).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')),
    additional_info: reFormatAdditionalInfo(values),
    lead: reFormatLead(values),
    brandname_uuid: values.brand?._id,
    templateV2_uuid: values.template?._id,
    type: type,
    description: values.description,
    lead_type: isEqual(values.parameter_tab, ParameterTabEnums.crm_list)
      ? LeadTypeEnums.crm
      : isEqual(values.parameter_tab, ParameterTabEnums.member_social_list)
      ? LeadTypeEnums.member
      : LeadTypeEnums.excel,
  }
  return campaignInput ?? {}
}
//for edit
const reFormatDataLead = (values: EmailEditionForm, leadV2Ids: String[]) => {
  switch (values.parameter_tab) {
    case ParameterTabEnums.crm_list:
      const newCustomerIds = map(values.customers, '_id')
      return isEqual(newCustomerIds, leadV2Ids)
        ? undefined
        : reFormatLead(values as EmailCreationForm)
    case ParameterTabEnums.member_social_list:
      const newMemberIds = map(values.memberSocials, '_id')
      return isEqual(newMemberIds, leadV2Ids)
        ? undefined
        : reFormatLead(values as EmailCreationForm)
    case ParameterTabEnums.excel_file:
      return isUndefined(values.excelData) ? undefined : values.excelData
  }
}
const reFormatEditDataCampaignInput = (
  values: EmailEditionForm,
  leadV2Ids: String[],
  type: String
) => {
  const campaignInput: CampaignV2CreateDto = {
    campaign_name: toString(values.campaign_name),
    time_start: toString(moment(values.sent_time).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')),
    additional_info: reFormatAdditionalInfo(values as EmailCreationForm),
    lead: reFormatDataLead(values, leadV2Ids),
    brandname_uuid: values.brand,
    templateV2_uuid: values.template,
    type: type,
    description: values.description,
    lead_type: isEqual(values.parameter_tab, ParameterTabEnums.crm_list)
      ? LeadTypeEnums.crm
      : isEqual(values.parameter_tab, ParameterTabEnums.member_social_list)
      ? LeadTypeEnums.member
      : LeadTypeEnums.excel,
  }
  return campaignInput ?? {}
}
export {reFormatDataCampaignInput, reFormatEditDataCampaignInput}
