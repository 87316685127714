import {KTSVG} from 'src/_biha/helpers'
interface ModalHeaderProps {
  onClose: () => void
  title: string
}
const ModalHeader = ({onClose, title}: ModalHeaderProps) => {
  return (
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <h2 className='fw-bolder w-100'>{title}</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-customers-modal-action='close'
        onClick={onClose}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {ModalHeader}
