// @ts-nocheck
import {toNumber} from 'lodash'
import {Column} from 'react-table'
import {AvatarUser} from 'src/app/shared/components/AvatarUser/AvatarUser'
import {customerResourcesToText, userTypeToText} from 'src/app/shared/utils/enumToText'
import {convertToVietnameseCurrencyShort} from 'src/app2/utils'
import {CampaignCustomHeader} from '../../../../auto-dial/components/table/columns/CampaignCustomHeader'
import {TagListCell} from '../../../../auto-dial/components/table/columns/TagListCell'
import {TextCell} from '../../../../auto-dial/components/table/columns/TextCell'
import {Customer} from '../../core/_models'
import {CustomerSelectionCell} from './CustomerSelectionCell'
import {CustomerSelectionHeader} from './CustomerSelectionHeader'
import {DateTimeCell} from './DateTimeCell'

// const customersColumns: ReadonlyArray<Column<Customer>> = [
//   {
//     Header: (props) => <CustomerSelectionHeader tableProps={props} />,
//     id: 'selection',
//     Cell: ({...props}) => <CustomerSelectionCell id={props.data[props.row.index].id} />,
//   },
//   {
//     Header: (props) => (
//       <CustomerCustomHeader tableProps={props} title='Name' className='min-w-125px' />
//     ),
//     id: 'name',
//     Cell: ({...props}) => <CustomerInfoCell customer={props.data[props.row.index]} />,
//   },
//   {
//     Header: (props) => (
//       <CustomerCustomHeader tableProps={props} title='Role' className='min-w-125px' />
//     ),
//     accessor: 'role',
//   },
//   {
//     Header: (props) => (
//       <CustomerCustomHeader tableProps={props} title='Last login' className='min-w-125px' />
//     ),
//     id: 'last_login',
//     Cell: ({...props}) => (
//       <CustomerLastLoginCell last_login={props.data[props.row.index].last_login} />
//     ),
//   },
//   {
//     Header: (props) => (
//       <CustomerCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
//     ),
//     id: 'two_steps',
//     Cell: ({...props}) => (
//       <CustomerTwoStepsCell two_steps={props.data[props.row.index].two_steps} />
//     ),
//   },
//   {
//     Header: (props) => (
//       <CustomerCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
//     ),
//     accessor: 'joined_day',
//   },
//   {
//     Header: (props) => (
//       <CustomerCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
//     ),
//     id: 'actions',
//     Cell: ({...props}) => <CustomerActionsCell id={props.data[props.row.index].id} />,
//   },
// ]
const customersColumns: ReadonlyArray<Column<Customer>> = [
  {
    Header: (props) => <CustomerSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => (
      <CustomerSelectionCell
        id={props.data[props.row.index]._id}
        isCheck={props.data[props.row.index].isCheck}
      />
    ),
  },
  {
    Header: (props) => <CampaignCustomHeader title='STT' tableProps={props} />,
    id: 'index',
    Cell: ({...props}) => <div>{props.row.index + 1}</div>,
  },
  {
    Header: 'Mã khách hàng',
    id: 'code',
    filter: 'Mã khách hàng',
    Cell: ({...props}) => <div>{props.data[props.row.index]?.code ?? '-'}</div>,
  },
  {
    Header: 'Tên khách hàng',
    id: 'fullName',
    filter: 'Tên khách hàng',
    Cell: ({...props}) => (
      <AvatarUser
        imageUrl={props.data[props.row.index].avatar?.originalUrl}
        fullName={
          props.data[props.row.index].userType === 'ENTERPRISE'
            ? props.data[props.row.index].businessName
            : props.data[props.row.index].fullName
        }
        width='fit-content'
      />
    ),
  },
  {
    Header: 'Loại người dùng',
    id: 'userType',
    filter: 'Loại người dùng',
    Cell: ({...props}) => (
      <TextCell value={userTypeToText(props.data[props.row.index]?.userType)} />
    ),
  },
  {
    Header: 'Số điện thoại',
    id: 'phone',
    filter: 'Số điện thoại',
    Cell: ({...props}) => (
      <div>
        {props.data[props.row.index]?.phone
          ? props.data[props.row.index]?.phone[0]
          : props.data[props.row.index]?.phone_number}
      </div>
    ),
  },
  {
    Header: 'Mã căn hộ',
    id: 'apartmentCode',
    filter: 'Mã căn hộ',
    Cell: ({...props}) => (
      <TextCell
        className='min-w-120px'
        value={
          props.data[props.row.index].apartmentCode
            ? props.data[props.row.index].apartmentCode
            : '-'
        }
      />
    ),
  },
  {
    Header: 'Ngành nghề',
    id: 'career',
    filter: 'Ngành nghề',
    Cell: ({...props}) => (
      <TextCell
        translateSource='CUSTOMER'
        translateNode='CAREER'
        value={props.data[props.row.index].career}
        className='mw-156px'
      />
    ),
  },
  {
    id: 'tagDetails',
    filter: 'Thẻ chi tiết nghành nghề',
    Header: 'Thẻ chi tiết ngành nghề',
    Cell: ({...props}) => (
      <TagListCell tags={props.data[props.row.index]?.tagDetails} className='mw-216px' />
    ),
  },
  {
    Header: 'TÀI CHÍNH (VNĐ)',
    id: 'finance',
    filter: 'Tài chính',
    Cell: ({...props}) => (
      <TextCell
        value={convertToVietnameseCurrencyShort(toNumber(props.data[props.row.index]?.finance))}
      />
    ),
  },
  {
    id: 'tags',
    Header: 'Thẻ tiềm năng',
    filter: 'Thẻ tiềm năng',
    Cell: ({...props}) => (
      <TagListCell tags={props.data[props.row.index].tags} className='mw-216px' />
    ),
  },
  {
    Header: 'Nguồn khách hàng',
    id: 'customerResources',
    filter: 'Nguồn khách hàng',
    Cell: ({...props}) => (
      <TextCell
        value={customerResourcesToText(props.data[props.row.index].customerResources ?? '-')}
      />
    ),
  },
  {
    Header: 'Phát sinh giao dịch',
    id: 'transactionArising',
    filter: 'Phát sinh giao dịch',
    Cell: ({...props}) => {
      if (props.data[props.row.index].transactionArising) {
        return <div className='badge badge-light-success'>Đã phát sinh</div>
      }
      return <div className='badge badge-light'>Chưa phát sinh</div>
    },
  },
  {
    Header: 'Số nhà',
    id: 'apartmentNumber',
    filter: 'Số nhà',
    Cell: ({...props}) => (
      <TextCell
        className='min-w-50px'
        value={
          props.data[props.row.index].apartmentNumber
            ? props.data[props.row.index].apartmentNumber
            : '-'
        }
      />
    ),
  },
  {
    Header: 'Đường',
    id: 'street',
    filter: 'Đường',
    Cell: ({...props}) => (
      <TextCell
        value={
          props.data[props.row.index].street?.name ? props.data[props.row.index].street.name : '-'
        }
      />
    ),
  },
  {
    Header: 'Phường/Xã',
    id: 'ward',
    filter: 'Phường/Xã',
    Cell: ({...props}) => (
      <TextCell
        value={
          props.data[props.row.index].ward?.name
            ? props.data[props.row.index].ward.name
            : props.data[props.row.index].ward ?? '-'
        }
      />
    ),
  },
  {
    Header: 'Quận/ huyện',
    id: 'district',
    filter: 'Quận/ Huyện',
    Cell: ({...props}) => (
      <TextCell
        value={
          props.data[props.row.index].district?.name
            ? props.data[props.row.index].district.name
            : props.data[props.row.index].district ?? '-'
        }
      />
    ),
  },
  {
    Header: 'Tỉnh/ thành phố',
    id: 'province',
    filter: 'Tỉnh/ Thành phố',
    Cell: ({...props}) => (
      <TextCell
        value={
          props.data[props.row.index].province?.name
            ? props.data[props.row.index].province.name
            : props.data[props.row.index].province ?? '-'
        }
      />
    ),
  },
  {
    Header: 'Quốc gia',
    id: 'country',
    filter: 'Quốc gia',
    Cell: ({...props}) => (
      <TextCell
        value={
          props.data[props.row.index].country?.name
            ? props.data[props.row.index].country.name
            : props.data[props.row.index].country ?? '-'
        }
      />
    ),
  },
  {
    Header: 'Ngày sinh',
    id: 'dateOfBirth',
    filter: 'Ngày ngày sinh',
    Cell: ({...props}) =>
      props.data[props.row.index].dateOfBirth ? (
        <DateTimeCell value={props.data[props.row.index].dateOfBirth} format='DD/MM/YYYY' />
      ) : (
        '-'
      ),
  },
  {
    Header: 'SỐ CMND/CCCD',
    id: 'citizenIdentification',
    filter: 'Số CMND/CCCD',
    Cell: ({...props}) => (
      <TextCell
        value={
          props.data[props.row.index].citizenIdentification
            ? props.data[props.row.index].citizenIdentification
            : props.data[props.row.index].identity_number ?? '-'
        }
      />
    ),
  },
  {
    Header: 'SỐ PASSPORT',
    id: 'passport',
    filter: 'Số passport',
    Cell: ({...props}) => (
      <TextCell
        value={props.data[props.row.index].passport ? props.data[props.row.index].passport : '-'}
      />
    ),
  },
  {
    Header: 'Email',
    id: 'email',
    filter: 'email',
    Cell: ({...props}) => <TextCell value={props.data[props.row.index].email ?? '-'} />,
  },
  {
    Header: 'Người tạo',
    id: 'createdBy',
    filter: 'Người tạo',
    Cell: ({...props}) => (
      <AvatarUser
        imageUrl={props.data[props.row.index].createdBy?.avatar}
        fullName={props.data[props.row.index].createdBy?.fullName}
        width='fit-content'
      />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader tableProps={props} title='Ngày tạo' className='min-w-250px' />
    ),
    filter: 'Ngày tạo',
    id: 'createdAt',
    Cell: ({...props}) =>
      props.data[props.row.index].createdAt ? (
        <DateTimeCell value={props.data[props.row.index].createdAt} format='HH:mm:ss, DD/MM/YYYY' />
      ) : (
        '-'
      ),
  },
  {
    Header: 'Người chỉnh sửa',
    id: 'updatedBy',
    filter: 'Người chỉnh sửa',
    Cell: ({...props}) =>
      props.data[props.row.index].updatedBy?.fullName ? (
        <AvatarUser
          imageUrl={props.data[props.row.index].updatedBy?.avatar}
          fullName={props.data[props.row.index].updatedBy?.fullName}
          width='fit-content'
        />
      ) : (
        '-'
      ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader tableProps={props} title='Ngày chỉnh sửa' className='min-w-250px' />
    ),
    filter: 'Ngày chỉnh sửa',
    id: 'updatedAt',
    Cell: ({...props}) =>
      props.data[props.row.index].updatedBy?.fullName && props.data[props.row.index].updatedAt ? (
        <DateTimeCell value={props.data[props.row.index].updatedAt} format='HH:mm:ss, DD/MM/YYYY' />
      ) : (
        '-'
      ),
  },
  // {
  //   Header: (props) => (
  //     <CampaignCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <CustomerActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {customersColumns}
