import {KTSVG} from 'src/_biha/helpers'
import {HorizontalMenu} from './HorizontalMenu'

export function MenuInner() {
  return (
    <div className='app-navbar flex-shrink-0'>
      <div
        className='app-navbar-item cursor-pointer symbol h-full'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <div className='me-3 text-white fw-bold'>
          <KTSVG path='/media/biha/icons/modules/total-icon.svg' className='svg-icon-2x' />
        </div>
      </div>
      <HorizontalMenu />
      <div className='d-flex align-items-center text-white fw-bold'>
        <div className='menu-item me-lg-1 user-select-none'>
          <div className='menu-label fs-4 py-3'>
            <span className='menu-title'>Tổng đài thông minh</span>
          </div>
        </div>
      </div>
    </div>
  )
}
