import {String} from 'src/app2/modules/_models'
import * as Yup from 'yup'
import {BrandNameV2, TemplateV2} from './core/_model'
import {Customer} from './fill-information-form/customer-list/core/_models'
import {MemberOutputType} from './fill-information-form/member-social-list/core/_models'

export interface Template_Option {
  label?: String
  value?: TemplateV2
}
export interface Email_Option {
  label?: String
  value?: BrandNameV2
}
export enum CustomerTabEnums {
  customer_crm = 'customer_crm',
  customer_crm_added = 'customer_crm_added',
}
export enum MemberSocialTabEnums {
  member_social = 'member_social',
  member_social_added = 'member_social_added',
}
export enum ParameterTabEnums {
  crm_list = 'crm_list',
  member_social_list = 'member_social_list',
  excel_file = 'excel_file',
}

export const ExcelEntityRequired = ['full_name', 'phone_number', 'address', 'brand']
export interface EmailCreationForm {
  campaign_name?: String
  description?: String
  sent_time?: String
  brand?: BrandNameV2
  template?: TemplateV2
  customer_tab?: CustomerTabEnums
  member_social_tab?: MemberSocialTabEnums
  parameter_tab?: ParameterTabEnums
  params?: {
    key?: String
    value?: String
  }[]
  customers?: Customer[]
  memberSocials?: MemberOutputType[]
  customerIds?: String[]
  excelFile?: File
  excelData?: any
  //step2
  isConfirmation?: String
}

export const EmailCreationDefaultValues: EmailCreationForm = {
  campaign_name: '',
  description: '',
  sent_time: '',
  brand: undefined,
  template: undefined,
  params: [],
  customer_tab: CustomerTabEnums.customer_crm,
  member_social_tab: MemberSocialTabEnums.member_social,
  parameter_tab: undefined,
  customers: undefined,
  customerIds: [],
  memberSocials: undefined,
  excelFile: undefined,
  excelData: [],
  isConfirmation: '',
}

export const EmailValidationSchema = Yup.object().shape({
  campaign_name: Yup.string()
    .required('Vui lòng nhập tên chiến dịch')
    .max(168, 'Tên chiến dịch không được vượt quá 168 ký tự'),
  sent_time: Yup.string().required('Vui lòng nhập thời gian gửi'),
  template: Yup.object().required('Vui lòng chọn Email template'),
  parameter_tab: Yup.string(),
  params: Yup.array().when('parameter_tab', {
    is: 'excel_file',
    then: Yup.array().notRequired(),
    otherwise: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().required('Key is required'),
        value: Yup.string()
          .required('Vui lòng nhập thông số tin gửi')
          .max(168, 'Giá trị thông số không được vượt quá 168 ký tự'),
      })
    ),
  }),
})
