import {FC, createContext, useContext} from 'react'
import {WithChildren} from '../../../../_biha/helpers'
import {QueryResponseProvider, useQueryResponseData} from './core/QueryResponseProvider'
import {ConfigGetSerialization} from './core/_models'

interface CustomerConfigContextProps {
  filterConfigurations?: ConfigGetSerialization
}
const ConfigurationContext = createContext<CustomerConfigContextProps>({})

const CustomerConfigWrapper: FC<WithChildren> = ({children}) => {
  const filterConfigurations = useQueryResponseData()
  return (
    <ConfigurationContext.Provider value={{filterConfigurations}}>
      {children}
    </ConfigurationContext.Provider>
  )
}

const CustomerConfigProvider: FC<WithChildren> = ({children}) => {
  return (
    <QueryResponseProvider>
      <CustomerConfigWrapper>{children}</CustomerConfigWrapper>
    </QueryResponseProvider>
  )
}

const useCustomerConfig = () => useContext(ConfigurationContext)

export {CustomerConfigProvider, useCustomerConfig}
