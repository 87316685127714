import {useEffect, useMemo} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'

import {KTCardBody} from 'src/_biha/helpers'
import {ListLoading} from 'src/app/shared/components/loading/ListLoading'
import {useQueryResponseData, useQueryResponseLoading} from '../../core/QueryResponseProvider'
import {Extension} from '../../core/_models'
import {ExtensionListPagination} from '../pagination/ExtensionListPagination'
import {extensionColumns} from './columns/_columns'
import {isEmpty} from 'lodash'
import {ExtensionDeletedRowActions} from './ExtensionDeletedRowAction'
import {useListView} from '../../core/ListViewProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useLocation} from 'react-router-dom'
import {parseQueryString} from 'src/app/shared/utils'

const ExtensionTable = () => {
  const {selected, hoveredIndex, onHover} = useListView()
  const extensions = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const data = useMemo(() => extensions, [extensions])
  const columns = useMemo(() => extensionColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const {search} = useLocation()

  useEffect(() => {
    updateState({perPage: 10, page: 1})
  }, [])

  const handleMouseEnterRow = (index: number) => {
    onHover(index)
  }

  useEffect(() => {
    const params = parseQueryString(search)
    const dataParams: any = {
      ...params,
    }

    updateState(dataParams)
  }, [search])

  return (
    <KTCardBody className='p-6 position-relative'>
      <div className='table-responsive'>
        <table
          id='kt_table_customers'
          className='table align-middle table-row-dashed fs-6 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Extension>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Extension>, i) => {
                prepareRow(row)
                return (
                  <CustomRow
                    row={row}
                    key={`row-${i}-${row.id}`}
                    onMouseEnterRow={handleMouseEnterRow}
                  />
                )
              })
            ) : (
              <tr>
                <td colSpan={headers.length}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Không tìm thấy kết quả
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isEmpty(selected) && rows.length ? (
        <ExtensionDeletedRowActions rows={rows} index={hoveredIndex} />
      ) : (
        <></>
      )}
      <ExtensionListPagination />
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export {ExtensionTable}
