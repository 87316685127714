import clsx from 'clsx'
import {FC, ReactNode} from 'react'
import {useDispatch} from 'react-redux'
import {Link, useLocation} from 'react-router-dom'
import {setGlobalState} from 'src/app/redux/slice/globalSlice'
import {KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  itemKey: string
  customIcon?: ReactNode
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  itemKey,
  customIcon,
  hasBullet = false,
}) => {
  const dispatch = useDispatch()
  const {pathname} = useLocation()
  const isActive = pathname.includes(itemKey)
  const {config} = useLayout()
  const {app} = config

  return (
    <div className='menu-item'>
      <Link
        onClick={() => dispatch(setGlobalState({menuActiveKey: itemKey}))}
        className={clsx('menu-link without-sub', {active: isActive})}
        to={to}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {customIcon && <span className='menu-icon me-2'>{customIcon}</span>}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('ki-duotone fs-3 me-2', fontIcon)}>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        )}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
