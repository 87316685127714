import {isNil} from 'lodash'
import {useState, useEffect} from 'react'
import {ButtonProps} from 'react-bootstrap'
import {ReactComponent as HangUp} from 'src/_biha/assets/svg/hangup-icon.svg'
import {ReactComponent as MuteMic} from 'src/_biha/assets/svg/mic.svg'
import {ReactComponent as Phone} from 'src/_biha/assets/svg/phone.svg'
import {ReactComponent as Remove} from 'src/_biha/assets/svg/remove-icon.svg'
import {formatTimeAudio} from 'src/app/shared/utils'
import request from '../../../shared/config/request'
import './index.scss'
import {w3cwebsocket as WebSocket} from 'websocket'

interface NumberButtonProps extends ButtonProps {
  number?: number
  text: string
}
const NumberButton = ({number, text, ...props}: NumberButtonProps) => {
  return !isNil(number) ? (
    <button {...props}>
      <div>{number}</div>
      <div className='number-button--text'>{text}</div>
    </button>
  ) : (
    <div className='number-button--hide'></div>
  )
}

const CallButton = () => {
  const [showDialer, setShowDialer] = useState(false)
  const [status, setStatus] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [intervalValue, setIntervalValue] = useState(0)
  let token = localStorage.getItem('tel4Token')
  const socket = new WebSocket(
    `wss://api-callcenter.gugotech.com/v3/websocket/agent/subscribe?token=${token}`
  )
  const handleClickToCall = async (inputValue: string) => {
    setIntervalValue(-1)

    let token = localStorage.getItem('tel4Token')
    let extension = localStorage.getItem('extension')
    let data = {ext: Number(extension), phone: inputValue}
    let res = await request('get', 'https://api-callcenter.gugotech.com/v1/click2call', data, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    if (res) {
      socket.onopen = () => {
        console.log('Kết nối WebSocket thành công')
      }
      socket.onmessage = (event: any) => {
        if (event?.data) {
          let data = JSON.parse(event?.data)
          if (data) {
            let state = data?.event
            switch (state) {
              case 'ringing':
                setIntervalValue(-1)

                setStatus('connecting')
                break
              case 'answered':
                setInterval(() => {
                  if (res.status && res.status.toString() === 'success') {
                    setIntervalValue((prev) => prev + 1)
                  } else {
                    setIntervalValue(0)
                  }
                }, 1000)
                break
              case 'hangup':
                setIntervalValue(-1)
                socket.close()

                setStatus('')
                break
            }
          }
        }
      }
    }
  }
  const numberButtonArray = [
    {
      number: 1,
      text: '',
    },
    {
      number: 2,
      text: 'abc',
    },
    {
      number: 3,
      text: 'def',
    },
    {
      number: 4,
      text: 'ghi',
    },
    {
      number: 5,
      text: 'jkl',
    },
    {
      number: 6,
      text: 'mno',
    },
    {
      number: 7,
      text: 'pqrs',
    },
    {
      number: 8,
      text: 'tuv',
    },
    {
      number: 9,
      text: 'wxyz',
    },
    {
      text: '',
    },
    {
      number: 0,
      text: '',
    },
  ]

  useEffect(() => {
    return () => {
      socket.close()
    }
  }, [])

  function handleKeyDownInput(event: any) {
    let element = document.getElementById('inputValue')
    if (element) {
      const {key} = event
      if (!key.match(/[^0-9]/)) {
        if (element?.innerText.length < 16) {
          if (element?.innerText.length === 4 || element?.innerText.length === 8) {
            setInputValue((prev) => {
              return prev + ' ' + key
            })
          } else {
            setInputValue((prev) => {
              return prev + key
            })
          }
        }
      } else {
        if (key === 'Backspace') {
          setInputValue((prev) => {
            return prev.substring(0, prev.length - 1)
          })
        }
        if (key === 'Enter') {
          if (element?.innerText.length > 0) {
            let newInputValue = element?.innerText.replaceAll(' ', '')
            handleClickToCall(newInputValue)
          }
        }
      }
    }
  }

  useEffect(() => {
    if (showDialer) {
      document.addEventListener('keydown', handleKeyDownInput)
    } else {
      document.removeEventListener('keydown', handleKeyDownInput)
      setStatus('')
      setInputValue('')
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDownInput)
      setStatus('')
      setInputValue('')
    }
  }, [showDialer])

  return (
    <>
      <button
        onClick={() => {
          setShowDialer((prev) => !prev)
        }}
        className='call-button'
      >
        <Phone />
      </button>
      {showDialer && (
        <div className='dialer'>
          <div className='dialer--info'>
            {status && <div className='dialer--info__avatar'></div>}
            <div className='fw-bold fs-1'>{status && 'Unknown'}</div>
            <div>
              {status === 'connecting'
                ? intervalValue > 0
                  ? formatTimeAudio(intervalValue)
                  : 'Đang kết nối...'
                : status}
            </div>
          </div>
          <div className='fw-bold fs-2' id={'inputValue'}>
            {inputValue}
          </div>
          <div className='number-button-wrapper'>
            {numberButtonArray.map((numberButton) => (
              <NumberButton
                onClick={() => {
                  setInputValue((prev) => {
                    if (prev.length < 16) {
                      if (prev.length === 4 || prev.length === 8) {
                        return prev + ' ' + numberButton.number
                      } else {
                        return prev + numberButton.number
                      }
                    } else {
                      return prev
                    }
                  })
                }}
                {...numberButton}
                className='number-button'
              />
            ))}
            <button
              onClick={() => {
                setInputValue((prev) => prev?.slice(0, prev?.length - 1))
              }}
              className='remove-button'
            >
              <Remove />
            </button>
          </div>
          {!status ? (
            <div className='d-flex flex-column gap-5 align-items-center'>
              <button
                className='dialer--button'
                onClick={() => {
                  if (inputValue.length > 0) {
                    let newInputValue = inputValue.replaceAll(' ', '')
                    handleClickToCall(newInputValue)
                  }
                }}
              >
                <Phone />
              </button>
              <div>Gọi</div>
            </div>
          ) : (
            <>
              <div className='d-flex justify-content-evenly w-100'>
                <div className='d-flex flex-column gap-5 align-items-center'>
                  <button className='dialer--button__mute'>
                    <MuteMic />
                  </button>
                  <div>Tắt mic</div>
                </div>
                <div className='d-flex flex-column gap-5 align-items-center'>
                  <button
                    onClick={() => {
                      setIntervalValue(0)
                      setStatus('')
                      socket.close()
                    }}
                    className='dialer--button__stop'
                  >
                    <HangUp />
                  </button>
                  <div>Dừng cuộc gọi</div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default CallButton
