const QUERIES = {
  CUSTOMERS_LIST: 'customers-list',
  CAMPAIGNS_LIST: 'campaigns-list',
  CAMPAIGN_HISTORY_LIST: 'campaign-history-list',
  CAMPAIGNS: 'campaign',
  HISTORY_LIST: 'history-list',
  CARRIER_LIST: 'carrier-list',
  CALLSCRIPT_LIST: 'callscript-list',
  EXTENSION_LIST: 'extension-list',
  LEAD_LIST: 'lead-list',
  COMMENT: 'comment',
  CONFIG: 'config',
  USER_CONFIG: 'user-config',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
  PROVINCE_LIST: 'province-list',
  CALL_CENTER: 'call-center',
  SWITCH_BOARD: 'switchboard',
}

const BANK_QUERIES = {
  BANK_LIST: 'bank-list',
}
const ZNS_SEND_HISTORY_QUERIES = {
  INDEX: 'zns-send-history',
  NOTE: 'note',
  OVERVIEW: 'overview',
}
const SMS_SEND_HISTORY_QUERIES = {
  INDEX: 'sms-send-history',
  NOTE: 'note',
  OVERVIEW: 'overview',
}

const USER_CONFIG_QUERIES = {
  USER_CONFIG: 'user-config',
}

const ZNS_QUERIES = {
  ZNS_LIST: 'zns-list',
  ZNS_DETAIL: 'zns-detail',
  ZNS_CREATE: 'zns-create',
  CRM_LIST: 'crm-list',
  BAND_NAME_V2: 'brand-name-v2',
  BRAND_NAME_DETAIL: 'brand-name-detail',
  TEMPLATE_V2: 'template-v2',
  LEAD_V2: 'lead-v2',
  LEAD_V2_DETAIL: 'lead-v2-detail',
}
const EMAIL_QUERIES = {
  BAND_NAME_V2: 'brand-name-v2',
  TEMPLATE_V2: 'template-v2',
  EMAIL_LIST: 'email-list',
  EMAIL_DETAIL: 'email-detail',
  LEAD_V2: 'lead-v2',
  LEAD_V2_DETAIL: 'lead-v2-detail',
}
const SMS_QUERIES = {
  BAND_NAME_V2: 'brand-name-v2',
  TEMPLATE_V2: 'template-v2',
  LEAD_V2: 'lead-v2',
  SMS_LIST: 'sms-list',
  SMS_DETAIL: 'sms-detail',
}
const MEMBER_MANAGEMENT_QUERIES = {
  MEMBER_LIST: 'member-list',
  CREATED_BY_LIST: 'created-by-list',
  PROCESSED_BY_LIST: 'processed-by-list',
  APPROVED_BY_LIST: 'approved-by-list',
  REJECTED_BY_LIST: 'rejected-by-list',
  SOCIAL_DEVELOPER_LIST: 'social-developer-list',
  MEMBER_ROLE_LIST: 'member-role-list',
}
const CONFIGURATION_QUERIES = {
  EMAIL_TEMPLATE: 'email-template',
  BRAND_NAME: 'brand-name',
}
const SUB_PATH = {
  CALL_CENTER: 'call-center',
  CAMPAIGN_LIST: 'campaign-list',
  SLUG_ID: ':id',
  ZNS: {
    INDEX: 'zns',
    CREATE: 'create-campaign-zns',
    EDIT: 'edit-campaign-zns',
    OVER_VIEW: 'overview',
    HISTORY: 'history',
  },
  SMS: {
    INDEX: 'sms',
    CREATE: 'create-campaign-sms',
    EDIT: 'edit-campaign-sms',
    OVER_VIEW: 'overview',
    HISTORY: 'history',
  },
  EMAIL: {
    INDEX: 'email',
    CREATE: 'create-campaign-email',
    EDIT: 'edit-campaign-email',
    OVER_VIEW: 'overview',
    HISTORY: 'history',
  },
}

export {
  QUERIES,
  BANK_QUERIES,
  ZNS_QUERIES,
  USER_CONFIG_QUERIES,
  MEMBER_MANAGEMENT_QUERIES,
  EMAIL_QUERIES,
  SUB_PATH,
  SMS_QUERIES,
  CONFIGURATION_QUERIES,
  ZNS_SEND_HISTORY_QUERIES,
  SMS_SEND_HISTORY_QUERIES,
}
