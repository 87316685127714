import {Module} from 'src/app/modules/auth'
import dayjs from 'dayjs'
import {filter, includes} from 'lodash'
import queryString from 'query-string'
import {RouterProps} from 'src/_biha/layout/components/header/header-menus/routers'
export const formatDateTime = (value: string | Date, format?: string) => {
  return dayjs(value).format(format ?? 'HH:mm, DD/MM/YYYY')
}
export function Last7Days() {
  var result = []
  for (var i = 0; i < 7; i++) {
    var d = new Date()
    d.setDate(d.getDate() - i)
    result.push(formatDateTime(d, 'DD/MM'))
  }

  return result
}
export function removeAccent(str: string) {
  str = str.toLowerCase()
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  return str
}
export const buildFormData = (formData: FormData, obj: any, parentKey = '') => {
  if (Array.isArray(obj)) {
    obj.forEach((element) => {
      buildFormData(formData, element, parentKey)
    })
  } else if (typeof obj === 'object' && !(obj instanceof File)) {
    Object.keys(obj).forEach((key) => {
      buildFormData(formData, obj[key], parentKey ? `${parentKey}.${key}` : key)
    })
  } else {
    if (obj == null) {
      return
    }
    const value = typeof obj === 'number' || typeof obj === 'boolean' ? obj.toString() : obj
    console.log(parentKey, value)
    formData.append(parentKey, value)
  }
  return formData
}
export function formatTimeAudio(seconds: number) {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor(seconds % 60)
  return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s].filter((a) => a).join(':')
}
export function formatTimeAudioShowZero(seconds: number) {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor(seconds % 60)
  return [h > 9 ? h : '0' + h, m > 9 ? m : '0' + m || '0', s > 9 ? s : '0' + s].join(':')
}
export const parseQueryString = (queryURI: string) => {
  return JSON.parse(JSON.stringify(queryString.parse(queryURI)))
}

export const hideDropdown = () => {
  const element = document.querySelector<HTMLElement>('.show.dropdown')
  if (element) {
    element.click()
  }

  const elementUp = document.querySelector<HTMLElement>('.show.dropup')
  if (elementUp) {
    elementUp.click()
  }
}
export const formatMoney = (value: number, affix?: string) => {
  if (!+value) return 0 + ` ${affix ?? 'VND'}`

  const number = `${Math.round(value)}`
  const x = number.split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? `.${x[1]}` : ''
  const rgx = /(\d+)(\d{3})/

  while (rgx.test(x1)) {
    // eslint-disable-next-line no-useless-concat
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }

  return x1 + x2 + ` ${affix ?? 'VND'}`
}
export const filterRoutersAppByModule = (routesApp: RouterProps[], modules?: Module[]) => {
  return filter(
    routesApp,
    (route) => (route.module && includes(modules, route.module)) || !route.module
  )
}
