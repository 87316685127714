import request from 'src/app/shared/config/request'

const API_URL = process.env.REACT_APP_GUGO_API
const REPORT_URL = `${API_URL}/report`
const EMPLOYEE_REPORT_URL = `/employee`
const EXTENSION_REPORT_URL = `/extension`
const SUMMARY_REPORT_URL = `/summary`

const getEmployeeReport = (query: string): Promise<any> => {
  return request('get', `${REPORT_URL}${EMPLOYEE_REPORT_URL}?${query}`, undefined, {
    responseType: 'blob',
  }).then((d) => d)
}
const getExtensionReport = (query: string): Promise<any> => {
  return request('get', `${REPORT_URL}${EXTENSION_REPORT_URL}?${query}`, undefined, {
    responseType: 'blob',
  }).then((d) => d)
}
const getExtensionSummaryReport = (query: string): Promise<any> => {
  return request(
    'get',
    `${REPORT_URL}${EXTENSION_REPORT_URL}${SUMMARY_REPORT_URL}?${query}`,
    undefined,
    {
      responseType: 'blob',
    }
  ).then((d) => d)
}
const getExtensionSummaryReportByDay = (query: string): Promise<any> => {
  return request(
    'get',
    `${REPORT_URL}${EXTENSION_REPORT_URL}${SUMMARY_REPORT_URL}/day?${query}`,
    undefined,
    {
      responseType: 'blob',
    }
  ).then((d) => d)
}
const getAgentExtensionSummaryReportByTime = (query: string): Promise<any> => {
  return request(
    'get',
    `${REPORT_URL}/agent/time?${query}`,
    undefined,
    {
      responseType: 'blob',
    }
  ).then((d) => d)
}

export {
  getEmployeeReport,
  getExtensionReport,
  getExtensionSummaryReport,
  getExtensionSummaryReportByDay,
  getAgentExtensionSummaryReportByTime,
}
