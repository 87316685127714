import { FC, useMemo } from 'react'
import { ID } from 'src/_biha/helpers'
import { useListView } from '../../../core/ListViewProvider'
import { useAppSelector } from 'src/app/redux/hook'

type Props = {
  id: ID
  isCheck: boolean
}

const CustomerSelectionCell: FC<Props> = ({ id, isCheck }) => {
  const { selected, onSelect } = useListView()
  const { listCustomerSelect } = useAppSelector((state) => state.campaign)
  const isSelected = useMemo(() => selected.includes(id), [selected, id])
  const isEditSelect = useMemo(() => listCustomerSelect.includes(id), [listCustomerSelect, id])

  return (
    <div className='form-check form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        data-kt-check={isSelected || isEditSelect}
        disabled={isCheck}
        data-kt-check-target='#kt_table_customers .form-check-input'
        checked={isSelected || isEditSelect}
        onChange={() => onSelect(id)}
      />
    </div>
  )
}

export { CustomerSelectionCell }
