import clsx from 'clsx'
import {cloneDeep, includes, isEqual, map, toNumber} from 'lodash'
import React, {KeyboardEvent, ReactNode, useEffect, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import {ToggleComponent} from 'src/_biha/assets/ts/components'
import {reInitMenu} from 'src/_biha/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'

const mappedLabel = (label: string): ReactNode => {
  if (label === '&laquo; Previous') {
    return <i className='ki-duotone ki-left'></i>
  }

  if (label === 'Next &raquo;') {
    return <i className='ki-duotone ki-right'></i>
  }

  return label
}
const pageSizeOptions = [
  {label: 10, value: 10},
  {label: 20, value: 20},
  {label: 50, value: 50},
  {label: 100, value: 100},
]

export const SPECIAL_KEYS = [
  'Tab',
  'ArrowLeft',
  'ArrowRight',
  'ArrowTop',
  'ArrowBottom',
  'Delete',
  'Backspace',
  'Enter',
]

const AutoDialListPagination = () => {
  const [paginationTerm, setPaginationTerm] = useState<string>('')
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({page, items_per_page: pagination.items_per_page || 10})
    window.scrollTo({top: 0, behavior: 'smooth'})
  }
  const updatePageSize = (perPage: number | null) => {
    if (!perPage || isLoading || pagination.perPage === perPage) {
      return
    }
    window.scrollTo({top: 0, behavior: 'smooth'})
    updateState({page: 0, perPage})
  }

  useEffect(() => {
    setTimeout(() => {
      reInitMenu()

      ToggleComponent.bootstrap()
    }, 500)
  })

  const handleKeydownInput = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {key, code, metaKey} = event
    if (!metaKey && !includes(SPECIAL_KEYS, code) && key.match(/[^0-9]/)) {
      event.preventDefault()
    }
    if (isEqual(event.key, 'Enter')) {
      const newPage = toNumber(paginationTerm)
      setPaginationTerm('')
      if (newPage && pagination.links.length > 0 && newPage <= pagination.links.length - 2) {
        updatePage(newPage)
      }
    }
  }

  const CustomToggle = React.forwardRef(({children, onClick}: any, ref: any) => (
    <button
      className='btn btn-outline h-40px w-100 d-flex justify-content-between align-items-center'
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <span>{children}</span>
      <span className='svg-icon fs-3 rotate-180 ms-3 me-0'>
        <i className='ki-duotone ki-down'></i>
      </span>
    </button>
  ))

  const renderPaginationUI = (link: any, index: number) => {
    if (link.label !== 'input') {
      return (
        <li
          key={index}
          className={clsx('page-item', {
            active: pagination.page === link.page,
            disabled: isLoading,
            previous: link.label === 'Previous',
            next: link.label === 'Next',
          })}
        >
          <button
            className={clsx('page-link', {
              'page-text': link.label === 'Previous' || link.label === 'Next',
              'me-5': link.label === 'Previous',
            })}
            onClick={() => updatePage(link.page)}
            style={{cursor: 'pointer'}}
          >
            {mappedLabel(link.label)}
          </button>
        </li>
      )
    } else {
      return (
        <li
          className={clsx('page-item', {
            active: pagination.page === link.page,
            disabled: isLoading,
            previous: link.label === 'Previous',
            next: link.label === 'Next',
          })}
        >
          <input
            key={link.label}
            type='text'
            disabled={isLoading || link.disabled}
            data-kt-table-filter='pagination'
            className='page-item form-control rounded-2 fs-8 fw-bold h-32px text-center w-90px input-page-number'
            placeholder='Nhập số trang'
            value={paginationTerm}
            onChange={(e) => setPaginationTerm(e.target.value)}
            onKeyDown={handleKeydownInput}
          />
        </li>
      )
    }
  }

  const renderPagination = (pagination: any) => {
    // Trường hợp bé hơn 4 trang
    let newPagination = cloneDeep(pagination)
    if (newPagination.links.length > 5) {
      newPagination.links.splice(pagination.links.length - 2, 0, {
        active: false,
        label: 'input',
      })
    }
    //   //Trường hợp đang ở trang 1,2,3
    if (pagination.page <= 3) {
      return newPagination.links
        ?.map((link: any) => {
          return {...link, label: mappedLabel(link.label)}
        })
        .map((link: any, index: number) => {
          if (link.label !== 'input') {
            if (index < 4) {
              return renderPaginationUI(link, index)
            } else if (index >= pagination.links.length - 2) {
              return renderPaginationUI(link, index)
            }
          } else {
            return renderPaginationUI(link, index)
          }
        })
    } else {
      // Trường hợp page cuối
      if (pagination.page === pagination.links.length - 2) {
        return newPagination.links
          ?.map((link: any) => {
            return {...link, label: mappedLabel(link.label)}
          })
          .map((link: any, index: number) => {
            if (link.label !== 'input') {
              if (index <= 3) {
                return renderPaginationUI(link, index)
              } 
              // else if (index === 4) {
              //   return (
              //     <li
              //       key={index}
              //       className={clsx('page-item', {
              //         active: pagination.page === link.page,
              //         disabled: isLoading,
              //         previous: link.label === 'Previous',
              //         next: link.label === 'Next',
              //       })}
              //     >
              //       <button
              //         className={clsx('page-link', {
              //           'page-text': link.label === 'Previous' || link.label === 'Next',
              //           'me-5': link.label === 'Previous',
              //         })}
              //       >
              //         ...
              //       </button>
              //     </li>
              //   )
              // } 
              else if (pagination.page === parseInt(link.label)) {
                return renderPaginationUI(link, index)
              } else if (index >= pagination.links.length - 2) {
                return renderPaginationUI(link, index)
              }
            } else {
              return renderPaginationUI(link, index)
            }
          })
      } else {
        return newPagination.links
          ?.map((link: any) => {
            return {...link, label: mappedLabel(link.label)}
          })
          .map((link: any, index: number) => {
            if (link.label !== 'input') {
              if (index <= 1) {
                return renderPaginationUI(link, index)
              } else if (index === 2) {
                return (
                  <li
                    key={index}
                    className={clsx('page-item', {
                      active: pagination.page === link.page,
                      disabled: isLoading,
                      previous: link.label === 'Previous',
                      next: link.label === 'Next',
                    })}
                  >
                    <button
                      className={clsx('page-link', {
                        'page-text': link.label === 'Previous' || link.label === 'Next',
                        'me-5': link.label === 'Previous',
                      })}
                    >
                      ...
                    </button>
                  </li>
                )
              } else if (pagination.page === parseInt(link.label)) {
                return renderPaginationUI(link, index)
              } else if (index >= pagination.links.length - 2) {
                return renderPaginationUI(link, index)
              }
            } else {
              return renderPaginationUI(link, index)
            }
          })
      }
    }
  }

  return (
    <div className='row mt-8'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start gap-7'>
        <div>
          <Dropdown drop='up' className='w-100' align={{lg: 'end'}}>
            <Dropdown.Toggle as={CustomToggle}>{pagination?.perPage}</Dropdown.Toggle>
            <Dropdown.Menu>
              <div className='d-flex flex-column overflow-auto'>
                {map(pageSizeOptions, (option) => (
                  <Dropdown.Item
                    onClick={() => {
                      updatePageSize(option?.value)
                    }}
                    active={option.value === pagination?.perPage}
                    key={option.value}
                    eventKey={option.value}
                    className={`py-4 border-0 fw-normal fs-4 ${
                      option?.value === pagination?.perPage
                        ? 'badge badge-light-primary text-primary'
                        : 'badge badge-white bg-white text-muted '
                    } `}
                  >
                    {option?.label}
                  </Dropdown.Item>
                ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className='text-muted'>
          Hiển thị {pagination.from} - {pagination.to} trên {pagination.total}
        </div>
      </div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_customers_paginate'>
          <ul className='pagination'>{renderPagination(pagination)}</ul>
        </div>
      </div>
    </div>
  )
}

export {AutoDialListPagination}
