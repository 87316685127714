/* eslint-disable react-hooks/exhaustive-deps */
import {B2BUserTel4vnType} from '../../../modules/apps/campaign/campaign-list/auto-call/core/_models'
import {b2bGetListOfUsersBasicTel4vnInfo} from '../../../modules/apps/campaign/campaign-list/auto-call/core/_requests'
import {FileWithFullUrls} from 'src/app/modules/auth'
import {FormikProvider, useField, useFormik, useFormikContext} from 'formik'
import {compact, first, gt, includes, isArray, isEmpty, map, size, uniqBy, upperCase} from 'lodash'
import {ChangeEvent, FC, useEffect, useState} from 'react'
import {Modal, Spinner} from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import {useQuery} from 'react-query'
import {toAbsoluteUrl, useDebounce} from 'src/_biha/helpers'
import {String} from 'src/app2/modules/_models'

interface Props {
  label?: String
  name: String
  queryName?: String
}

const UserFilterByUuid: FC<Props> = ({label, name, queryName}) => {
  const [privateSearch, setPrivateSearch] = useState<String>('')
  const [page, setPage] = useState<number>(1)
  const [isShowingModal, toggleShowingModal] = useState<boolean>(false)
  const [privateOptions, setPrivateOptions] = useState<B2BUserTel4vnType[] | undefined>()
  const outerForm = useFormikContext()
  const [field, , helpers] = useField(name)
  const {value: outerFormValue} = field
  const {setValue: setOuterFormValue} = helpers
  const searchChange = useDebounce(privateSearch, 500)
  const {data, refetch} = useQuery(
    [queryName],
    async () => {
      const res = await b2bGetListOfUsersBasicTel4vnInfo({
        page,
        limit: 10,
        fullName: searchChange,
      })
      setPrivateOptions((previousOptions) => [
        ...(previousOptions ?? []),
        ...(res.data?.b2bGetListOfUsersBasicTel4vnInfo?.items ?? []),
      ])
      return res.data
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  )
  const {hasNextPage} = data?.b2bGetListOfUsersBasicTel4vnInfo ?? {}
  const innerForm = useFormik<any>({
    initialValues: {[name]: undefined},
    onSubmit: (values) => {
      const {[name]: fieldName} = values
      setOuterFormValue(includes(fieldName, 'all') ? [] : fieldName)
      outerForm.submitForm()
    },
  })
  const {[name]: innerUsers} = innerForm.values
  const isSelectedAllInnerForm = includes(innerUsers, 'all')
  const searchedFieldCount = size(
    isArray(outerFormValue) ? outerFormValue : compact([outerFormValue])
  )

  const handleOnToggleModal = () => {
    toggleShowingModal(!isShowingModal)
    innerForm.setFieldValue(name, outerFormValue)
    setPrivateSearch('')
  }
  const handleSubmitForm = () => {
    innerForm.submitForm()
    toggleShowingModal(!isShowingModal)
  }
  const handleSelectAll = () => {
    innerForm.setFieldValue(name, isSelectedAllInnerForm ? [] : ['all'])
  }
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setPrivateSearch(event.target.value)
  }
  const handleClear = () => {
    setPrivateSearch('')
    setPrivateOptions(privateOptions)
  }
  const handleFetchMore = () => {
    if (hasNextPage) {
      setPage((prevPage) => prevPage + 1)
      refetch()
    }
  }

  useEffect(() => {
    setPrivateOptions([])
    setPage(1)
    refetch()
  }, [searchChange, isShowingModal])

  return (
    <div className='d-inline-flex'>
      <div id={`${name}-filter`}>
        <FormikProvider value={innerForm}>
          <button
            type='button'
            className={`btn btn-sm fw-bold rounded-2 py-0 px-3 h-32px text-nowrap ${
              gt(searchedFieldCount, 0)
                ? 'bg-light-primary bg-hover-light-primary text-primary'
                : 'bg-light bg-hover-light text-gray-600'
            }`}
            data-bs-toggle='modal'
            data-bs-target={`#${name}-filter-modal`}
            onClick={handleOnToggleModal}
          >
            <span>
              {label ?? name} {gt(searchedFieldCount, 0) ? ` +${searchedFieldCount}` : ''}
            </span>
            <i className='ki-duotone ki-down fs-7 ms-1' />
          </button>
          <Modal
            show={isShowingModal}
            onHide={handleOnToggleModal}
            tabIndex={-1}
            dialogClassName='modal-dialog modal-dialog-centered w-500px'
          >
            <div className='modal-header h-60px d-flex justify-content-between align-items-center p-6 position-relative border-bottom'>
              <div className='d-flex justify-content-center align-items-center w-100'>
                <h5 className='modal-title fs-4 ms-7'>{label ?? name}</h5>
              </div>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2 min-w-28px'
                data-bs-dismiss='modal'
                onClick={handleOnToggleModal}
              >
                <i className='ki-duotone ki-cross fs-2x'>
                  <span className='path1' />
                  <span className='path2' />
                </i>
              </div>
            </div>
            <div className='modal-body px-6 py-4'>
              <div className='card-tittle mb-2'>
                <div className='card-title mb-1'>
                  <div className='d-flex align-items-center rounded-2 position-relative'>
                    <i className='ki-duotone ki-magnifier fs-2 position-absolute ms-3'>
                      <span className='path1' />
                      <span className='path2' />
                    </i>
                    <input
                      autoComplete='off'
                      data-kt-table-filter={`${name}-checkbox-search`}
                      className='form-control form-control-outline w-100 ps-10 pe-8 fs-7 fw-bold h-40px py-0'
                      placeholder={'Tìm kiếm'}
                      value={privateSearch ?? ''}
                      onChange={handleSearch}
                    />
                    {privateSearch ? (
                      <button
                        className='position-absolute btn p-0 top-50 end-0 translate-middle'
                        onClick={handleClear}
                      >
                        <i className='ki-duotone ki-cross-circle fs-3'>
                          <span className='path1' />
                          <span className='path2' />
                        </i>
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
              <div
                className='d-flex flex-column'
                id={`${name}-infinite-scroll`}
                style={{
                  maxHeight: '50vh',
                  minHeight: '50vh',
                  overflowY: 'auto',
                }}
              >
                <InfiniteScroll
                  dataLength={size(privateOptions)}
                  hasMore={hasNextPage ?? false}
                  loader={
                    <div className='d-flex justify-content-center'>
                      <Spinner />
                    </div>
                  }
                  next={handleFetchMore}
                  scrollableTarget={`${name}-infinite-scroll`}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 5,
                  }}
                >
                  <div className='d-flex flex-column'>
                    <label className='form-check form-check-custom form-check-solid mb-1 w-100 cursor-pointer px-3 py-2 rounded-2 bg-hover-light-primary user-select-none'>
                      <input
                        id={`${name}-checkbox-all`}
                        name='checkedAll'
                        className='form-check-input'
                        checked={isSelectedAllInnerForm}
                        type='checkbox'
                        value='all'
                        onChange={handleSelectAll}
                      />
                      <span className='form-check-label text-gray-600 fs-7 lh-sm fw-bold text-truncate'>
                        Chọn tất cả
                      </span>
                    </label>
                  </div>
                  <div className='d-flex flex-column'>
                    {map(uniqBy(privateOptions, 'id'), (privateOption, privateOptionIndex) => {
                      return (
                        <div key={privateOptionIndex} className='mb-1 user-select-all'>
                          <label className='form-check form-check-custom form-check-solid w-100 cursor-pointer px-3 py-2 rounded-2 bg-hover-light-primary user-select-none'>
                            <input
                              id={`${name}-checkbox-${privateOptionIndex}`}
                              name={name}
                              className='form-check-input'
                              type='checkbox'
                              checked={includes(
                                innerUsers,
                                privateOption?.provider?.tel4vn?.user_uuid
                              )}
                              disabled={isSelectedAllInnerForm}
                              value={privateOption?.provider?.tel4vn?.user_uuid}
                              onChange={innerForm.handleChange}
                            />
                            <div className='d-flex overflow-hidden align-items-center'>
                              <div className='symbol symbol-circle symbol-32px overflow-hidden me-2 ms-3'>
                                {(privateOption?.avatar as FileWithFullUrls)?.originalUrl ? (
                                  <div className='symbol-label'>
                                    <img
                                      src={toAbsoluteUrl(
                                        isEmpty(
                                          (privateOption?.avatar as FileWithFullUrls)?.originalUrl
                                        )
                                          ? ''
                                          : ((privateOption?.avatar as FileWithFullUrls)
                                              ?.originalUrl as String)
                                      )}
                                      alt={
                                        isEmpty(privateOption?.fullName)
                                          ? 'avatar'
                                          : (privateOption?.fullName as String)
                                      }
                                      className='w-100'
                                    />
                                  </div>
                                ) : (
                                  <div className='symbol-label fs-3 bg-gray-300 text-gray-700'>
                                    {upperCase(first(privateOption?.fullName))}
                                  </div>
                                )}
                              </div>
                              <div className='d-flex flex-column form-check-label'>
                                <span className='text-dark lh-sm fw-bold text-truncate w-200px'>
                                  {privateOption?.fullName}
                                </span>
                              </div>
                            </div>
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </InfiniteScroll>
              </div>
            </div>
            <div className='modal-footer px-4 py-2 flex justify-content-between'>
              <button
                type='button'
                className='btn btn-lg btn-light m-0 py-3 px-4 fs-7 h-40px fw-bold text-gray-800 rounded-2'
                onClick={innerForm.handleReset}
              >
                <i className='ki-duotone ki-arrows-circle fs-4 me-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                <span>Đặt lại</span>
              </button>
              <button
                type='button'
                className='btn btn-lg btn-primary rounded-2 fs-7 h-40px fw-bold m-0 py-3 px-4'
                data-bs-dismiss='modal'
                onClick={handleSubmitForm}
              >
                Xác nhận
              </button>
            </div>
          </Modal>
        </FormikProvider>
      </div>
    </div>
  )
}

export {UserFilterByUuid}
