import {FormikProvider, useFormik} from 'formik'
import {isEqual} from 'lodash'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {ReactComponent as FileDown} from 'src/_biha/assets/svg/file-down.svg'
import {ReactComponent as Success} from 'src/_biha/assets/svg/shield-tick.svg'
import {KTCard} from 'src/_biha/helpers'
import {CloseButton} from 'src/app/shared/components/Modal/ModalConfirm/ModalHeader'
import {ListLoading} from 'src/app/shared/components/loading/ListLoading'
import {SelectOptions} from 'src/app/shared/interfaces'
import {FormDateRangeInput} from 'src/app2/modules/apps/components/form-date-range-input/FormDateRangeInput'
import {FormSelect} from 'src/app2/modules/apps/components/form-select/FormSelect'
import * as Yup from 'yup'
import {PageLink} from '../../../../_biha/layout/core'
import {
  getAgentExtensionSummaryReportByTime,
  getEmployeeReport,
  getExtensionReport,
  getExtensionSummaryReport,
  getExtensionSummaryReportByDay,
} from './core/_request'

const reportSchema = Yup.object().shape({
  type: Yup.string().required('Vui lòng chọn loại báo cáo'),
  report_date: Yup.array()
    .of(Yup.string())
    .required('Vui lòng chọn thời gian')
    .min(1, 'Vui lòng chọn thời gian'),
})
export const customersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Tổng đài thông minh',
    path: '/call-center',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const typeOptions: SelectOptions[] = [
  {
    label: 'Báo cáo theo nhân viên',
    value: '1',
  },
  {
    label: 'Báo cáo theo số máy nhánh',
    value: '2',
  },
  {
    label: 'Báo cáo tổng hợp theo số máy nhánh',
    value: '3',
  },
  {
    label: 'Báo cáo tổng hợp theo số máy nhánh và ngày',
    value: '4',
  },
  {
    label: 'Báo cáo theo thời gian của nhân viên',
    value: '5',
  },
]

const ReportPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const formik = useFormik({
    initialValues: {
      type: '1',
      report_date: '',
    },
    validationSchema: reportSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      setIsLoading(true)

      try {
        const link = document.createElement('a')
        if (isEqual(values.type, '1')) {
          const reportRes = await getEmployeeReport(
            `report_date=${values?.report_date.toString() ?? ''}`
          )

          const url = URL.createObjectURL(new Blob([reportRes], {type: 'application/vnd.ms-excel'}))
          link.href = url
          link.download = 'report-employee.xlsx'
          link.click()
        }
        if (isEqual(values.type, '2')) {
          const reportRes = await getExtensionReport(
            `report_date=${values?.report_date.toString() ?? ''}`
          )

          const url = URL.createObjectURL(new Blob([reportRes], {type: 'application/vnd.ms-excel'}))
          link.href = url
          link.download = 'report-extension.xlsx'
          link.click()
        }
        if (isEqual(values.type, '3')) {
          const reportRes = await getExtensionSummaryReport(
            `report_date=${values?.report_date.toString() ?? ''}`
          )

          const url = URL.createObjectURL(new Blob([reportRes], {type: 'application/vnd.ms-excel'}))
          link.href = url
          link.download = 'report-extension-summary.xlsx'
          link.click()
        }
        if (isEqual(values.type, '4')) {
          const reportRes = await getExtensionSummaryReportByDay(
            `report_date=${values?.report_date.toString() ?? ''}`
          )

          const url = URL.createObjectURL(new Blob([reportRes], {type: 'application/vnd.ms-excel'}))
          link.href = url
          link.download = 'report-extension-summary-by-day.xlsx'
          link.click()
        }
        if (isEqual(values.type, '5')) {
          const reportRes = await getAgentExtensionSummaryReportByTime(
            `report_date=${values?.report_date.toString() ?? ''}`
          )

          const url = URL.createObjectURL(new Blob([reportRes], {type: 'application/vnd.ms-excel'}))
          link.href = url
          link.download = 'agent-report-summary.xlsx'
          link.click()
        }
        toast.success(
          ({closeToast}) => (
            <div className='text-black'>
              <div className='fw-bold'>Thành công!</div>
              <div className='text-muted'>Xuất file excel thành công</div>
            </div>
          ),
          {icon: () => <Success />, closeButton: CloseButton}
        )
        setIsLoading(false)
        // }
      } catch (ex: any) {
        toast.error(`${ex.response.data._error}`)
      } finally {
        setSubmitting(false)
      }
    },
  })
  const typeWatch = formik.values.type
  const quickSelectOptions = [
    {label: '7 ngày trước', startDate: moment().add(-7, 'days'), endDate: moment()},
    {label: '30 ngày trước', startDate: moment().add(-30, 'days'), endDate: moment()},
    {label: '90 ngày trước', startDate: moment().add(-90, 'days'), endDate: moment()},
  ]

  useEffect(() => {
    if (!typeWatch) {
      formik.setFieldValue('type', '')
    }
  }, [typeWatch])

  return (
    <FormikProvider value={formik}>
      <form
        id='kt_modal_add_customer_form'
        className='form'
        onSubmit={formik.handleSubmit}
        style={{position: 'relative'}}
      >
        <KTCard>
          <div className='border-0 p-7 '>
            <div
              style={{
                margin: '0 200px',
              }}
              className='flex flex-col items-center'
            >
              <div className='fs-3 fw-bold'>Lọc loại báo cáo</div>
              <div className='separator my-3 opacity-75' />
              <div className='d-flex flex-column'>
                <label className='required fw-bold fs-6 my-2 w-50'>Loại báo cáo</label>
                <FormSelect
                  placeholder='Chọn loại báo cáo'
                  name='type'
                  options={typeOptions}
                  className='w-100'
                  noOptionsMessage={({inputValue}) =>
                    !inputValue ? 'Không có lựa chọn' : 'Không tìm thấy kết quả'
                  }
                  classNames={{
                    control: () => 'min-h-40px border-gray-300 rounded-2',
                  }}
                />
              </div>
              <div className='d-flex flex-column'>
                <label className='required fw-bold fs-6 my-2 w-50'>Thời gian</label>
                <div className='w-100'>
                  <FormDateRangeInput
                    name='report_date'
                    placeholder='Chọn ngày tạo'
                    numberOfMonths={2}
                    popperPlacement='bottom-end'
                    maxDate={moment()}
                    quickSelectOptions={quickSelectOptions}
                  />
                  {formik.getFieldMeta('report_date').error && (
                    <div className='mt-2'>
                      <span role='alert' className='text-danger'>
                        {formik.getFieldMeta('report_date').error}
                      </span>
                    </div>
                  )}
                </div>
                {/* <InputDate
                  name='report_date'
                  autoClose
                  quickSelectOptions={quickSelectOptions}
                  placeholder='Chọn ngày tạo'
                  numberOfMonths={2}
                  maxDate={moment()}
                /> */}
              </div>
            </div>
          </div>
        </KTCard>
        <div
          style={{
            marginTop: 32,
          }}
          className='d-flex justify-content-end'
        >
          <button type='submit' className='btn btn-lg btn-primary rounded-2 py-0 px-3 h-40px'>
            <FileDown /> &nbsp; Xuất báo cáo File Excel
          </button>
        </div>
        {isLoading && <ListLoading />}
      </form>
    </FormikProvider>
  )
}

export default ReportPage
