import axios, {AxiosResponse} from 'axios'
import {compact, isArray} from 'lodash'
import {ID} from 'src/_biha/helpers'
import request from 'src/app/shared/config/request'
import {
  CallscriptQueryResponse,
  CampaignDetailResponse,
  CampaignInfo,
  CampaignQueryResponse,
  CarrierQueryResponse,
  ConfigFilterQueryResponse,
  CreateCampaignBody,
  CreateCampaignResponse,
  Customer,
  CustomersQueryResponse,
  ILocationRes,
  ListCustomer,
  TagsDetailQueryResponse,
  TemplateQueryResponse,
} from './_models'

const API_URL = process.env.REACT_APP_GUGO_API
const API_CRM_URL = process.env.REACT_APP_CRM_API
const API_USER = process.env.REACT_APP_GUGO_GRAPHQL_API
const API_MAP = process.env.REACT_APP_MAP_API ?? 'https://api-tncc-v1.gugotech.com/map/graphql'
const CAMPAIGN_URL = `${API_URL}/campaign`
const LEAD_URL = `${API_URL}/lead`

export const CARRIER_URL = `${API_URL}/carrier`
export const TEMPLATE_URL = `${API_URL}/template`
export const CALLSCRIPT_URL = `${API_URL}/callscript`
export const CUSTOMER_URL = `${API_CRM_URL}/customers`
export const CONFIG_URL = `${API_CRM_URL}/config`
export const CUSTOMER_EXTENSION_URL = `${API_USER}/users/template`

const getCampaign = (query: string): Promise<CampaignQueryResponse> => {
  return request('get', `${CAMPAIGN_URL}?${query}`).then((d) => {
    return d
  })
}

const getCampaignById = (id: ID): Promise<CampaignDetailResponse | undefined> => {
  return axios
    .get(`${CAMPAIGN_URL}/${id}`)
    .then((response: AxiosResponse<CampaignDetailResponse>) => response.data)
}

function createFileCampaign(campaign: CampaignInfo, customers: ListCustomer, file: File) {
  const formData = new FormData()

  Object.keys(campaign).forEach((item) => {
    if (item === 'extensions') {
      const extenstions = isArray(campaign[item])
        ? (compact(campaign[item]) as string[])
        : compact([campaign[item] as string])
      extenstions?.forEach((extenstion: string, extenstionIndex: number) =>
        formData.append(`campaign[${item}][${extenstionIndex}]`, extenstion)
      )
    } else if (item === 'recall_status' && campaign.recall_status) {
      campaign.recall_status.map((option, index) => {
        formData.append(`campaign[${item}[${index}]]`, option)
      })
    } else {
      formData.append(`campaign[${item}]`, campaign[`${item as keyof typeof campaign}`])
    }
  })
  formData.append(`file`, file)
  // formData.append(
  //   `lead[data][]`,
  //   customers.data && customers.data.length ? customers.data?.toString() : '[]'
  // )

  return (
    axios
      // {headers: {contentType: 'multipart/form-data'}}
      .post(CAMPAIGN_URL, formData, {headers: {contentType: 'multipart/form-data'}})
      .then((response: AxiosResponse<CreateCampaignResponse>) => response.data)
  )
}
function updateFileCampaign(
  campaign: CampaignInfo,
  customers: ListCustomer,
  file: File,
  id: string
) {
  const formData = new FormData()

  Object.keys(campaign).forEach((item) => {
    if (item === 'extensions') {
      formData.append(`campaign[${item}[]]`, campaign[`${item as keyof typeof campaign}`])
    } else if (item === 'recall_status' && campaign.recall_status) {
      campaign.recall_status.map((option, index) => {
        formData.append(`campaign[${item}[${index}]]`, option)
      })
    } else {
      formData.append(`campaign[${item}]`, campaign[`${item as keyof typeof campaign}`])
    }
  })
  formData.append(`file`, file)
  // formData.append(
  //   `lead[data][]`,
  //   customers.data && customers.data.length ? customers.data?.toString() : '[]'
  // )

  return (
    axios
      // {headers: {contentType: 'multipart/form-data'}}
      .put(`${CAMPAIGN_URL}/${id}`, formData, {headers: {contentType: 'multipart/form-data'}})
      .then((response: AxiosResponse<CreateCampaignResponse>) => response.data)
  )
}
function createCampaign(campaign: CreateCampaignBody): Promise<CreateCampaignResponse | undefined> {
  return (
    axios
      // {headers: {contentType: 'multipart/form-data'}}
      .post(CAMPAIGN_URL, campaign)
      .then((response: AxiosResponse<CreateCampaignResponse>) => response.data)
  )
}
function updateCampaign(
  campaign: CreateCampaignBody,
  id: string
): Promise<CreateCampaignResponse | undefined> {
  return (
    axios
      // {headers: {contentType: 'multipart/form-data'}}
      .put(`${CAMPAIGN_URL}/${id}`, campaign)
      .then((response: AxiosResponse<CreateCampaignResponse>) => response.data)
  )
}

const getCarrier = (query: string): Promise<CarrierQueryResponse> => {
  return request('get', `${CARRIER_URL}?${query}`).then((d) => {
    return d
  })
}
const getTemplate = (query: string): Promise<TemplateQueryResponse> => {
  return request('get', `${TEMPLATE_URL}?${query}`).then((d) => {
    return d
  })
}
const getTemplateExtension = (url: string): Promise<any> => {
  const query = `
      query{
      b2bGetListOfUsersBasicTel4vnInfo(is_create_extension: true) {
        items {
          id
          fullName
          avatar{
            ... on FileWithFullUrls {
      fileId
                originalUrl
            }
          }
          provider{
            tel4vn{
              user_uuid
              extension
            }
          }
        }
      }
    }
  `

  return request('post', `${CUSTOMER_EXTENSION_URL}`, {query}).then((d: any) => {
    return d
  })
}
const getTemplateDepartment = (url: string): Promise<any> => {
  const query = `
      query{
        b2bGetListEmployeeBasicDepartments(is_create_extension: true) {
          id
          name
          employees {
            id
            userType
            fullName
            employeeCode
            email
            avatar{
              ... on FileWithFullUrls {
                fileId
                originalUrl
              }
            }
            provider{
              tel4vn{
                is_create_extension
                extension
                user_uuid
              }
            }
          }
        }
      }
  `

  return request('post', `${CUSTOMER_EXTENSION_URL}`, {query}).then((d: any) => {
    return d
  })
}
const getCallScript = (query: string): Promise<CallscriptQueryResponse> => {
  return request('get', `${CALLSCRIPT_URL}?${query}`).then((d) => {
    return d
  })
}
const getCustomers = (query: string): Promise<CustomersQueryResponse> => {
  return request('get', `${CUSTOMER_URL}?${query}`).then((d) => {
    return d
  })
}
const getListUser = (): Promise<any> => {
  const query = `
  query {
    b2bGetListOfUsersBasicTel4vnInfo {
      items {
        id
        fullName
        avatar {
          ... on FileWithFullUrls {
            fileId
            originalUrl
          }
        }
        provider {
          tel4vn {
            user_uuid
            extension
          }
        }
      }
    }
  }
`

  return request('post', `${API_USER}`, {query}).then((d) => {
    return d.data
  })
}
const getListUserNoneExtension = (): Promise<any> => {
  const query = `
  query {
    b2bGetListOfUsersBasicTel4vnInfo(is_create_extension: false) {
      items {
        id
        fullName
        avatar {
          ... on FileWithFullUrls {
            fileId
            originalUrl
          }
        }
        provider {
          tel4vn {
            user_uuid
            extension
          }
        }
      }
    }
  }
`

  return request('post', `${API_USER}`, {query}).then((d) => {
    return d.data
  })
}

const getListDepartmentNoneExtension = (url: string): Promise<any> => {
  const query = `
      query{
        b2bGetListEmployeeBasicDepartments(is_create_extension: false) {
          id
          name
          employees {
            id
            userType
            fullName
            employeeCode
            email
            avatar{
              ... on FileWithFullUrls {
                fileId
                originalUrl
              }
            }
            provider{
              tel4vn{
                is_create_extension
                extension
                user_uuid
              }
            }
          }
        }
      }
  `

  return request('post', `${CUSTOMER_EXTENSION_URL}`, {query}).then((d: any) => {
    return d
  })
}

const getDetailCampaignCustomer = (id: string): Promise<Customer> => {
  return axios.get(`${LEAD_URL}/${id}`).then((response: AxiosResponse<Customer>) => response.data)
}
const getSourceCustomer = (): Promise<ConfigFilterQueryResponse> => {
  return axios
    .get(`${CONFIG_URL}`)
    .then((response: AxiosResponse<ConfigFilterQueryResponse>) => response.data)
}
const getTagsDetail = (): Promise<TagsDetailQueryResponse> => {
  return axios
    .get(`${API_CRM_URL}/tag?type=DEFAULT`)
    .then((response: AxiosResponse<TagsDetailQueryResponse>) => response.data)
}

const getListProvinces = (): Promise<ILocationRes[]> => {
  const query = `
  query {
    getListProvinces {
      id
      name
    }
  }
  `
  return request('get', `${API_MAP}`, {query}).then((d) => {
    return d.data.getListProvinces
  })
}
const getListDistrict = (provinceId: string, limit: number): Promise<any> => {
  const body = {
    query: `
    query ($provinceId: ObjectID!, $limit: Int) {
      getListDistrictsOfProvince(provinceId: $provinceId, limit: $limit) {
        districts {
          id
          name
        }
      }
    }
    `,
    variables: {
      provinceId,
      limit: limit,
    },
  }
  return request('get', `${API_MAP}`, body).then((d) => {
    return d.data.getListDistrictsOfProvince
  })
}
const getListWard = (districtId: string, limit: number): Promise<any> => {
  const body = {
    query: `
    query ($districtId: ObjectID!, $limit: Int) {
      getListWardsOfDistrict(districtId: $districtId, limit: $limit) {
        wards {
          id
          name
        }
      }
    }
    
    `,
    variables: {
      districtId,
      limit: limit,
    },
  }
  return request('get', `${API_MAP}`, body).then((d) => {
    return d.data.getListWardsOfDistrict
  })
}
const getListStreet = (districtId: string, limit: number): Promise<any> => {
  const body = {
    query: `
    query ($districtId: ObjectID!, $limit: Int) {
      getListStreetsOfDistrict(districtId: $districtId, limit: $limit) {
        streets {
          id
          name
        }
      }
    }
    
    
    `,
    variables: {
      districtId,
      limit: limit,
    },
  }
  return request('get', `${API_MAP}`, body).then((d) => {
    return d.data.getListStreetsOfDistrict
  })
}

export {
  createCampaign,
  createFileCampaign,
  getCallScript,
  getCampaign,
  getCampaignById,
  getCarrier,
  getCustomers,
  getDetailCampaignCustomer,
  getListDepartmentNoneExtension,
  getListDistrict,
  getListProvinces,
  getListStreet,
  getListUser,
  getListUserNoneExtension,
  getListWard,
  getSourceCustomer,
  getTagsDetail,
  getTemplate,
  getTemplateDepartment,
  getTemplateExtension,
  updateCampaign,
  updateFileCampaign,
}
