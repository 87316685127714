import {isString} from 'lodash'
import {ReactNode} from 'react'
import {KTSVG} from 'src/_biha/helpers'
interface ModalHeaderProps {
  onClose: () => void
  title: string | ReactNode
}
const ModalHeader = ({onClose, title}: ModalHeaderProps) => {
  return (
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      {isString(title) ? (
        <h2
          className='fw-bolder w-100'
          style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
          {title}
        </h2>
      ) : (
        title
      )}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-customers-modal-action='close'
        onClick={onClose}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}
const CloseButton = ({closeToast, ...props}: any) => (
  <div onClick={closeToast}>
    <KTSVG
      path='/media/icons/duotune/arrows/arr061.svg'
      className={`svg-icon-1 ${props.type === 'success' ? 'text-success' : ''}`}
    />
  </div>
)
export {ModalHeader, CloseButton}
