import {Response} from 'src/_biha/helpers'
import {CreatedBy} from '../../campaign/campaign-list/campaign-create/core/_models'
import {Boolean, String} from 'src/app2/modules/_models'
export type Extension = {
  _id: string
  extension: string
  enabled: boolean
  user_uuid: string
  extension_uuid: string
  company_id?: string
  created_at?: Date
  updated_at?: Date
  updated_by?: CreatedBy
  user?: CreatedBy
}

export type CreateExtensionRequest = {
  extension?: string
  enabled?: boolean
  user_uuid?: string
  password: string
  passwordConfirmation: undefined
}
export type ExtensionQueryResponse = Response<Array<Extension>>

//for filter table
export interface ExtensionFilterForm {
  extension?: String
  user_uuid?: String[]
  enabled?: Boolean
  created_by?: String
  created_at?: String[]
}
export const ExtensionFilterDefaultValues: ExtensionFilterForm = {
  extension: '',
  user_uuid: [],
  enabled: undefined,
  created_by: '',
  created_at: [],
}
