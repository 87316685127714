import {FC, useState, createContext, useContext, useMemo, useEffect} from 'react'
import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  initialListView,
  ListViewContextProps,
  groupingOnSelectAll,
  WithChildren,
} from 'src/_biha/helpers'
import {useQueryResponse, useQueryResponseData} from './QueryResponseProvider'
import {useAppDispatch} from 'src/app/redux/hook'
import {setCampaignState} from 'src/app/redux/slice/campaginSlice'

const ListViewContext = createContext<ListViewContextProps>(initialListView)

const ListViewProvider: FC<WithChildren> = ({children}) => {
  const dispatch = useAppDispatch()

  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate)
  const {isLoading} = useQueryResponse()
  const data = useQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])
  const [hoveredIndex, setHoveredIndex] = useState<number>(initialListView.hoveredIndex)
  useEffect(() => {
    dispatch(
      setCampaignState({
        selectedCampaigns: selected
          .map((id) => data.find((item) => item._id === id))
          .filter(Boolean),
      })
    )
  }, [selected])

  return (
    <ListViewContext.Provider
      value={{
        selected,
        itemIdForUpdate,
        hoveredIndex,
        setItemIdForUpdate,
        disabled,
        isAllSelected,
        onHover: (index: number) => {
          setHoveredIndex(index)
        },
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(
            data?.length > 0 && data.every((i) => selected.includes(i._id)),
            setSelected,
            data
          )
        },
        clearSelected: () => {
          setSelected([])
        },
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
