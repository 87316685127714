// @ts-nocheck
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Column} from 'react-table'
import {AvatarUser} from 'src/app/shared/components/AvatarUser/AvatarUser'
import {Campaign} from '../../../core/_models'
import {CampaignCustomHeader} from './CampaignCustomHeader'
import {CustomerSelectionCell} from './CustomerSelectionCell'
import {CustomerSelectionHeader} from './CustomerSelectionHeader'
import {DateTimeCell} from './DateTimeCell'

const autoCallColumns: ReadonlyArray<Column<Campaign> & {filter?: string}> = [
  {
    Header: (props) => <CustomerSelectionHeader tableProps={props} />,
    id: 'selection',
    width: 10,
    Cell: ({...props}) => <CustomerSelectionCell id={props.data[props.row.index]._id} />,
  },
  {
    Header: (props) => <CampaignCustomHeader title='STT' tableProps={props} />,
    id: 'index',
    filter: 'STT',
    width: 50,
    Cell: ({...props}) => <div style={{minWidth: 50}}>{props.row.index + 1}</div>,
  },
  {
    Header: 'Tên chiến dịch',
    filter: 'Tên chiến dịch',
    id: 'campaign_name',
    width: 250,
    Cell: ({...props}) => (
      <OverlayTrigger
        placement='top'
        overlay={
          <Tooltip id={props.data[props.row.index].campaign_name}>
            <div className='w-300px'>{props.data[props.row.index].campaign_name}</div>
          </Tooltip>
        }
      >
        <div className='me-4 text-overflow'>{props.data[props.row.index].campaign_name}</div>
      </OverlayTrigger>
    ),
  },
  {
    id: 'status',
    Header: 'Trạng thái',
    filter: 'Trạng thái',
    width: 150,
    Cell: ({...props}) => {
      const renderTag = () => {
        switch (props.data[props.row.index].status) {
          case 'stop':
            return <span className='badge badge-light-danger fs-8 '>Đã hủy</span>
          case 'start':
            return <span className='badge badge-light-primary fs-8'>Đang thực hiện</span>
          default:
            return <span className='badge badge-light fs-8'>Chưa bắt đầu</span>
        }
      }
      return <div className='w-152px'>{renderTag()}</div>
    },
  },
  {
    Header: 'Đầu số',
    id: 'carrier_name',
    filter: 'Đầu số',
    width: 250,
    Cell: ({...props}) => (
      <OverlayTrigger
        placement='top'
        overlay={
          <Tooltip id={props.data[props.row.index].carrier_name}>
            <div className='w-300px'>{props.data[props.row.index].carrier_name}</div>
          </Tooltip>
        }
      >
        <div className='me-4 text-overflow'>{props.data[props.row.index].carrier_name}</div>
      </OverlayTrigger>
    ),
  },
  {
    Header: 'Ngày bắt đầu',
    id: 'local_start_time',
    filter: 'Ngày bắt đầu',
    width: 200,
    Cell: ({...props}) => (
      <DateTimeCell className='w-200px' value={props.data[props.row.index].local_start_time} />
    ),
  },
  {
    Header: 'Người tạo',
    id: 'created_by',
    filter: 'Người tạo',
    width: 200,
    Cell: ({...props}) => (
      <AvatarUser
        imageUrl={props.data[props.row.index].created_by?.avatar}
        fullName={props.data[props.row.index].created_by?.fullName}
        width='fit-content'
      />
    ),
  },
  {
    Header: (props) => (
      <CampaignCustomHeader tableProps={props} title='Ngày tạo' className='w-200px' />
    ),
    filter: 'Ngày tạo',
    id: 'created_at',
    width: 200,
    Cell: ({...props}) => (
      <DateTimeCell
        className='w-200px'
        value={props.data[props.row.index].created_at}
        format='HH:mm:ss, DD/MM/YYYY'
      />
    ),
  },
  // {
  //   Header: (props) => (
  //     <CampaignCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <CustomerActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {autoCallColumns}
