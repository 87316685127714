import {isEqual, isString, map} from 'lodash'
import {NavLink} from 'react-router-dom'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import {FreeMode, Navigation} from 'swiper/modules'
import {Swiper, SwiperSlide} from 'swiper/react'
import {useAuth} from '../../../../../app/modules/auth'
import {KTSVG} from '../../../../helpers'
import {Spotlight} from './Spotlight'
import {routersApp} from './routers'
import {filterRoutersAppByModule} from 'src/_biha/utils'

const HorizontalMenu = () => {
  const {currentUser} = useAuth()

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semi py-2 fs-6 left '
      style={{position: 'fixed', left: '75px'}}
      data-kt-menu='true'
    >
      <div className='d-flex pb-0' style={{marginLeft: '72px'}}>
        <div className='d-flex align-items-center' style={{width: 'calc(100vw - 80px)'}}>
          <button
            id='swiper-back-horizontal-menu'
            type='button'
            className='btn h-40px w-60px text-gray-700'
          >
            <i className='ki-solid ki-arrow-left fs-1' />
          </button>
          <Swiper
            freeMode
            slidesPerView='auto'
            modules={[Navigation, FreeMode]}
            navigation={{
              prevEl: `#swiper-back-horizontal-menu`,
              nextEl: `#swiper-next-horizontal-menu`,
            }}
            className='d-flex user-select-none flex-grow-1'
          >
            {map(filterRoutersAppByModule(routersApp, currentUser?.modules), (router) => {
              const isExternalLink = router?.path?.startsWith('https')
              if (isEqual(router?.id, 0)) {
                return (
                  <SwiperSlide key={router?.id} className='w-152px'>
                    <div
                      id='total-button'
                      className='rounded-3 d-flex justify-content-center cursor-pointer bg-hover-light-primary'
                      data-kt-menu-dismiss='true'
                      style={{width: 120, height: 110}}
                    >
                      <Spotlight />
                    </div>
                  </SwiperSlide>
                )
              } else {
                if (isExternalLink) {
                  return (
                    <SwiperSlide key={router?.id} className='w-152px'>
                      <div
                        className={`w-120px bg-hover-light-primary rounded-3 d-flex flex-column`}
                        style={{
                          height: 110,
                        }}
                        data-kt-menu-dismiss='true'
                      >
                        <a
                          href={router.path || ''}
                          className='d-flex flex-column align-items-center h-100'
                          target='_blank'
                          rel='noreferrer'
                        >
                          {isString(router.icon) && (
                            <KTSVG path={router.icon} className='svg-icon-3x pt-5 pb-3' />
                          )}
                          <span
                            className='fs-7 fs-normal text-center w-120px'
                            style={{color: '#22313e'}}
                          >
                            {router.title}
                          </span>
                        </a>
                      </div>
                    </SwiperSlide>
                  )
                }
                return (
                  <SwiperSlide key={router?.id} className='w-152px'>
                    <div
                      key={router.id}
                      className={`w-120px bg-hover-light-primary rounded-3 d-flex flex-column`}
                      style={{
                        height: 110,
                      }}
                      data-kt-menu-dismiss='true'
                    >
                      <NavLink
                        to={router.path || ''}
                        className='d-flex flex-column align-items-center h-100'
                      >
                        {isString(router.icon) && (
                          <KTSVG path={router.icon} className='svg-icon-3x pt-5 pb-3' />
                        )}
                        <span
                          className='fs-7 fs-normal text-center w-120px'
                          style={{color: '#22313e'}}
                        >
                          {router.title}
                        </span>
                      </NavLink>
                    </div>
                  </SwiperSlide>
                )
              }
            })}
          </Swiper>
          <button
            id={`swiper-next-horizontal-menu`}
            type='button'
            className='btn h-40px w-56px text-gray-700'
          >
            <i className='ki-solid ki-arrow-right fs-1' />
          </button>
        </div>
      </div>
    </div>
  )
}

export {HorizontalMenu}
