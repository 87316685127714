import {autoCallColumns} from 'src/app/modules/apps/campaign/campaign-list/auto-call/components/table/columns/_columns'
import {
  ConfigFilter,
  Customer,
  ValueSelect,
} from 'src/app/modules/apps/campaign/campaign-list/campaign-create/core/_models'
import {createSlice} from '@reduxjs/toolkit'
import {PayloadAction} from '@reduxjs/toolkit'
import {autoDialColumns} from 'src/app/modules/apps/campaign/campaign-list/auto-dial/components/table/columns/_columns'
import {Campaign} from 'src/app/modules/apps/campaign/campaign-list/auto-call/core/_models'
import {customersColumns} from 'src/app/modules/apps/campaign/campaign-list/campaign-create/components/table/columns/_columns'
import {ID} from 'src/_biha/helpers'

export interface CampaignState {
  dataCustomers: Customer[]
  dataCustomersTotal: Customer[]
  dataCustomersSuccess: Customer[]
  listCustomerSelect: ID[]
  activeKeyTable: string
  autoCallShownColumns: string[]
  autoDialShownColumns: string[]
  createCampaginShownColumns: string[]
  selectedHistoryId: string
  selectedFile: string
  selectedCampaigns: (Campaign | undefined)[]
  listConfigFilter?: ConfigFilter
  listDistrict: ValueSelect[] | null
  listProvince: ValueSelect[] | null
  listWard: ValueSelect[] | null
  listStreet: ValueSelect[] | null
}
const initialState: CampaignState = {
  dataCustomers: [],
  dataCustomersSuccess: [],
  dataCustomersTotal: [],
  listCustomerSelect: [],
  autoCallShownColumns: autoCallColumns.map((col) => col.id!),
  autoDialShownColumns: autoDialColumns.map((col) => col.id!),
  createCampaginShownColumns: customersColumns.map((col) => col.id!),

  activeKeyTable: 'crmTable',
  selectedHistoryId: '',
  selectedFile: '',
  selectedCampaigns: [],
  listDistrict: [],
  listProvince: [],
  listWard: [],
  listStreet: [],
}
export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaignState(state, action: PayloadAction<Partial<CampaignState>>) {
      Object.assign(state, action.payload)
    },
  },
})

export const {actions: campaignAction, reducer: campaignReducer} = campaignSlice
export const {setCampaignState} = campaignSlice.actions
