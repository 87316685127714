import {FC, createContext, useContext} from 'react'
import {WithChildren} from '../../../../_biha/helpers'
import {QueryResponseProvider, useQueryResponseData} from './core/QueryResponseProvider'
import {UserConfigurationType} from './core/_models'

type UserConfigurationContextProps = UserConfigurationType
const UserConfigurationContext = createContext<UserConfigurationContextProps>({})

const ConfigurationWrapper: FC<WithChildren> = ({children}) => {
  const userConfigs = useQueryResponseData()
  return (
    <UserConfigurationContext.Provider value={userConfigs as UserConfigurationContextProps}>
      {children}
    </UserConfigurationContext.Provider>
  )
}

const UserConfigurationProvider: FC<WithChildren> = ({children}) => {
  return (
    <QueryResponseProvider>
      <ConfigurationWrapper>{children}</ConfigurationWrapper>
    </QueryResponseProvider>
  )
}

const useUserConfigurations = () => useContext(UserConfigurationContext)

export {UserConfigurationProvider, useUserConfigurations}
