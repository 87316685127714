export enum CampaignType {
  AutoCall = 'autocall',
  AutoDial = 'autodialer',
}
export enum CustomerResources {
  HANDMADE = 'HANDMADE',
  EXCEL = 'EXCEL',
}
export enum Career {
  WORK_SAFETY = 'WORK_SAFETY',
  DOCTOR = 'DOCTOR',
  SELL = 'SELL',
  TECHNICAL_SALES = 'TECHNICAL_SALES',
  RETAIL_WHOLESALE = 'RETAIL_WHOLESALE',
  INSURANCE = 'INSURANCE',
  MAINTENANCE_REPAIR = 'MAINTENANCE_REPAIR',
  REAL_ESTATE = 'REAL_ESTATE',
  TRANSLATOR = 'TRANSLATOR',
  EXECUTIVE_MANAGEMENT_LEVEL = 'EXECUTIVE_MANAGEMENT_LEVEL',
  STOCK = 'STOCK',
  HIGH_TECHNOLOGY = 'HIGH_TECHNOLOGY',
  MECHANICAL = 'MECHANICAL',
  OIL_AND_GAS = 'OIL_AND_GAS',
  TEXTILE_SHOES = 'TEXTILE_SHOES',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  PHARMACEUTICAL_BIOTECH = 'PHARMACEUTICAL_BIOTECH',
  PHARMACIST = 'PHARMACIST',
  GEOLOGY_MINERAL = 'GEOLOGY_MINERAL',
  REFRIGERATION_REFRIGERATION = 'REFRIGERATION_REFRIGERATION',
  POWER_ELECTRONICS = 'POWER_ELECTRONICS',
  ENTERTAINMENT = 'ENTERTAINMENT',
  EDUCATIONS = 'EDUCATIONS',
  HIGH_END_GOODS = 'HIGH_END_GOODS',
  HOUSEHOLD_APPLIANCES = 'HOUSEHOLD_APPLIANCES',
  SHIPPING = 'SHIPPING',
  AVIATION_TOURISM = 'AVIATION_TOURISM',
  CONSUMER_GOODS = 'CONSUMER_GOODS',
  ADMINISTRATION_CLERICAL = 'ADMINISTRATION_CLERICAL',
  CHEMISTRY_BIOCHEMISTRY = 'CHEMISTRY_BIOCHEMISTRY',
  PLANNING_PROJECTS = 'PLANNING_PROJECTS',
  PRINTING_PUBLISHING = 'PRINTING_PUBLISHING',
  INTERNET_ONLINE_MEDIA = 'INTERNET_ONLINE_MEDIA',
  IT_HARDWARE_NETWORK = 'IT_HARDWARE_NETWORK',
  IT_SOFTWARE = 'IT_SOFTWARE',
  ACCOUNTANT = 'ACCOUNTANT',
  LOGISTICS = 'LOGISTICS',
  AUDIT = 'AUDIT',
  ARCHITECTURE_INTERIOR_DESIGN = 'ARCHITECTURE_INTERIOR_DESIGN',
  BEAUTY_SPA = 'BEAUTY_SPA',
  WORK_ABROAD = 'WORK_ABROAD',
  THE_LAW = 'THE_LAW',
  MARKETING = 'MARKETING',
  ENVIRONMENT_WASTE_REATMENT = 'ENVIRONMENT_WASTE_REATMENT',
  JUST_HAVE_GRADUATED = 'JUST_HAVE_GRADUATED',
  FINE_ARTS_ART_DESIGN = 'FINE_ARTS_ART_DESIGN',
  BANK = 'BANK',
  FOREIGNERS_VIET_KIEU = 'FOREIGNERS_VIET_KIEU',
  RESTAURANT_HOTEL = 'RESTAURANT_HOTEL',
  PERSONNEL = 'PERSONNEL',
  AGRICULTURE_FORESTRY = 'AGRICULTURE_FORESTRY',
  JURIDICAL = 'JURIDICAL',
  NON_GOVERNMENT_NON_PROFIT = 'NON_GOVERNMENT_NON_PROFIT',
  QA_QC = 'QA_QC',
  ADVERTISING_PROMOTION_FOREIG_AFFAIRS = 'ADVERTISING_PROMOTION_FOREIG_AFFAIRS',
  PRODUCT_OF_INDUSTRY = 'PRODUCT_OF_INDUSTRY',
  MANUFACTURE = 'MANUFACTURE',
  TECHNOLOGY_FINANCE = 'TECHNOLOGY_FINANCE',
  FINANCE_INVESTMENT = 'FINANCE_INVESTMENT',
  SPORT = 'SPORT',
  FASHION = 'FASHION',
  SEASONAL_SHORT_TERM_CONTRACT = 'SEASONAL_SHORT_TERM_CONTRACT',
  PET = 'PET',
  PROCUREMENT_SUPPLIES_SUPPLYING = 'PROCUREMENT_SUPPLIES_SUPPLYING',
  FOOD_BEVERAGE = 'FOOD_BEVERAGE',
  ECOMMERCE = 'ECOMMERCE',
  PHARMACEUTICAL_REPRESENTATIVES = 'PHARMACEUTICAL_REPRESENTATIVES',
  TELEVISION_MEDIA_JOURNALISM = 'TELEVISION_MEDIA_JOURNALISM',
  AUTOMATION_AUTOMOTIVE = 'AUTOMATION_AUTOMOTIVE',
  ADVISE = 'ADVISE',
  SHIPPING_DELIVERY = 'SHIPPING_DELIVERY',
  TELECOMMUNICATION = 'TELECOMMUNICATION',
  BUILD = 'BUILD',
  IMPORT_AND_EXPORT = 'IMPORT_AND_EXPORT',
  DOCTOR_AIDE = 'DOCTOR_AIDE',
  MEDICAL_HEALTHCARE = 'MEDICAL_HEALTHCARE',
  OTHER = 'OTHER',
}
export enum UserType {
  INDIVIDUAL = 'INDIVIDUAL',
  ENTERPRISE = 'ENTERPRISE',
}