import {FC, useContext} from 'react'
import {useQuery} from 'react-query'

import {ConfigGetSerialization} from './_models'
import {getConfigurations} from './_requests'
import Cookies from 'js-cookie'
import {
  createPaginationResponseContext,
  initialQueryResponse,
  QUERIES,
  WithChildren,
} from 'src/_biha/helpers'
import {AUTH_COOKIES_KEY} from '../../../../../app/modules/auth'

const QueryResponseContext =
  createPaginationResponseContext<ConfigGetSerialization>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.CONFIG}`,
    () => {
      const cookiesValue: string | undefined = Cookies.get(AUTH_COOKIES_KEY)
      if (!cookiesValue) {
        return undefined
      }

      return getConfigurations()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()

  if (!response) {
    return undefined
  }
  return response || undefined
}
const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading}
