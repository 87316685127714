/* eslint-disable react-hooks/exhaustive-deps */
import {FormikProvider, useField, useFormik, useFormikContext} from 'formik'
import {
  filter,
  find,
  gt,
  includes,
  isArray,
  isEmpty,
  isEqual,
  isNil,
  isUndefined,
  map,
  size,
  toString,
} from 'lodash'
import {ChangeEvent, FC, MouseEvent, useEffect, useState} from 'react'
import {PopperPlacement} from '../../../../../_biha/assets/ts/components'
import {removeVietnameseAccents} from '../../../../utils'
import {OverlayTrigger, Popover} from 'react-bootstrap'

interface Option {
  label?: string
  value?: string
}
interface Props {
  name: string
  label?: string
  options?: Option[]
  emptyText?: string
  searchPlaceholder?: string
  popperPlacement?: PopperPlacement
  optionsClassName?: string
  buttonClassName?: string
  onClose?: () => void
  onConfirm?: () => void
}
interface CheckboxFilterForm {
  innerValues?: string[]
}
const initialValuesCheckboxFilterForm: CheckboxFilterForm = {
  innerValues: [],
}

const CheckboxFilter: FC<Props> = ({
  name,
  label,
  options,
  emptyText,
  searchPlaceholder,
  popperPlacement = 'bottom',
  optionsClassName,
  buttonClassName,
  onClose,
  onConfirm,
}) => {
  const outerForm = useFormikContext()
  const [field, , helpers] = useField(name)
  const {value: outerFormValue} = field
  const {setValue: setOuterFormValue} = helpers
  const innerForm = useFormik<CheckboxFilterForm>({
    initialValues: initialValuesCheckboxFilterForm,
    onSubmit: (values) => {
      isSelectedAllInnerForm ? setOuterFormValue([]) : setOuterFormValue(values.innerValues)
      onConfirm ? onConfirm?.() : outerForm.submitForm()
    },
  })
  const [privateSearch, setPrivateSearch] = useState<string>('')
  const [privateOptions, setPrivateOptions] = useState<Option[] | undefined>()
  const [isShowingPopover, setToggleShowingPopover] = useState<boolean>(false)
  const isSelectedAllInnerForm = isEqual(size(options), size(innerForm.values['innerValues']))
  const hasSearchValue = !isEmpty(outerFormValue) && !isNil(outerFormValue)
  const searchedLabel = hasSearchValue
    ? `${
        find(options, {value: isArray(outerFormValue) ? outerFormValue[0] : outerFormValue})
          ?.label ?? ''
      }${
        isArray(outerFormValue) && gt(size(outerFormValue), 1)
          ? ` +${size(outerFormValue) - 1}`
          : ''
      }`
    : !isUndefined(label)
    ? label
    : name
  const handleClose = () => {
    onClose?.()
    handleTogglePopover()
  }
  const handleClear = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    innerForm.setValues({innerValues: []})
    setOuterFormValue([])
    outerForm.submitForm()
  }
  const handleSelectAll = () => {
    isSelectedAllInnerForm
      ? innerForm.setValues({innerValues: []})
      : innerForm.setValues({innerValues: map(options, (option) => toString(option.value))})
  }
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setPrivateSearch(event.target.value)
    event.target.value
      ? setPrivateOptions(
          filter(options, (option) =>
            includes(
              removeVietnameseAccents(toString(option.label)),
              removeVietnameseAccents(event.target.value)
            )
          )
        )
      : setPrivateOptions(options)
  }
  const handleConfirm = () => {
    innerForm.submitForm()
    handleTogglePopover()
  }
  const handleTogglePopover = () => {
    setToggleShowingPopover(!isShowingPopover)
  }

  useEffect(() => {
    outerFormValue &&
      innerForm.setValues({
        innerValues: isArray(outerFormValue) ? outerFormValue : [outerFormValue],
      })
  }, [outerFormValue, isShowingPopover])

  useEffect(() => {
    setPrivateOptions(options)
  }, [options])

  return (
    <FormikProvider value={innerForm}>
      <OverlayTrigger
        rootClose
        trigger='click'
        show={isShowingPopover}
        placement={popperPlacement ?? 'bottom'}
        onToggle={handleTogglePopover}
        overlay={
          <Popover
            id={`checkbox-filter-${name}`}
            bsPrefix='m-0'
            className='menu menu-sub menu-sub-dropdown w-360px rounded-2'
            style={{zIndex: 4}}
          >
            <div className='card card-custom card-flush card-px-0'>
              <div className='card-tittle p-4'>
                <div className='card-title m-0 mt-4'>
                  <div className='d-flex align-items-center rounded-2 position-relative'>
                    <i className='ki-duotone ki-magnifier fs-2 position-absolute ms-3'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                    <input
                      autoComplete='off'
                      data-kt-table-filter={`${name}-checkbox-search`}
                      className='form-control form-control-ontlined w-100 ps-10 pe-8 fs-7 fw-bold h-40px py-0'
                      placeholder={searchPlaceholder ?? 'Tìm kiếm'}
                      value={privateSearch ?? ''}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
              </div>
              <div className='card-body card-scroll h-220px py-0 px-4 border-bottom'>
                {isEmpty(privateOptions) ? (
                  <span className='d-inline-block m-2 text-gray-600 fst-italic fs-6'>
                    {emptyText ?? 'Không tìm thấy kết quả'}
                  </span>
                ) : (
                  <div className={`${optionsClassName ?? ''} f-flex flex-column`}>
                    <div className='mb-3 user-select-all'>
                      <label
                        className={`form-check form-check-custom form-check-solid ${
                          isSelectedAllInnerForm ? 'bg-light-primary' : ''
                        } w-100 cursor-pointer p-2 rounded-2 bg-hover-light-primary user-select-none`}
                      >
                        <input
                          id={`${name}-checkbox-all`}
                          name='checkedAll'
                          className='form-check-input'
                          checked={isSelectedAllInnerForm}
                          type='checkbox'
                          value='all'
                          onChange={handleSelectAll}
                        />
                        <span className='form-check-label text-gray-600 fs-7 lh-sm fw-bold text-truncate'>
                          Chọn tất cả
                        </span>
                      </label>
                    </div>
                    <div role='group' aria-labelledby='checkbox-group'>
                      {map(privateOptions, (option, optionIndex) => (
                        <div key={optionIndex} className='mb-3 user-select-all'>
                          <label
                            className={`form-check form-check-custom form-check-solid ${
                              isSelectedAllInnerForm ||
                              includes(innerForm.values['innerValues'], option.value)
                                ? 'bg-light-primary'
                                : ''
                            } w-100 cursor-pointer p-2 rounded-2 bg-hover-light-primary user-select-none`}
                          >
                            <input
                              {...field}
                              id={`${name}-checkbox-${optionIndex}`}
                              name='innerValues'
                              className='form-check-input'
                              type='checkbox'
                              checked={
                                isSelectedAllInnerForm ||
                                includes(innerForm.values['innerValues'], option.value)
                              }
                              value={option.value}
                              onChange={innerForm.handleChange}
                            />
                            <span className='form-check-label text-gray-600 fs-7 lh-sm fw-bold text-truncate'>
                              {option.label}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className='card-footer px-4 py-2'>
                <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    className='btn btn-light fw-bold fs-6 px-4 py-3 lh-1 me-3 rounded-2 text-gray-600'
                    data-kt-menu-dismiss='true'
                    onClick={handleClose}
                  >
                    Huỷ
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary fw-bold fs-6 px-4 py-3 lh-1 rounded-2'
                    data-kt-menu-dismiss='true'
                    onClick={handleConfirm}
                  >
                    Xác nhận
                  </button>
                </div>
              </div>
            </div>
          </Popover>
        }
      >
        <button
          type='button'
          className={`${buttonClassName ?? ''} btn btn-sm d-flex align-items-center fw-bold ${
            hasSearchValue
              ? 'bg-light-primary bg-hover-light-primary text-primary'
              : 'bg-light bg-hover-light text-gray-600'
          } me-2 rounded-2 py-0 px-3 h-32px`}
          data-kt-menu-trigger='click'
          data-kt-menu-placement={popperPlacement}
        >
          <span className='me-1 mw-180px text-truncate lh-lg'>{searchedLabel}</span>
          {hasSearchValue ? (
            <div className='ms-1' onClick={handleClear}>
              <i className='ki-duotone ki-cross-circle fs-3'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            </div>
          ) : (
            <i className='ki-duotone ki-down fs-7'></i>
          )}
        </button>
      </OverlayTrigger>
    </FormikProvider>
  )
}

export {CheckboxFilter}
