import {useState} from 'react'
import {CustomersListSearchComponent} from './CustomersListSearchComponent'
import {CustomersListToolbar} from './CustomersListToolbar'
import FilterGroup from './FilterGroup'

const ExtensionHeader = () => {
  const [showFilter, setShowFilter] = useState(false)

  return (
    <>
      <div className='d-flex border-0 px-6 pt-6 justify-content-between'>
        <CustomersListSearchComponent />
        <CustomersListToolbar setShowFilter={setShowFilter} />
      </div>
      <div className={`${showFilter ? 'd-flex ' : 'd-none'}`}>
        <FilterGroup />
      </div>
    </>
  )
}

export {ExtensionHeader}
