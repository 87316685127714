import {JSONObject, Number, String} from 'src/app2/modules/_models'

export enum LeadTypeEnums {
  crm = 'crm',
  member = 'member',
  excel = 'excel',
}
export interface CampaignV2CreateDto {
  campaign_name: String
  time_start: String
  additional_info: JSONObject
  lead: any
  brandname_uuid?: String
  templateV2_uuid?: String
  type: String
  description?: String
  lead_type?: LeadTypeEnums
}
//get brand name list
export interface BrandNameV2 {
  _id?: String
  created_by?: IUser
  company_id?: String
  brandname?: String
  config_name?: String
  apiKey?: String
  description?: String
  username?: String
  provider?: String
  type?: String
  region?: String
  created_at?: String
  updated_at?: String
}
export type BrandNameListQueryResponse = CampaignV2Response<Array<BrandNameV2>>
//get template list
interface IUser {
  fullName?: String
  email?: String
  phone?: String
  avatar?: String
  id?: String
}
export interface TemplateV2 {
  _id?: String
  created_by?: IUser
  company_id?: String
  template_name?: String
  template_code?: String
  type?: String
  content?: String
  status?: String
  list_params?: String[]
  brandname_uuid?: String
  created_at?: String
  updated_at?: String
  title?: String
  updated_by?: IUser
  amount?: Number
}
export interface CampaignV2Response<T> {
  data?: T
  message?: String
  statusCode?: Number
  totalData?: Number
  totalPage?: Number
  currentPage?: Number
  perPage?: Number
}
export type TemplateListQueryResponse = CampaignV2Response<Array<TemplateV2>>
