/* eslint-disable react-hooks/exhaustive-deps */
import {FormikProvider, useFormik} from 'formik'
import {omit, replace} from 'lodash'
import moment from 'moment'
import qs from 'qs'
import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {CheckboxFilter} from 'src/app2/modules/apps/components/checkbox-filter/CheckboxFilter'
import {FormDateRangeFilter} from 'src/app2/modules/apps/components/form-date-range-filter/FormDateRangeFilter'
import {TextFilter} from 'src/app2/modules/apps/components/text-filter/TextFilter'
import {UserFilterById} from '../../../../../shared/components/table-filter/UserFilterById'
import {UserFilterByUuid} from '../../../../../shared/components/table-filter/UserFilterByUuid'
import {ExtensionFilterDefaultValues, ExtensionFilterForm} from '../../core/_models'
import {useQueryResponse} from '../../core/QueryResponseProvider'

const statusOptions = [
  {
    label: 'Đã tắt',
    value: 'false',
  },
  {
    label: 'Đang hoạt động',
    value: 'true',
  },
]
const quickSelectOptions = [
  {label: '7 ngày trước', startDate: moment().add(-7, 'days'), endDate: moment()},
  {label: '30 ngày trước', startDate: moment().add(-30, 'days'), endDate: moment()},
  {label: '90 ngày trước', startDate: moment().add(-90, 'days'), endDate: moment()},
]
const FilterGroup = () => {
  const {isLoading, refetch} = useQueryResponse()
  const navigate = useNavigate()
  const {pathname, search} = useLocation()
  const form = useFormik<ExtensionFilterForm>({
    initialValues: ExtensionFilterDefaultValues,
    onSubmit: (values) => {
      const searchObject = qs.parse(replace(search, '?', ''))
      navigate(
        `${pathname}?${qs.stringify(
          omit(
            {
              ...omit(searchObject, [
                'extension',
                'user_uuid',
                'enabled',
                'created_by',
                'created_at',
              ]),
              ...values,
            },
            'page'
          ),
          {
            arrayFormat: 'repeat',
            encode: false,
            filter: (_, value) => value || undefined,
          }
        )}`
      )
    },
  })

  const handleResetForm = () => {
    form.resetForm()
    navigate(`${pathname}?items_per_page=10&page=1&perPage=10`)
    refetch()
  }

  useEffect(() => {
    const searchObject = qs.parse(replace(search, '?', ''))
    form.setValues(searchObject)
  }, [search])

  return (
    <FormikProvider value={form}>
      <form onSubmit={form.handleSubmit} className='overflow-visible w-100'>
        <div className='border-0 px-6 pt-4 d-flex justify-content-between align-items-center filter-group w-100'>
          <div className='d-flex'>
            <div className='my-1'>
              <TextFilter
                name='extension'
                label='Máy nhánh'
                popperPlacement='bottom-start'
                maxLength={168}
              />
            </div>
            <div className='ms-2 my-1'>
              <UserFilterByUuid label='Nhân viên' name='user_uuid' queryName='get_list_employee' />
            </div>
            <div className='ms-4 my-1'>
              <CheckboxFilter
                name='enabled'
                label='Trạng thái'
                popperPlacement='bottom'
                options={statusOptions}
              />
            </div>
            <div className='ms-2 my-1'>
              <UserFilterById
                label='Người tạo'
                name='created_by'
                queryName='get_list_extension_created_by'
              />
            </div>
            <div className='ms-4 my-1'>
              <FormDateRangeFilter
                name='created_at'
                autoClose
                quickSelectOptions={quickSelectOptions}
                label='Ngày tạo'
                numberOfMonths={2}
                maxDate={moment()}
              />
            </div>
          </div>
          <button
            disabled={isLoading}
            type='button'
            className='btn btn-secondary h-32px d-flex align-items-center fs-8 rounded-2 px-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            onClick={handleResetForm}
          >
            <i className='ki-duotone ki-arrows-circle fs-5'>
              <i className='path1'></i>
              <i className='path2'></i>
            </i>
            Đặt lại
          </button>
        </div>
      </form>
    </FormikProvider>
  )
}

export default FilterGroup
