interface IPropAvatarUser {
  imageUrl: string
  fullName: string
  description?: string
  styleName?: boolean
  width?: number | string
  minWidth?: number | string
}
const AvatarUser = ({
  imageUrl,
  fullName,
  description,
  width,
  styleName = false,
  minWidth,
}: IPropAvatarUser) => {
  return (
    <div
      className='d-flex align-items-center text-nowrap w-100'
      style={{height: 40, width: width ?? 190, minWidth: minWidth ?? 0}}
    >
      {imageUrl ? (
        <div className='symbol symbol-40px me-4 d-flex align-items-center'>
          <img src={imageUrl} alt='avatar' className='rounded-circle' />
        </div>
      ) : (
        <div className='symbol symbol-circle symbol-40px me-4 d-flex align-items-center'>
          <div className='symbol-label fs-2 fw-semibold bg-secondary text-inverse-success'>
            {fullName?.charAt(0)}
          </div>
        </div>
      )}
      <div>
        <span className={`fw-bold`} style={{color: !styleName ? '#2459ad' : '#3F4254'}}>
          {fullName}
        </span>
        <span>{description}</span>
      </div>
    </div>
  )
}

export {AvatarUser}
