import clsx from 'clsx'
import {useField} from 'formik'
import {
  isArray,
  isEmpty,
  isEqual,
  isNil,
  isUndefined,
  map,
  range,
  toString,
  upperFirst,
} from 'lodash'
import moment, {Moment} from 'moment'
import {FC, Fragment, MouseEvent, useEffect, useState} from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {DayPickerRangeController, FocusedInputShape, isSameDay} from 'react-dates'
import 'react-dates/initialize'
import {PopperPlacement} from '../../../../../_biha/assets/ts/components'
import {
  DEFAULT_DISPLAY_DATE,
  DEFAULT_DISPLAY_DAY_MONTH,
  DEFAULT_FORMAT_DATE,
} from '../../../../../_biha/i18n/LocaleProvider'
import {NextIcon} from './components/NextIcon'
import {PrevIcon} from './components/PrevIcon'
import 'moment/locale/vi'
moment.locale('vi')

interface DateRangePicker {
  startDate: Moment | null
  endDate: Moment | null
}

interface QuickSelectOption {
  label?: string
  startDate: Moment | null
  endDate: Moment | null
}

interface Props {
  name: string
  autoClose?: boolean
  minDate?: Moment
  maxDate?: Moment
  quickSelectOptions?: QuickSelectOption[]
  popperPlacement?: PopperPlacement
  inputClassName?: string
  placeholder?: string
  numberOfMonths?: number
  disabled?: boolean
  type?: 'date' | 'datetime' | 'dateMonth'
  onSelect?: (selected?: DateRangePicker) => void
  onClear?: () => void
}

const FormDateRangeInput: FC<Props> = ({
  name,
  autoClose,
  minDate,
  maxDate,
  quickSelectOptions,
  popperPlacement = 'bottom',
  inputClassName,
  placeholder,
  numberOfMonths = 1,
  type,
  disabled,
  onSelect,
  onClear,
}) => {
  const [field, , helpers] = useField(name)
  const {value: outerFormValue} = field
  const {setValue: setOuterFormValue} = helpers
  const [startDate, setStartDate] = useState<Moment | null>(null)
  const [endDate, setEndDate] = useState<Moment | null>(null)
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>('startDate')
  const [isShowingPopover, setToggleShowingPopover] = useState<boolean>(false)
  const hasSelectedValue = !isNil(startDate) && !isNil(endDate)
  const inputLabels =
    startDate && endDate
      ? isEqual(type, 'dateMonth')
        ? `${moment(startDate).format(DEFAULT_DISPLAY_DAY_MONTH)} đến ${moment(endDate).format(
            DEFAULT_DISPLAY_DAY_MONTH
          )}`
        : `${moment(startDate).format(DEFAULT_DISPLAY_DATE)} đến ${moment(endDate).format(
            DEFAULT_DISPLAY_DATE
          )}`
      : placeholder ?? 'Vui lòng chọn ngày'

  const handleDateChange = ({startDate, endDate}: DateRangePicker) => {
    setStartDate(startDate)
    setEndDate(endDate)
    if (startDate && endDate) {
      onSelect?.({startDate, endDate})

      setOuterFormValue?.([
        startDate.format(DEFAULT_FORMAT_DATE),
        endDate.format(DEFAULT_FORMAT_DATE),
      ])
      autoClose && handleTogglePopover()
    }
  }
  const handleFocusChange = (focusedInput: FocusedInputShape | null) => {
    setFocusedInput(focusedInput || 'startDate')
  }
  const handleClear = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setStartDate(null)
    setEndDate(null)
    setOuterFormValue?.([])
    onClear?.()
  }
  const handleClickQuickSelect = (quickSelect: QuickSelectOption) => {
    setStartDate(quickSelect.startDate)
    setEndDate(quickSelect.endDate)
    setOuterFormValue?.([
      quickSelect.startDate?.format(DEFAULT_FORMAT_DATE),
      quickSelect.endDate?.format(DEFAULT_FORMAT_DATE),
    ])
    autoClose && handleTogglePopover()
  }
  const handleCheckIsOutsideRange = (day: Moment | null) => {
    if (isNil(day)) return true
    if (!isUndefined(minDate) && !isUndefined(maxDate))
      return day.isBefore(minDate, 'day') || day.isAfter(maxDate, 'day')
    if (!isUndefined(minDate)) return day.isBefore(minDate, 'day')
    if (!isUndefined(maxDate)) return day.isAfter(maxDate, 'day')
    return false
  }
  const handleTogglePopover = () => {
    setToggleShowingPopover(!isShowingPopover)
  }

  const renderDayContents = (day: Moment) => {
    return (
      <div>
        {day.format('D')}
        <span className={clsx({CurrentDay: isSameDay(day, moment())})}></span>
      </div>
    )
  }
  const renderWeekHeaderElement = (day: string) => {
    return (
      <div className='min-h-36px d-flex justify-content-center align-items-center text-gray-600 fw-bold fs-7'>
        {day}
      </div>
    )
  }
  const renderMonthElement = ({
    month,
    onMonthSelect,
    onYearSelect,
  }: {
    month: Moment
    onMonthSelect: (currentMonth: Moment, newMonthVal: string) => void
    onYearSelect: (currentMonth: Moment, newMonthVal: string) => void
    isVisible: boolean
  }) => {
    return (
      <div className='position-relative min-h-16px'>
        <div className='position-absolute d-flex top-50 start-50 translate-middle'>
          <select
            className='form-select w-100px text-gray-600 fw-bold fs-7 h-32px lh-sm me-2 px-3 py-1'
            data-placeholder='Chọn tháng'
            value={month.month()}
            onChange={(event) => onMonthSelect(month, event.target.value)}
          >
            {moment.months().map((label, value) => (
              <option key={`month-option-${value}`} value={value}>
                {upperFirst(label)}
              </option>
            ))}
          </select>
          {!isEqual(type, 'dateMonth') && (
            <select
              className='form-select w-76px text-gray-600 fw-bold fs-7 h-32px lh-sm px-3 py-1'
              data-placeholder='Chọn năm'
              value={month.year()}
              onChange={(event) => onYearSelect(month, event.target.value)}
            >
              {range(
                !isUndefined(minDate) ? minDate.year() : 1900,
                !isUndefined(maxDate) ? maxDate.year() + 1 : moment().year() + 100
              ).map((value) => (
                <option key={`year-option-${value}`} value={value}>
                  {value}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
    )
  }
  useEffect(() => {
    if (isArray(outerFormValue) && !isEmpty(outerFormValue)) {
      if (
        moment(toString(outerFormValue[1])).isBefore(
          moment(toString(outerFormValue[0]), DEFAULT_FORMAT_DATE)
        )
      ) {
        outerFormValue?.[1] && setStartDate(moment(outerFormValue[1]) ?? null)
        outerFormValue?.[0] && setEndDate(moment(outerFormValue[0]) ?? null)
      } else {
        outerFormValue?.[0] && setStartDate(moment(outerFormValue[0]) ?? null)
        outerFormValue?.[1] && setEndDate(moment(outerFormValue[1]) ?? null)
      }
    } else {
      setStartDate(null)
      setEndDate(null)
    }
  }, [outerFormValue])

  return (
    <div id={`input-${name}-date-range`}>
      <OverlayTrigger
        rootClose
        trigger='click'
        show={isShowingPopover}
        placement={popperPlacement}
        onToggle={handleTogglePopover}
        overlay={
          <Popover
            id={`input-${name}-date-range-popover`}
            bsPrefix='m-0'
            className='menu menu-sub menu-sub-dropdown p-0 rounded-2 overflow-hidden'
            style={{zIndex: 9999}}
          >
            <div className='d-flex'>
              <div className='overflow-x-hidden'>
                <div className='mx-n2 z-0'>
                  <DayPickerRangeController
                    noBorder
                    hideKeyboardShortcutsPanel
                    firstDayOfWeek={1}
                    startDate={startDate}
                    endDate={endDate}
                    focusedInput={focusedInput}
                    monthFormat='[Tháng] M [năm] YYYY'
                    initialVisibleMonth={() => startDate ?? moment()}
                    numberOfMonths={numberOfMonths}
                    navNext={<NextIcon />}
                    navPrev={<PrevIcon />}
                    daySize={38}
                    minimumNights={0}
                    onFocusChange={handleFocusChange}
                    onDatesChange={handleDateChange}
                    isOutsideRange={handleCheckIsOutsideRange}
                    renderWeekHeaderElement={renderWeekHeaderElement}
                    renderMonthElement={renderMonthElement}
                    renderDayContents={renderDayContents}
                  />
                </div>
              </div>

              {!isEmpty(quickSelectOptions) && (
                <Fragment>
                  <div className='w-140px d-flex flex-column pt-2 border-start border-gray-200 z-2'>
                    {map(quickSelectOptions, (quickSelect, quickSelectIndex) => {
                      const isSelected =
                        startDate && endDate && quickSelect.startDate && quickSelect.endDate
                          ? isSameDay(startDate, quickSelect.startDate) &&
                            isSameDay(endDate, quickSelect.endDate)
                          : false
                      return (
                        <div key={quickSelectIndex} className='px-2 py-1'>
                          <button
                            disabled={
                              handleCheckIsOutsideRange(quickSelect.startDate) ||
                              handleCheckIsOutsideRange(quickSelect.endDate)
                            }
                            type='button'
                            className={clsx(
                              'btn h-32px w-100 d-flex justify-content-center align-items-center p-0 rounded-2',
                              {
                                'bg-primary text-white': isSelected,
                                'bg-hover-light text-gray-800': !isSelected,
                              }
                            )}
                            onClick={() => handleClickQuickSelect(quickSelect)}
                          >
                            <span className='fs-7 fw-bold'>{quickSelect.label}</span>
                          </button>
                        </div>
                      )
                    })}
                  </div>
                </Fragment>
              )}
            </div>
          </Popover>
        }
      >
        <button type='button' className='btn btn-lg p-0 w-100' disabled={disabled}>
          <div
            className={`${
              inputClassName ?? ''
            } d-flex align-items-center h-40px border border-1 border-gray-300 rounded-2 overflow-hidden ${
              disabled ? 'bg-gray-200' : ''
            }`}
          >
            <div className='flex-row-fluid d-flex align-items-center overflow-hidden px-2'>
              <span
                className={`fs-7 fw-bold text-truncate lh-sm ${
                  hasSelectedValue ? 'text-gray-600' : 'text-gray-500'
                }`}
              >
                {inputLabels}
              </span>
            </div>
            {hasSelectedValue && (
              <div className='cursor-pointer' onClick={handleClear}>
                <i className='ki-duotone ki-cross-circle fs-3'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            )}
            <div className='h-100 d-flex align-items-center justify-content-center min-w-40px'>
              <i className='ki-outline ki-calendar-2 text-gray-600 fs-2'></i>
            </div>
          </div>
        </button>
      </OverlayTrigger>
    </div>
  )
}

export {FormDateRangeInput}
