import {combineReducers} from '@reduxjs/toolkit'
import {globalReducer} from './slice/globalSlice'
import { campaignReducer } from './slice/campaginSlice'

const rootReducer = combineReducers({
  global: globalReducer,
  campaign: campaignReducer
})
export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
