import {FC, PropsWithChildren} from 'react'
import {HeaderProps} from 'react-table'
import {useListView} from '../../../core/ListViewProvider'
import {Campaign} from '../../../core/_models'
import {useQueryResponseData} from '../../../core/QueryResponseProvider'

type Props = {
  tableProps: PropsWithChildren<HeaderProps<Campaign>>
}

const CustomerSelectionHeader: FC<Props> = ({tableProps}) => {
  const {selected, onSelectAll} = useListView()
  const campaign = useQueryResponseData()
  return (
    <th {...tableProps.column.getHeaderProps()} className='w-10px'>
      <div className='form-check form-check-custom form-check-solid me-3'>
        <input
          className='form-check-input'
          type='checkbox'
          data-kt-check={campaign?.length > 0 && campaign.every((i) => selected.includes(i._id))}
          data-kt-check-target='#kt_table_customers .form-check-input'
          checked={campaign?.length > 0 && campaign.every((i) => selected.includes(i._id))}
          onChange={onSelectAll}
        />
      </div>
    </th>
  )
}

export {CustomerSelectionHeader}
